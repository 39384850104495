import React from 'react'

import {
  alertOn,
  AppId,
  AppRegistration,
  displayDismissed,
  User,
  Warning,
} from '../../../types'
import { CustomComponentHandler } from '../../../store'
import { DisplayResultSwitchYard } from '../SwitchYard/DisplayResultSwitchYard'

const AppResultNotification: React.FC<{
  appId: string
  appReg: AppRegistration
  warning: Warning
  user: User
  customReactComps: CustomComponentHandler
  handleDismissCommandAlert: (appId: AppId, appReg: AppRegistration) => void
}> = ({
  appReg,
  appId,
  warning,
  user,
  customReactComps,
  handleDismissCommandAlert,
}) => {
  const command = appReg.app_skeleton.command
  const resultcomps = command?.display_result?.tchildren ?? []
  const showCommandAlert = !displayDismissed(command) && alertOn(command)

  if (!showCommandAlert) return <></>

  return (
    <>
      {resultcomps.map((comp, idx) => (
        <DisplayResultSwitchYard
          key={idx}
          comp={comp}
          appInfo={[warning, appReg]}
          user={user}
          customReactComps={customReactComps}
          handleDismissCommandAlert={() =>
            handleDismissCommandAlert(appId, appReg)
          }
          subType={comp.sub_type || command?.display_result?.sub_type}
        />
      ))}
    </>
  )
}

export default AppResultNotification
