import React from 'react'
import Box from '@mui/material/Box'

import { getBackgroundByEnvironment } from '../../utils/Environment'

const EnvironmentBar: React.FC<{
  isNotProduction: boolean
  environment: string
}> = ({ isNotProduction, environment }) => {
  return (
    <>
      {isNotProduction && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            backgroundColor: theme =>
              getBackgroundByEnvironment(theme, environment),
            color: 'white',
            height: '25px',
            display: 'flex',
            zIndex: theme => theme.zIndex.drawer + 1,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {`${environment.toUpperCase()} environment`}
        </Box>
      )}
    </>
  )
}

export default EnvironmentBar
