import React from 'react'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'

import {
  AppId,
  AppRegistration,
  AppSkeleton,
  clearAppSkeleton,
} from '../../types'

const Eraser: React.FC<{
  app: AppRegistration
  onAppDataChange: (appId: AppId) => (appsk: AppSkeleton) => void
}> = ({ app, onAppDataChange }) => {
  const appId = app.app_id
  const appSkel = app.app_skeleton
  const modSkel = (): AppSkeleton => clearAppSkeleton(appSkel)

  return (
    <Tooltip arrow title="Clear form data">
      <IconButton
        aria-label="clear form data"
        className="fa-solid fa-eraser"
        onClick={() => onAppDataChange(appId)(modSkel())}
        size="small"
      />
    </Tooltip>
  )
}

export default Eraser
