import { FormControl, InputLabel, InputBase, Tooltip } from '@mui/material'
import clsx from 'clsx'
import { isUndefined } from 'lodash'
import React from 'react'

import { getJuvoInfo } from '../../../../store'
import { ComparisonTextInputModel } from '../../../../types'
import { isDefined, sanitizeInputName } from '../../../../utils'
import { InputStateProps } from '../../../../utils/MakeInputState'
import { AutoResizeInput } from '../../AutoResizeInput/AutoResizeInput'
import ValueChips from '../ValueChips/ValueChips'
import {
  getComparisonClasses,
  stringComparer,
} from '../../../../utils/Comparison'

const CompareTextInput: React.FC<{
  comp: ComparisonTextInputModel
  inputStateProps: InputStateProps
}> = ({ comp, inputStateProps }) => {
  const juvoInfo = getJuvoInfo('CompareTextInput', comp)
  const label = comp.text ?? ''
  const recommendation = comp.recommendation
  const { value, setValue, handleBlur } = inputStateProps
  const inputName = sanitizeInputName(label)
  const inputValue = isDefined(value)
    ? value
    : isDefined(recommendation)
    ? recommendation
    : ''
  const chips = comp.compValues ?? []

  return (
    <FormControl
      variant="standard"
      fullWidth
      className="juvo-input-control"
      sx={{
        display: 'flex',
      }}
      {...juvoInfo}
    >
      <InputLabel shrink className="juvo-input-label">
        {label}
      </InputLabel>
      <Tooltip arrow title={comp.tooltip ?? ''}>
        <InputBase
          type="text"
          id={inputName}
          name={inputName}
          value={inputValue}
          endAdornment={
            <ValueChips
              chips={chips ?? []}
              currentValue={inputValue}
              comparisonFunc={stringComparer}
              onClickEvent={item => setValue(item)}
            />
          }
          inputComponent={AutoResizeInput}
          slotProps={{
            input: { className: 'juvo-compare-input' },
          }}
          onChange={e => setValue(e.target.value ?? e.target.textContent)}
          onBlur={handleBlur}
          className={clsx(
            'juvo-compare-input-base',
            ...getComparisonClasses<string>(
              chips ?? [],
              value ?? '',
              stringComparer,
            ),
            isDefined(recommendation) && isUndefined(value) && 'recommendation',
          )}
        />
      </Tooltip>
    </FormControl>
  )
}

export default CompareTextInput
