import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import Gaussian from 'gaussian'
import { alpha, Typography, useTheme } from '@mui/material'
import { Box } from '@mui/system'

import { NormalCurveModel } from '../../../types/Model'
import { defv, isDefined } from '../../../utils'
import { getJuvoInfo } from '../../../store'
import {
  MAX_DEV_GRAPH_RANGE,
  GRAPH_SMOOTHNESS,
  MAX_DEV_VISUAL_RANGE,
} from '../../../constants/Constants'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip,
  Legend,
)

// Make a number of x-points spread across the graph, from the min to the max
export const makeXPoints = (
  mean: number,
  stdDev: number,
  devSteps: number,
  z?: number,
): Array<number> => {
  if (!mean || !devSteps) return []

  const points = []
  const max_dev = stdDev * MAX_DEV_GRAPH_RANGE
  const maxPoint = mean + max_dev
  const minPoint = mean - max_dev
  const interval = (maxPoint - minPoint) / devSteps

  for (let i = 0; i < devSteps; i++) {
    points.push(minPoint + interval * i)
  }
  if (isDefined(z)) {
    points.push(mean + stdDev * z)
    points.push(mean - stdDev * z)
  }
  return points.sort((a, b) => a - b)
}

const NormalCurve: React.FC<{
  comp: NormalCurveModel
}> = ({ comp }) => {
  const theme = useTheme()

  const isGraphDefined = isDefined(comp.mean)
  const mean = comp.mean || 0
  const stdDev = defv(comp['std-dev'])(
    defv(comp.bound?.['std-dev']?.value)(
      defv(comp.bound?.['std-dev']?.recommendation)(1),
    ),
  )
  const empty_msg = defv(comp.empty_msg)(
    defv(comp.bound?.empty_msg?.value)(
      defv(comp.bound?.empty_msg?.recommendation)(''),
    ),
  ) //display nothing if empty_msg is not set
  const z = defv(comp.z)(
    defv(comp.bound?.z?.value)(defv(comp.bound?.z?.recommendation)(0)),
  ) //not shown if 0

  const variance = stdDev * stdDev
  const distribution = Gaussian(mean, variance)
  const borderColor = comp.color ?? theme.palette.primary.main
  const areaColor = alpha(borderColor, 0.5)

  // Make a single set of data for the graph
  const makeDataSet = (
    borderColor: string,
    data: Array<{ x: number; y: number }>,
    fill: boolean,
  ) => {
    return {
      borderColor: borderColor,
      pointStyle: false as const,
      tension: 0.3,
      backgroundColor: areaColor,
      data: data,
      fill: fill,
    }
  }

  // Make a set of points as a subset of the given points, truncating between the upper and lower targets
  const makeAreaData = (
    xyData: Array<{ x: number; y: number }>,
    upper: number,
    lower: number,
  ): Array<{ x: number; y: number }> => {
    return xyData.map(point => {
      const newPoint = { ...point } // Clone
      if (newPoint.x < lower || newPoint.x < 0 || newPoint.x > upper) {
        newPoint.y = NaN
      }
      return newPoint
    })
  }

  // Don't label first and last ticks
  const tickCallback = (value: any, index: number, ticks: Array<any>) => {
    return index === 0 || index === ticks.length - 1 ? '' : value
  }

  const xPoints = makeXPoints(mean, stdDev, GRAPH_SMOOTHNESS, z)
  const xyData = xPoints.map(x => {
    return { x: x, y: distribution.pdf(x) }
  })
  const areaData = makeAreaData(xyData, mean + stdDev * z, mean - stdDev * z)

  return isGraphDefined ? (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      {z > 0 && (
        <Typography
          {...getJuvoInfo('NormalCurve', comp)}
          variant="subtitle1"
          sx={{
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
            top: '50%',
            left: '50%',
            zIndex: '100',
            background: 'rgba(255, 255, 255, 0.2)',
            padding: '2px 6px',
          }}
        >{`${Math.round((z - 1) * 100)}%`}</Typography>
      )}
      <Line
        options={{
          responsive: true,
          animation: false as const,
          plugins: {
            legend: {
              display: false as const,
            },
            title: {
              display: true,
              text: comp.label,
            },
          },
          scales: {
            x: {
              type: 'linear' as const,
              max: mean + stdDev * MAX_DEV_VISUAL_RANGE,
              min: mean - stdDev * MAX_DEV_VISUAL_RANGE,
              ticks: { callback: tickCallback },
            },
            y: {
              type: 'linear' as const,
              display: false,
            },
          },
        }}
        data={{
          datasets: [
            {
              ...makeDataSet(borderColor, xyData, false),
            },
            {
              ...makeDataSet(borderColor, areaData, true),
            },
          ],
        }}
        datasetIdKey="id"
      />
    </Box>
  ) : (
    <Typography {...getJuvoInfo('NormalCurve', comp)} variant="body1">
      {empty_msg}
    </Typography>
  )
}

export default NormalCurve
