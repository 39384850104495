import React from 'react'
import { Box } from '@mui/material'

import { CritErr } from '../../types'
import NotFound from '../ErrorScreens/NotFound/NotFound'

import AppLoadingSplash from './AppLoadingSplash'

// TODO: reconnect button
const AppLoading: React.FC<{ err: CritErr }> = ({ err }) => {
  const isAppBooting = ['Not Initialized', 'initial'].includes(err.msg)
  const isNotLoggedInYet = err.msg === 'Not Logged In' && err.hint === ''

  if (isAppBooting || isNotLoggedInYet) {
    return (
      <Box
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <Box
          sx={{
            width: { xs: '284px', md: '484px' },
          }}
        >
          <AppLoadingSplash />
        </Box>
      </Box>
    )
  }

  return (
    <NotFound
      message="Juvo failed to initialize. Please check your network connection and try again. If the problem persists, report the issue and session ID."
      homeLink={false}
    />
  )
}

export default AppLoading
