import React, { useEffect } from 'react'
import Container from '@mui/material/Container'

import { CritErr } from '../../types'
import { getDebugInfo } from '../../store'
import AppLoading from '../AppLoading/AppLoading'
import { logDebug, logWarn } from '../../utils/Logger'
import { isUnit } from '../../utils/Common'

const Critical: React.FC<{ err: CritErr }> = ({ err }) => {
  useEffect(() => {
    logDebug(`Booting status: ${err.msg}`)
    logDebug(`Hint: ${err.hint}`)
    if (isUnit(err.err)) {
      logWarn(`Booting status: ${JSON.stringify(err.err)}`)
    }
  }, [err])

  return (
    <Container
      maxWidth="sm"
      juvo-component="Critical"
      debug-info={getDebugInfo(err)}
    >
      <AppLoading err={err} />
    </Container>
  )
}

export default Critical
