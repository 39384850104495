import * as React from 'react'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import MobileStepper from '@mui/material/MobileStepper'
import Button from '@mui/material/Button'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'

import { getJuvoInfo } from '../../../store'
import {
  adjustStdComponentArgs,
  StdComponentArgs,
  CarouselModel,
} from '../../../types'
import { containerSetChild } from '../../../store/State'
import { SwitchYard } from '../..'
import { logWarn } from '../../../utils/Logger'
import { scrollAppContentToTop } from '../../../utils/Common'

const Carousel: React.FC<{
  stdCompArgs: StdComponentArgs<CarouselModel>
}> = ({ stdCompArgs }) => {
  const props = stdCompArgs
  const { comp, onComponentChange } = stdCompArgs

  // I got something coming in for the array of carouselSeats based on the number of tchildren in the json. Now I need to set the steps or
  // pull the info from that json structure to display those child components instead of the hard coded steps above
  const carouselSeats = comp.tchildren || []

  const validModel = carouselSeats.length > 0
  const activeStepIdx0 = comp.position || 0
  if (activeStepIdx0 + 1 > carouselSeats.length && activeStepIdx0 !== 0)
    logWarn(
      'Developer Error, console position out of bounds, check addlet code',
      { gotPosition: comp.position, gotCarouselLength: carouselSeats.length },
    )

  const activeStepIdx =
    activeStepIdx0 + 1 > carouselSeats.length ? 0 : activeStepIdx0

  const activeStep =
    activeStepIdx < carouselSeats.length ? carouselSeats[activeStepIdx] : null

  const activeStepProps = adjustStdComponentArgs(props, activeStep, nc =>
    onComponentChange(containerSetChild(nc, activeStepIdx, comp)),
  )

  const setActiveStep = (n: number): void => {
    onComponentChange({ ...comp, position: n })
  }

  const handleNext = () => {
    setActiveStep(activeStepIdx + 1)
  }

  const handleBack = () => {
    setActiveStep(activeStepIdx - 1)
  }

  const theme = useTheme()

  // One thing I notice is that there isn't a map in here. It does move from step to step but by index and not necessarily a map
  // We also need to pull in the CarouselContent or Step Content basically..

  React.useEffect(() => {
    scrollAppContentToTop()
  }, [activeStepIdx])

  return validModel ? (
    <Box
      className="carousel-box"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        flexGrow: 1,
      }}
    >
      {/* <Paper
        square
        elevation={0}
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: 50,
          pl: 2,
          bgcolor: 'background.default',
        }}
      >
          {/* This is the label of the step at the top. Not sure we need this..? */}
      {/* <Typography >{steps[activeStepIdx].label}</Typography>
      </Paper> */}
      <Box
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          maxWidth: 900,
          width: '100%',
          p: { xs: 0, sm: 2 },
        }}
      >
        <SwitchYard key={activeStepIdx} {...activeStepProps} />
      </Box>
      <MobileStepper
        variant="text"
        steps={carouselSeats.length}
        position="static"
        activeStep={activeStepIdx}
        nextButton={
          <Button
            // on small screens avoid a lot of margin space, and avoid carousel stepper to wrap in several lines
            sx={{ m: { xs: 1, sm: 4 }, p: 2 }}
            size="small"
            onClick={handleNext}
            disabled={activeStepIdx === carouselSeats.length - 1}
          >
            Next
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button
            sx={{ m: { xs: 1, sm: 4 }, p: 2 }}
            size="small"
            onClick={handleBack}
            disabled={activeStepIdx === 0}
          >
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </Box>
  ) : (
    // for dynamically populated carousels this will always show up until some data comes from the server
    //other components on the page should disambiguate this message
    <Box {...getJuvoInfo('CarouselComponent', comp)}>No data</Box>
  )
}

export default Carousel
