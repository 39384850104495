import {
  ClaimListModel,
  ClaimModel,
  ClaimView,
  ComponentModel,
} from '../../../types'
import { logDebug } from '../../../utils/Logger'
import { Nullable } from '../../../utils/Undefined'

export const writeJoin = (
  toJoin: string[],
  firstTerms: string,
  lastTerm: string,
): string => {
  if (toJoin.length == 0) {
    return ''
  }

  if (lastTerm.length > 0) {
    lastTerm = lastTerm + ' '
  }

  const numItems = toJoin.length
  if (numItems == 1) {
    return toJoin[0]
  }
  if (numItems == 2) {
    return toJoin.join(' ' + lastTerm)
  }

  return (
    toJoin.slice(0, -1).join(firstTerms + ' ') +
    firstTerms +
    ' ' +
    lastTerm +
    toJoin[numItems - 1]
  )
}

export const intExpansion = (inText: Nullable<string>): Nullable<number>[] => {
  if (inText == null || inText == '') {
    //this weird code is here so we match on null claim number in the message
    // search for HACK1 to see where this is used
    // null identifies claim number that was not entered / defined
    return [null]
  }

  const individualClaims = [...inText.matchAll(/[0-9]+/g)]
  const inRangeClaims = [...inText.matchAll(/[0-9]+\W*?-\W*?[0-9]+/g)]

  const claims = new Set<number>()
  individualClaims.forEach(claim => {
    claims.add(parseInt(claim[0]))
  })

  inRangeClaims.forEach(elements => {
    const startEndSplit = elements[0].replace(' ', '').split('-')
    const start = parseInt(startEndSplit[0])
    const stop = parseInt(startEndSplit[startEndSplit.length - 1])
    if (start <= stop) {
      const range = (start: number, stop: number, step = 1) =>
        Array.from(
          { length: (stop - start) / step + 1 },
          (_, i) => start + i * step,
        )
      range(start, stop).forEach(cc => {
        claims.add(cc)
      })
    }
  })

  return Array.from(claims).sort((a, b) => a - b)
}

export const intCompression = (ints: number[]): string => {
  const sortedInts: number[] = ints.sort((n1, n2) => n1 - n2)

  const ranges: string[] = []
  let currentRange: number[] = []
  let vm1 = -1

  sortedInts.forEach((value, ctr) => {
    if (ctr == 0 || vm1 + 1 == value) {
      currentRange.push(value)
    } else if (currentRange.length == 1) {
      if (currentRange[0] == null) {
        ranges.push('')
      } else {
        ranges.push(currentRange[0].toString())
      }
      currentRange = [value]
    } else if (currentRange.length == 2) {
      ranges.push(`${currentRange[0]}, ${currentRange[1]}`)
      currentRange = [value]
    } else {
      ranges.push(`${currentRange[0]}-${currentRange[currentRange.length - 1]}`)
      currentRange = [value]
    }

    vm1 = value
  })

  const numCurrent = currentRange.length
  const numRanges = ranges.length

  if (numCurrent == 1 && numRanges > 0) {
    ranges.push(`and ${currentRange[0]}`)
  } else if (numRanges > 0 && numCurrent == 2) {
    ranges.push(`${currentRange[0]}, and ${currentRange[1]}`)
  } else if (numRanges == 0 && numCurrent == 2) {
    ranges.push(`${currentRange[0]} and ${currentRange[1]}`)
  } else if (numRanges == 0 && numCurrent == 1) {
    if (currentRange[0] == null) {
      ranges.push('')
    } else {
      ranges.push(currentRange[0].toString())
    }
  } else if (numRanges == 0 && numCurrent > 2) {
    ranges.push(`${currentRange[0]}-${currentRange[currentRange.length - 1]}`)
  } else if (numRanges > 0 && numCurrent > 2) {
    ranges.push(
      `and ${currentRange[0]}-${currentRange[currentRange.length - 1]}`,
    )
  }
  return writeJoin(ranges, ',', '')
}

export const compress = (claims: ClaimModel[]): Map<string, ClaimModel[]> => {
  const compressedLookup = new Map()

  let parentClaim = 0
  claims.forEach(claim => {
    if (claim.isIndependent) {
      parentClaim = claim.claimNumber || parentClaim
    }
    const key = `${claim.isIndependent}.${claim.claimStatus}.${parentClaim}`
    if (compressedLookup.has(key)) {
      compressedLookup.get(key).push(claim)
    } else {
      compressedLookup.set(key, [claim])
    }
  })

  return compressedLookup
}

export const format = (lookup: Map<string, ClaimModel[]>): ClaimView[] => {
  // TODO figure out how to do this as a map..
  const formatted: Array<ClaimView> = []
  for (const [key, value] of lookup) {
    const claimNumbers: Array<number> = []
    value.map((item: { claimNumber: any }) => {
      claimNumbers.push(item.claimNumber)
    })
    const claimsText = intCompression(claimNumbers)
    formatted.push({
      claims: claimsText,
      status: key.split('.')[1],
      isIndependent: key.split('.')[0] === 'true',
    })
  }

  return formatted
}

// TODO make this faster
// Note this will uncompress only specified component
// the actual result depends on the order of uncopression.
export const uncondenseClaim = (
  childComponent: ClaimView,
  inx: number,
  containerComp: ClaimListModel,
): ComponentModel => {
  // Full Claims
  const claims = containerComp.claims || []
  const condensedClaims = containerComp.internal_condensed_claims || []

  const newCondensedClaims = condensedClaims.map((cp: ClaimView, ix: number) =>
    ix === inx ? childComponent : cp,
  )

  const toModify = intExpansion(childComponent.claims)
  // TODO: Lets not be O(n^2)
  toModify.forEach(modNum => {
    let found = false
    for (let ctr = 0; ctr < claims.length; ctr++) {
      //see HACK1
      if (claims[ctr].claimNumber == modNum) {
        claims[ctr].claimStatus = childComponent.status
        claims[ctr].isIndependent = childComponent.isIndependent
        found = true
        break
      }
    }
    if (!found) {
      const newClaim = {
        ...createEmptyClaimModel(),
        claimStatus: childComponent.status,
        isIndependent: childComponent.isIndependent,
        claimNumber: modNum,
      }
      claims.push(newClaim)
    }
  })

  // Remove claims which have been removed
  let indices: Nullable<number>[] = []
  newCondensedClaims.forEach(cc => {
    const claimNums = intExpansion(cc.claims)
    indices = indices.concat(claimNums)
  })

  const newClaims = claims.filter(claim => indices.includes(claim.claimNumber))

  return {
    ...containerComp,
    claims: newClaims.sort(
      (c1, c2) => (c1.claimNumber || 0) - (c2.claimNumber || 0),
    ),
    internal_condensed_claims: newCondensedClaims,
  }
}

export const claimsFromView = (
  claimViews: ClaimView[],
  compClaims: Nullable<ClaimModel[]>,
): ClaimModel[] => {
  const claims = compClaims || []
  let indices: Nullable<number>[] = []
  claimViews.forEach(childComponent => {
    const toModify = intExpansion(childComponent.claims)
    // TODO: Lets not be O(n^2)
    toModify.forEach(modNum => {
      let found = false
      for (let ctr = 0; ctr < claims.length; ctr++) {
        //see HACK1
        if (claims[ctr].claimNumber == modNum) {
          claims[ctr].claimStatus = childComponent.status
          claims[ctr].isIndependent = childComponent.isIndependent
          found = true
          break
        }
      }
      if (!found) {
        const newClaim = {
          ...createEmptyClaimModel(),
          claimStatus: childComponent.status,
          isIndependent: childComponent.isIndependent,
          claimNumber: modNum,
        }
        claims.push(newClaim)
      }
    })

    // Remove claims which have been removed
    indices = indices.concat(toModify)
  })

  const newClaims = claims.filter(claim => indices.includes(claim.claimNumber))

  return newClaims
}

export const changeClaimView = (
  childComponent: ClaimView,
  inx: number,
  containerComp: ClaimListModel,
): ComponentModel => {
  const condensedClaims = containerComp.internal_condensed_claims || []
  const newCondensedClaims = condensedClaims.map((cp: ClaimView, ix: number) =>
    ix === inx ? childComponent : cp,
  )
  logDebug('new_condensed_claims : ', newCondensedClaims)
  return { ...containerComp, internal_condensed_claims: newCondensedClaims }
}

export const createEmptyClaimModel = (): ClaimModel => {
  return {
    type: 'claim',
    preamble: [],
    claimNumber: null,
    isIndependent: null,
    claimStatus: '',
    features: [],
  }
}
