import React from 'react'
import { Navigate, useParams, useLocation } from 'react-router-dom'
import queryString from 'query-string'

const RedirectTopAppId: React.FC = () => {
  const { appId } = useParams()
  const { search } = useLocation()
  
  // Remove "token" from QS because we don't need it anymore after the
  // redirect. It should be stored in local storage
  const qs = queryString.parse(search)
  delete qs["token"]

  return <Navigate to={`/apps/${appId}?${queryString.stringify(qs)}`} replace />
}

export default RedirectTopAppId