import React, {  useMemo } from 'react'

import { useContainerDimensions } from '../hooks/useContainerDimensions'

import { isUndefined, Nullable } from './Undefined'

export const getVisualLength = (text: Nullable<string>): number => {
  if (isUndefined(text) || text.length === 0) {
    return 0
  }

  const ruler: HTMLSpanElement | null = document.querySelector('#ruler')
  if (ruler === null) {
    console.error('Ruler not found, could not measure text visual length!')
    return 0
  }

  ruler.innerHTML = text
  return ruler.offsetWidth
}

export type MeasurementProps = {
  inputFitsInsideContainer: boolean
  defaultLabel: string
  defaultLabelVisualLength: number
  elementHandle: HTMLDivElement | null
  inputWidth: number
}

// The current padding we use is 16px on both sides.
// This is a temporary solution, we should measure the padding of the container when and if it becomes more dynamic.
const DEFAULT_CONTAINER_PADDING_PX = 32

// TODO: container padding should be dynamic and container type more accurate
export const MakeLabelMeasurements: React.FC<{
  label: Nullable<string>
  containerPadding?: number
  defaultLabel?: string
  labelOffset?: number
  children(props: MeasurementProps & { ref: any }): JSX.Element
}> = ({
  containerPadding = DEFAULT_CONTAINER_PADDING_PX,
  label,
  labelOffset = 0,
  defaultLabel = '',
  children,
}) => {
  const {
    dimensions: { width: containerWidth },
    elementHandle,
    ref,
  } = useContainerDimensions<HTMLDivElement>()

  const inputWidth = useMemo(() => {
    return getVisualLength(label) + labelOffset
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [label])

  const inputFitsInsideContainer = useMemo(() => {
    return inputWidth < containerWidth - containerPadding
  }, [inputWidth, containerWidth, containerPadding])

  const defaultLabelVisualLength = useMemo(() => {
    return getVisualLength(defaultLabel) + labelOffset
  }, [defaultLabel, labelOffset])

  return children({
    ref,
    inputFitsInsideContainer,
    defaultLabel,
    defaultLabelVisualLength,
    elementHandle,
    inputWidth: inputFitsInsideContainer
      ? inputWidth
      : defaultLabelVisualLength,
  })
}
