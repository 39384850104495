import React, { useState } from 'react'
import { InputLabel, Box, IconButton, InputBase } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import clsx from 'clsx'
import { format } from 'date-fns'

import { ComparisonDateInputModel, DateModel } from '../../../../types/Model'
import { setComponentValue } from '../../../../store'
import { Command } from '../../../../types'
import { AutoResizeInput } from '../../AutoResizeInput/AutoResizeInput'
import ValueChips from '../ValueChips/ValueChips'
import { useOnSmallScreen } from '../../../../hooks/useOnSmallScreen'
import {
  dateComparer,
  getComparisonClasses,
} from '../../../../utils/Comparison'
import { sanitizeInputName } from '../../../../utils/Common'
import { logErr } from '../../../../utils/Logger'
import { getJuvoProps } from '../../../../utils/JuvoProps'

const CompareDateInput: React.FC<{
  comp: ComparisonDateInputModel
  onCommand: (cmd: Command) => void
  onChange: (_: DateModel) => void
}> = ({ comp, onCommand, onChange }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isFocused, setFocus] = useState(false)
  const onSmallScreen = useOnSmallScreen()

  const label = comp.text ?? ''
  const chips = comp.compValues ?? []
  const inputName = sanitizeInputName(label)

  const handleChange = (date: Date) => {
    try {
      onChange(setComponentValue(comp)(format(date, 'yyyy-MM-dd')))
    } catch (error) {
      logErr('Invalid date value')
    }
  }

  const { eventHandlers, className, value, juvoInfo } = getJuvoProps<any, any>(
    'CompareDateInput',
    comp,
    onCommand,
    handleChange,
  )

  const {
    onChange: onChangeFromServer,
    onBlur: onBlurFromServer,
    onFocus: onFocusFromServer,
    ...handlersFromServer
  } = eventHandlers

  const setChipValue = (value: string) => {
    onChangeFromServer(new Date(`${value}T00:00:00`))
  }

  const formatDate = (item: string) => {
    return format(new Date(`${item}T00:00:00`), 'MM/dd/yyyy')
  }

  return (
    <Box
      className={clsx('juvo-date-field', isFocused && 'date-field-focus')}
      sx={{
        '&.date-field-focus .MuiInputBase-formControl': {
          outline: theme =>
            `2px solid ${theme.palette.primary.main} !important`,
        },
        '.MuiInputBase-formControl': {
          outline: theme => `1px solid ${theme.palette.grey[400]}`,
          '&:hover': {
            outline: theme => `2px solid ${theme.palette.grey[500]}`,
          },

          '.MuiInputBase-input': {
            padding: '10px 12px !important',
          },

          '.MuiOutlinedInput-notchedOutline': {
            border: '0 !important',
          },
        },
        marginBottom: '8px',
      }}
    >
      <InputLabel shrink htmlFor={inputName} className="juvo-input-label">
        {label}
      </InputLabel>
      <DatePicker
        {...juvoInfo}
        value={value ? new Date(`${value}T00:00:00`) : null}
        {...handlersFromServer}
        open={isOpen}
        onChange={date => {
          onChangeFromServer(date)
        }}
        onAccept={() => setIsOpen(false)}
        onClose={() => setIsOpen(false)}
        renderInput={(params: any) => {
          delete params['InputProps']
          return (
            <InputBase
              {...params}
              className={clsx(
                params.className,
                className,
                'juvo-input-base',
                'juvo-compare-input-base',
                ...getComparisonClasses<string>(
                  chips ?? [],
                  value ?? '',
                  dateComparer,
                ),
              )}
              id={inputName}
              name={inputName}
              sx={{
                minWidth: onSmallScreen ? '100%' : 'auto',
                width: '100%',
              }}
              onBlur={() => {
                onBlurFromServer && onBlurFromServer()
                setFocus(false)
              }}
              inputComponent={AutoResizeInput}
              endAdornment={
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                    // No pointer events for this box only, so that the user can click anywhere to focus the input
                    pointerEvents: 'none',
                    '& *': {
                      pointerEvents: 'all',
                    },
                  }}
                >
                  <ValueChips
                    chips={chips ?? []}
                    currentValue={value}
                    comparisonFunc={(a, b) => dateComparer(a, b)}
                    onClickEvent={item => setChipValue(item)}
                    labelMutator={item => formatDate(item)}
                  />
                  <IconButton
                    aria-label="open calendar"
                    className="fa-regular fa-calendar"
                    onClick={() => setIsOpen(true)}
                    size="small"
                  />
                </Box>
              }
              onFocus={() => {
                onFocusFromServer && onFocusFromServer()
                setFocus(true)
              }}
            />
          )
        }}
      />
    </Box>
  )
}

export default CompareDateInput
