import React from 'react'

import ConnectionWarning from '../ConnectionWarning/ConnectionWarning'

const RouteWithConnection: React.FC<{
  isOpen: boolean
  children: React.ReactElement
}> = ({ isOpen, children }) => {
  return (
    <>
      {children}
      <ConnectionWarning isOpen={isOpen} />
    </>
  )
}

export default RouteWithConnection
