import React from 'react'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'

import { AppRegistration } from '../../types'
import { sanitizeUrl } from '../../utils'

const DocLink: React.FC<{
  app: AppRegistration
}> = ({ app }) => {
  const appDocLink = app.app_doclink
  // TODO: Check that the host is *web*
  if (appDocLink) {
    return (
      <Tooltip arrow title="External link">
        <IconButton
          className="fa-solid fa-arrow-up-right-from-square"
          component="a"
          href={sanitizeUrl(appDocLink)}
          target="_blank"
          size="small"
          rel="noopener noreferrer"
        />
      </Tooltip>
    )
  } else {
    return null
  }
}

export default DocLink
