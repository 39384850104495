import { useState, useCallback } from "react"

import { isDefined } from "../utils"

import { useWindowDimensions } from "./useWindowDimensions"

export type Dimensions = {
  width: number
  height: number
}

export const useContainerDimensions = <E extends HTMLElement>(): {
  ref: any
  elementHandle: E | null
  dimensions: Dimensions
} => {
  const windowDims = useWindowDimensions()
  const [dimensions, setDimensions] = useState<Dimensions>(windowDims)
  const [elementHandle, setElementHandle] = useState<E | null>(null)

  const measure = useCallback(
    (node: E) => {
      if (isDefined(node)) {
        setElementHandle(node)
        const container = node.parentNode as HTMLElement
        setDimensions({
          width: container.getBoundingClientRect().width,
          height: container.getBoundingClientRect().height,
        })
      }
    },
    /* eslint-disable react-hooks/exhaustive-deps */
    [windowDims],
  )

  return {
    dimensions,
    elementHandle,
    ref: measure,
  }
}
