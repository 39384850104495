import React from 'react'
import Chip from '@mui/material/Chip'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Downshift from 'downshift'
import {
  DragDropContext,
  Draggable,
  DraggingStyle,
  NotDraggingStyle,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd'

import { reorder } from '../../juvo-container/List/List'
import { MakeState, TagListModel } from '../../../types'
import { setComponentValue } from '../../../store/Component'
import { isDefined, isUndefined } from '../../../utils'

const TagList: React.FC<{
  comp: TagListModel
  onChange: (_: TagListModel) => void
  stateHandler: MakeState<string>
}> = ({ comp, onChange, stateHandler }) => {
  const { recommendation, placeholder, title, value } = comp
  const showedItems = isDefined(value) ? value ?? [] : recommendation ?? []
  const isRecommended = isDefined(recommendation) && isUndefined(value)
  const [inputValue, setInputValue] = stateHandler
  const [focused, setFocused] = React.useState(false)

  const changeItems = (newItems: string[]) =>
    onChange(setComponentValue<string[]>(comp)(newItems))

  const getItemStyle = (draggableStyle?: DraggingStyle | NotDraggingStyle) => ({
    margin: '4px',
    ...draggableStyle,
  })

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) return

    const newItems = reorder(
      showedItems,
      result.source.index,
      result.destination.index,
    )
    changeItems(newItems)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement
    if (event.key === 'Enter') {
      const newSelectedItem = [...showedItems]
      // avoid white values
      if (!target.value.replace(/\s/g, '').length) return

      // avoid duplicated values
      const duplicatedValues = newSelectedItem.find(
        item => item === target.value.trim(),
      )

      if (duplicatedValues) {
        setInputValue('')
        return
      }

      newSelectedItem.push(target.value.trim())
      changeItems(newSelectedItem)
      setInputValue('')
    }
    if (showedItems.length && !inputValue.length && event.key === 'Backspace') {
      changeItems(showedItems.slice(0, showedItems.length - 1))
    }
  }

  const getFocusStyle = () => {
    return focused
      ? {
          borderColor: 'transparent !important',
          outline: '2px solid var(--color-primary) !important',
        }
      : null
  }

  const handleDelete = (item: string) => () => {
    const newSelectedItem: string[] = [...showedItems]
    newSelectedItem.splice(newSelectedItem.indexOf(item), 1)
    changeItems(newSelectedItem)
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
  }

  return (
    <Downshift
      id="downshift-multiple"
      inputValue={inputValue}
      selectedItem={showedItems}
    >
      {({ getInputProps }) => {
        const { onBlur, onChange, onFocus } = getInputProps({
          onKeyDown: handleKeyDown,
          placeholder,
        })
        return (
          <div>
            <TextField
              InputProps={{
                startAdornment: (
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable" direction="horizontal">
                      {provided => (
                        <Box
                          ref={provided.innerRef}
                          sx={{
                            display: 'flex',
                            padding: 1,
                            flexWrap: 'wrap',
                            overflow: 'hidden',
                          }}
                          {...provided.droppableProps}
                        >
                          {showedItems.map((item: any, index: number) => (
                            <Draggable
                              key={item}
                              draggableId={item}
                              index={index}
                            >
                              {provided => (
                                <Box
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  sx={getItemStyle(
                                    provided.draggableProps.style,
                                  )}
                                >
                                  <Chip
                                    tabIndex={-1}
                                    label={item}
                                    onDelete={handleDelete(item)}
                                    sx={{
                                      background: theme =>
                                        isRecommended
                                          ? theme.palette.info.main
                                          : theme.palette.grey[300],
                                    }}
                                  />
                                </Box>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </Box>
                      )}
                    </Droppable>
                  </DragDropContext>
                ),
                onBlur: (event: React.FocusEvent<any>) => {
                  setFocused(false)
                  onBlur?.(event)
                },
                onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                  handleInputChange(event)
                  onChange?.(event)
                },
                onFocus: (event: React.FocusEvent<any>) => {
                  setFocused(true)
                  onFocus?.(event)
                },
                onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => {
                  handleKeyDown(event)
                },
                placeholder: placeholder ?? 'Add tags',
              }}
              InputLabelProps={{
                className: 'juvo-input-label',
              }}
              value={inputValue}
              fullWidth
              variant="standard"
              id="tags"
              name="tags"
              label={title}
              color="primary"
              sx={{
                marginBottom: '16px',
                '& .MuiInputBase-root': {
                  display: 'flex',
                  flexDirection: 'column',
                  width: { xs: '275px', sm: '500px', md: '650px' },
                  border: '1px solid var(--color-border)',
                  padding: '4px 12px',
                  marginTop: '0',
                  '&:before': {
                    display: 'none',
                  },
                  '&:after': {
                    display: 'none',
                  },
                  '&:hover': {
                    borderColor: 'transparent !important',
                    outline: '2px solid var(--color-hover)',
                  },
                  ...getFocusStyle(),
                },
              }}
            />
          </div>
        )
      }}
    </Downshift>
  )
}

export default TagList
