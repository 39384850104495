import { Map } from 'immutable'
import { match } from 'ts-pattern'

import {
  AppId,
  AppItem,
  AppRegistration,
  AppSkeleton,
  clearAppRegistration,
  clearOutCommand,
  ComponentModel,
  CritErr,
  critNetworkErr,
  Guid,
  IdiosyncraticComponentType,
  InitialAppData,
  isCarouselModel,
  isFileDropModel,
  isIdiosyncraticCompType,
  isListModel,
  isMultiSelectModel,
  ListModel,
  Message,
  User,
  Warning,
  WarningIssue,
} from '../types'
import {
  imGet,
  imMapEmpty,
  ImmutableMap,
  imSet,
  imToJS,
} from '../utils/ImmutableMap'
import { logDebug, logErr, logFatal, logInfo, logWarn } from '../utils/Logger'
import { isDefined, isUndefined, Nullable } from '../utils/Undefined'
import {
  assumeRights,
  Either,
  getLocalFavorites,
  left,
  right,
  setLocalFavorites,
  zipWith,
} from '../utils/Common'
import { FetchError } from '../utils/Fetch'
import { getJuvoConfig } from '../utils/JuvoConfig'

import {
  addIdsToCompIfMissing,
  getUniqueId,
  msgListRecommendations,
  removeDuplicateHashes,
  userAcceptRecommendedBestEffort,
} from './Component'
import { CmdHandler, isCmdMessage } from './CustomComponents'

export type SuccessState = {
  type: 'success'
  guid: Guid
  appList: AppItem[]
  apps: ImmutableMap<AppId, [Warning, AppRegistration]>
  suggestions: Nullable<AppId[]>
  juvoUser: User
  unexpectedWarn?: Nullable<WarningIssue>
}

export type ErrorState = {
  type: 'critical'
  err: CritErr
}

export const getSuggestedApps = (
  apps: ImmutableMap<AppId, [Warning, AppRegistration]>,
  suggestions: Nullable<AppId[]>,
): AppItem[] => {
  // If app not in the map, consider this as unexpected error (left)
  const interested = (
    appid: AppId,
    app: [Warning, AppRegistration] | undefined,
  ): Either<string, AppRegistration> => {
    return app ? right(app[1]) : left(`No registration for ${appid}`)
  }

  const suggestedApps = () => {
    const { environment } = getJuvoConfig()
    const appsIds = imToJS(apps).keys()
    const localFavorites = getLocalFavorites(environment)
    const appsArr = Array.from(appsIds)
    const intersection = localFavorites.filter(val => appsArr.includes(val))

    setLocalFavorites(environment, intersection) // Set favorites in case the outdated name was used

    if (intersection.length < localFavorites.length) {
      logWarn('Some favorited apps no longer exist')
    }

    // If favorites exist then return favorites
    if (intersection.length > 0) {
      return intersection
      // If suggestions exist then return suggestions
    } else if (suggestions && suggestions.length > 0) {
      return suggestions
    }
    // Return first 4 apps
    else {
      return appsArr.slice(0, 4)
    }
  }

  const eAppLst = suggestedApps().map(appId =>
    interested(appId, imGet(apps)(appId)),
  )

  //NOTE  assumeRights(eAppLst) returns AppRegistration[] but this can be used as is as AppItem[]
  return assumeRights(eAppLst)
}

export type State = SuccessState | ErrorState

export const clearState = (s: State): State =>
  match(s)
    .with({ type: 'success' }, st => {
      const appIds = st.appList.map(app => app.app_id)
      return appIds.reduce(
        (acc, appId) => stateOverApp(appId, clearAppRegistration, acc),
        s,
      )
    })
    .with({ type: 'critical' }, st => st)
    .exhaustive()

export const stateDebug = (s: State) =>
  match(s)
    .with({ type: 'success' }, st => imToJS(st.apps))
    .with({ type: 'critical' }, st => st.err)
    .exhaustive()

export const getGlobalWarn = (s: State): Nullable<WarningIssue> =>
  match(s)
    .with({ type: 'success' }, st => st.unexpectedWarn)
    .with({ type: 'critical' }, () => null)
    .exhaustive()

export const dismissGlobalWarn = (s: State): State =>
  match(s)
    .with({ type: 'success' }, st => {
      return { ...st, unexpectedWarn: null }
    })
    .with({ type: 'critical' }, st => st)
    .exhaustive()

export const containerSetChild = (
  childComp: ComponentModel,
  inx: number,
  containerComp: ComponentModel,
): ComponentModel => {
  const children: ComponentModel[] = containerComp.tchildren || []
  const newChildren = children.map((cp, ix) => (ix === inx ? childComp : cp))
  return { ...containerComp, tchildren: newChildren }
}

export const stateSetComponent = (
  appId: AppId,
  newcomp: ComponentModel,
  state: State,
): State => {
  const uniqueCid: string = getUniqueId(newcomp)
  return stateOverApp(
    appId,
    appreg => {
      // this changes top level components only, we can convert to using appRegistrationSetComponent
      // but is seems to be not needed as the updates compose as lenses
      const debugupdates: ComponentModel[] = []
      const newcoms: ComponentModel[] = appreg.app_skeleton.components.map(
        oldcomp => {
          if (getUniqueId(oldcomp) === uniqueCid) {
            debugupdates.push(oldcomp)
            return newcomp
          } else {
            return oldcomp
          }
        },
      )
      if (debugupdates.length === 0) {
        logWarn('stateSetComponent - no match', {
          newcomp: newcomp,
          oldcomps: appreg.app_skeleton.components,
        })
      } else if (debugupdates.length > 1) {
        logWarn('stateSetComponent - more than one match', {
          newcomp: newcomp,
          replaced: debugupdates,
        })
      }
      //keep useful for troubleshooting
      // else {
      //   logDebug('stateSetComponent - expected', {
      //     newcomp: newcomp,
      //     replaced: debugupdates,
      //     newcps: newcoms
      //   })
      // }
      const newAppSkeleton = { ...appreg.app_skeleton, components: newcoms }

      return { ...appreg, app_skeleton: newAppSkeleton }
    },
    state,
  )
}

// used to adjust out message only
export const appRegistrationSetComponent =
  (newcomp: ComponentModel) =>
  (appreg: AppRegistration): AppRegistration => {
    let alreadyReplacedFasthack = false //avoid fold on that level, stores if component was already replaced
    const newcoms: ComponentModel[] = appreg.app_skeleton.components.map(
      oldcomp => {
        if (alreadyReplacedFasthack) return oldcomp
        else {
          const [m, res] = setComponentRecursive(newcomp)(oldcomp)
          alreadyReplacedFasthack = m //avoid fold which recreates arrays
          return res
        }
      },
    )
    const newAppSkeleton = { ...appreg.app_skeleton, components: newcoms }

    return { ...appreg, app_skeleton: newAppSkeleton }
  }

export const setComponentRecursive =
  (newcomp: ComponentModel) =>
  (oldcomp: ComponentModel): [boolean, ComponentModel] => {
    const uniqueCid: string = getUniqueId(newcomp)

    if (getUniqueId(oldcomp) === uniqueCid) {
      return [true, newcomp]
    } else if (Array.isArray(oldcomp.tchildren)) {
      // this is fold we could go to a hack like above if slow
      const { match, cs } = oldcomp.tchildren.reduce(
        (
          acc: { match: boolean; cs: ComponentModel[] },
          oldchild: ComponentModel,
        ) => {
          if (acc.match) return { match: true, cs: [...acc.cs, oldchild] }
          else {
            const [m, nc] = setComponentRecursive(newcomp)(oldchild)
            return { match: m, cs: [...acc.cs, nc] }
          }
        },
        { m: false, cs: [] as ComponentModel[] },
      )

      if (match) {
        return [match, { ...oldcomp, tchildren: cs }]
      } else return [match, oldcomp]
    } else if (isDefined(oldcomp.child)) {
      const [changed, res] = setComponentRecursive(newcomp)(oldcomp.child)
      if (changed) {
        return [changed, { ...oldcomp, child: res }]
      } else {
        return [false, oldcomp]
      }
    } else {
      return [false, oldcomp]
    }
  }

export const stateSetSkeleton = (
  appId: AppId,
  newskel: AppSkeleton,
  state: State,
): State => {
  return stateOverApp(
    appId,
    appreg => {
      return { ...appreg, app_skeleton: newskel }
    },
    state,
  )
}

export const stateOverApp = (
  appId: AppId,
  fn: (_: AppRegistration) => AppRegistration,
  state: State,
): State => {
  return match(state)
    .with({ type: 'success' }, st => {
      const old = imGet(st.apps)(appId)
      if (old) {
        const nmp = imSet(st.apps)(appId, [old[0], fn(old[1])])
        return { ...st, apps: nmp }
      } else {
        logWarn({
          type: 'stateOverAppErr',
          warn: 'Unexpected, missing app in stateOverApp:' + appId,
        })
        return st
      }
    })
    .with({ type: 'critical' }, () => {
      return state
    })
    .exhaustive()
}

export const appOverSkeleton = (
  fn: (_: AppSkeleton) => AppSkeleton,
  appReg: AppRegistration,
): AppRegistration => {
  return { ...appReg, app_skeleton: fn(appReg.app_skeleton) }
}

export const mergeListNoOverride =
  (debugLoc: string) =>
  (storelist: ListModel) =>
  (msglist: ListModel): ListModel => {
    //components by id
    //components by id
    const msgChildrenById = Map<string, ComponentModel>(
      msglist.tchildren
        .filter(msgchildcomp => msgchildcomp.id) //remove recommended components, (no id)
        .map(msgchildcomp => [msgchildcomp.id, msgchildcomp]),
    )

    const newStateTChildren = storelist.tchildren.map(statecmp => {
      const frommsg = msgChildrenById.get(statecmp.id)
      //if matching id was sent from server merge, otherwise assume user just added this component
      //    const res = frommsg ? updateComp(statecmp, frommsg): statecmp
      //    return res;
      if (frommsg) {
        // logDebug({mergeListMsg: "merging", state: statecmp, msg: frommsg})
        return updateComp(debugLoc)(true)(false)(statecmp, frommsg)
      } else {
        // logDebug({mergeListMsg: "assuming user just added", state: statecmp})
        return statecmp
      }
    })

    const newRecoms = msgListRecommendations(storelist.tignore ?? [])(msglist)

    const list: ListModel = {
      ...storelist,
      tchildren: newStateTChildren,
      numchildren: newStateTChildren.length,
      //numchildren is not changed
      trecommend: newRecoms,
    }

    if (newStateTChildren.length > 0) {
      return { ...list, trecommend: removeDuplicateHashes(newRecoms) }
    } else {
      //TODO we could do that faster without reusing userAcceptRecommended
      const res = newRecoms.reduce((acc, { content_hash, component }) => {
        return userAcceptRecommendedBestEffort(component)(content_hash)(acc)
      }, list)
      //Please keep this, much easier to debug
      //logDebug("mergeListNoOverride", {res: res})
      return res
    }
  }

/**
 * With override in place just accept what server send
 */
export const mergeListOverride =
  (storelist: ListModel) =>
  (msglist: ListModel): ListModel => {
    const newStateTChildren = msglist.tchildren.filter(
      msgchildcomp => msgchildcomp.id,
    )

    const newRecoms = msgListRecommendations([])(msglist)

    const list: ListModel = {
      ...storelist,
      tchildren: newStateTChildren,
      numchildren: newStateTChildren.length,
      trecommend: newRecoms,
      tignore: [],
    }

    //TODO we could do that faster without reusing userAcceptRecommended
    const res = newRecoms.reduce((acc, { content_hash, component }) => {
      return userAcceptRecommendedBestEffort(component)(content_hash)(acc)
    }, list)
    //please keep this, much easier to debug
    return res
  }

export const updateComp =
  (debugLoc: string) =>
  (inList: boolean) =>
  (isOverride: boolean) =>
  (statecomp: ComponentModel, msgcomp: ComponentModel): ComponentModel => {
    if (!msgcomp.id && statecomp.id) {
      if (!inList)
        logWarn(`${debugLoc} - server lost ID`, {
          was: statecomp,
          now: msgcomp,
        })
    } else if (msgcomp.id !== statecomp.id) {
      logErr(`${debugLoc} - server changed ID`, {
        was: statecomp,
        now: msgcomp,
      })
    } else if (!statecomp.id) {
      logWarn(`${debugLoc} - no component ID in state`, {
        dta: statecomp,
      })
    }
    const id = inList ? msgcomp.id : msgcomp.id || statecomp.id
    const defres = { ...msgcomp, id: id }
    if (statecomp.id && msgcomp?.id && statecomp.id !== msgcomp.id) {
      logDebug(
        `updateComp - ${debugLoc}: updateComp handling incompatible IDs`,
        {
          updateCompErr: 'Incompatible Ids',
          statecomp: statecomp,
          msgcomp: msgcomp,
        },
      )
      return defres
    } else {
      if (isListModel(statecomp)) {
        if (isListModel(defres)) {
          if (isOverride) return mergeListOverride(statecomp)(defres)
          else return mergeListNoOverride(debugLoc)(statecomp)(defres)
        } else {
          logWarn(`${debugLoc}:`, {
            updateCompErr: 'Incompatible Nesting',
            statecomp: statecomp,
            msgcomp: msgcomp,
          })
          return defres
        }
      } else if (isCarouselModel(statecomp)) {
        //carousel starts empty in the skeleton and then will be server controlled
        //any actions involving carousel need to force loading
        // complete (or any non-loading action) hopefully does not set position or change list size,
        // if it does we have no way to resolving
        // the conflicts that will not upset the user
        // special "rude" option allows to override components on each message.
        if (
          'rude' !== statecomp.carousel_type &&
          Array.isArray(statecomp.tchildren) &&
          statecomp.tchildren.length > 0
        ) {
          defres.tchildren = zipWith(
            statecomp.tchildren,
            msgcomp.tchildren,
            updateComp(debugLoc)(inList)(isOverride),
          )
          //assumes "static" carousel with numchildren not modified and children not reordered (these are new versions of the same components)
        } else {
          //note defres clones msgcomponent already, we still need to add id's
          // we always populate ids on lists
          // This initializes the component if it was not initialized before (was empty) or it carousel is rude
          defres.tchildren = msgcomp.tchildren.map(addIdsToCompIfMissing)
        }
        // server can change postion, isOverride assumes loading state present otherwise server should not throw user around the carousel
        if ('rude' === statecomp.carousel_type) {
          if (isDefined(msgcomp.position)) {
            defres.position = msgcomp.position
          } else {
            defres.position = 0
          }
        } else {
          if (isOverride && isDefined(msgcomp.position)) {
            defres.position = msgcomp.position
          } else if (
            isUndefined(statecomp.tchildren) ||
            (Array.isArray(statecomp.tchildren) &&
              statecomp.tchildren.length === 0)
          ) {
            //for dynamically created lists that start undefined or empty it is safe to change position on the user because user has no way
            //to interact with the carousel children
            //This is safe no matter if the statecomp.position was set to 0 or is still null
            defres.position = msgcomp.position
          } else {
            //user owns position otherwise
            if (
              isDefined(msgcomp.position) &&
              msgcomp.position !== statecomp.position
            ) {
              logDebug(
                `updateComp - ${debugLoc}: carousel position, ignoring server, use override if you want that`,
                { userPos: statecomp.position, msgPos: msgcomp.position },
              )
            }
            defres.position = statecomp.position
          }
        }
        if (isUndefined(defres.position)) {
          defres.position = 0
        }
        return defres
      } else {
        if (isOverride) {
          //need to recurse down to repair missing IDs
          if (Array.isArray(statecomp.tchildren)) {
            if (!Array.isArray(msgcomp.tchildren)) {
              logWarn(`Incompatible Nesting ${debugLoc}`, {
                updateCompErr: 'Incompatible Nesting',
                statecomp: statecomp,
                msgcomp: msgcomp,
              })
            } else {
              if (statecomp.type === 'filter-results') {
                //TODO create idiosyncratic handling for this, fix server losing IDs, or rename tchildren
                defres.tchildren = [...msgcomp.tchildren]
              } else {
                //TODO this is needed because server keeps dropping ids, this will try to reinstate them
                defres.tchildren = zipWith(
                  statecomp.tchildren,
                  msgcomp.tchildren,
                  updateComp(debugLoc)(inList)(isOverride),
                )
              }
            }
          }
          return defres
        } else if (isIdiosyncraticCompType(statecomp.type)) {
          //isOverride is handled genrically above
          return mergeIdiosyncratic(statecomp.type, statecomp, defres)[1]
        } else {
          if (statecomp.value) defres.value = statecomp.value //we do not have any internal state on components at this time
          if (Array.isArray(statecomp.tchildren)) {
            if (!Array.isArray(msgcomp.tchildren)) {
              logWarn(`Incompatible Nesting ${debugLoc}`, {
                updateCompErr: 'Incompatible Nesting',
                statecomp: statecomp,
                msgcomp: msgcomp,
              })
            } else {
              defres.tchildren = zipWith(
                statecomp.tchildren,
                msgcomp.tchildren,
                updateComp(debugLoc)(inList)(isOverride),
              )
            }
          } else if (isDefined(statecomp.child)) {
            if (isUndefined(msgcomp.child)) {
              logWarn(`Incompatible Child ${debugLoc}`, {
                statecomp: statecomp,
                msgcomp: msgcomp,
              })
            } else {
              defres.child = updateComp(debugLoc)(inList)(isOverride)(
                statecomp.child,
                msgcomp.child,
              )
            }
          }
          return defres
        }
      }
    }
  }

/**
 * Define what to do when merging server messages for IdiosyncraticComponentType
 * decide what part of incomming 'msgcomp' to use vs what part of 'statecomp' to use.
 * These are currnetly synonyms of 'any' and to get type safety
 * we return a tuple.
 *
 * This is not used with "override" merges
 */
const mergeIdiosyncratic = (
  ctype: IdiosyncraticComponentType,
  statecomp: ComponentModel,
  msgcomp: ComponentModel,
): [IdiosyncraticComponentType, ComponentModel] => {
  //do not simplify return type to ComponentModel
  switch (ctype) {
    case 'file-drop': {
      // user owned we do not expect server suggestions here?
      if (
        isFileDropModel(statecomp) &&
        Array.isArray(statecomp.files_raw) &&
        statecomp.files_raw.length > 0
      )
        return [ctype, { ...msgcomp, files_raw: statecomp.files_raw }]
      else return [ctype, msgcomp]
    }
    case 'multi-select': {
      if (
        isMultiSelectModel(statecomp) &&
        Array.isArray(statecomp.selections) &&
        statecomp.selections.length > 0
      )
        return [ctype, { ...msgcomp, selections: statecomp.selections }]
      else return [ctype, msgcomp]
    }
    case 'text-snippets': {
      //TODO this is wrong but best we can do?
      //this assumes server can suggests, it is not safe
      logInfo('text-snippets are not supported yet')
      return [ctype, msgcomp]
    }
    case 'claim-list': {
      if (Array.isArray(statecomp.claims) && statecomp.claims.length > 0)
        return [ctype, statecomp]
      else return [ctype, msgcomp]
    }
    case 'filter-results': {
      return [ctype, msgcomp]
    }
  }
}

/**
 * This will clear clearOutCommand dismissing spaceship for valid msgAppId
 * It will also set global warning that will be displayed in current app until dismissed
 * @param w
 * @param st
 * @param msgAppId
 * @returns
 */
const processGlobalWarning = (
  w: Warning,
  st: SuccessState,
  msgAppId: Nullable<AppId>,
): State => {
  const st1 = match(w)
    .with({ type: 'warning' }, warn => {
      return { ...st, unexpectedWarn: warn }
    })
    .with({ type: 'noWarn' }, () => st)
    .exhaustive()
  if (msgAppId && msgAppId !== '' && msgAppId !== 'unknown') {
    return stateOverApp(msgAppId, clearOutCommand, st1)
  } else return st1
}
//REVIEW we need Java-doc style /** comments */ on top of definitions
/**
 * Given a message from server update model state
 */
export const updateState = (
  officeCmdHandler: CmdHandler,
  msg: Message,
  state: State,
): State => {
  return match(state)
    .with({ type: 'success' }, st => {
      const payload_any: any = msg?.payload
      //server can send error information without any components
      const hasComponents: boolean =
        isDefined(msg) && isDefined(msg.payload.components)
      if (
        msg?.payload.command &&
        (msg?.payload.command['@'] === 'display' ||
          msg?.payload.command['@'] === 'display-result')
      ) {
        const appid = msg?.payload.app_id
        const currentApp = imGet(st.apps)(appid)

        if (currentApp) {
          const [warn, stateappreg] = currentApp
          const isOverride = msg?.payload.command.sub_command === 'override'
          const napps: AppRegistration = hasComponents
            ? {
                ...stateappreg,
                app_skeleton: {
                  ...stateappreg.app_skeleton,
                  components: zipWith(
                    stateappreg.app_skeleton.components,
                    msg?.payload.components,
                    updateComp(stateappreg.app_id)(false)(isOverride),
                  ),
                  command: msg?.payload.command, //Note: this replaces command removing any internal state (e.g. dialog box dismissal)
                },
                out_command: null, //Note: this removes internal state (loading state) (do not remove comment)
              }
            : {
                ...stateappreg,
                app_skeleton: {
                  ...stateappreg.app_skeleton,
                  command: msg?.payload.command, //Note: this replaces command removing any internal state (e.g. dialog box dismissal)
                },
                out_command: null, //Note: this removes internal state (loading state) (do not remove comment)
              }
          const nmp = imSet(st.apps)(appid, [warn, napps])
          const res = { ...st, apps: nmp }
          logDebug(`updateState ${appid}`, {
            newReg: napps,
            updatedState: res,
          })
          return res
        } else {
          logDebug({
            updateStateErr:
              'Message for unknown component ' + msg?.payload?.app_id,
            msg: msg,
          })
          const warn: Warning = {
            type: 'warning',
            warn: 'Message for unknown component ' + msg?.payload?.app_id,
            dta: msg,
          }
          return processGlobalWarning(warn, st, null)
        }
      } else {
        const appid = msg?.payload.app_id
        if (isCmdMessage(payload_any)) {
          const cmdRes = officeCmdHandler(payload_any) //Do not update state, this  message was intended for office handler.
          return processGlobalWarning(cmdRes, st, appid)
        } else {
          //TODO need to invoke handler callback (to be defined and passed here)
          logWarn({ updateStateErr: 'Unsupported message', msg: msg })
          const warn: Warning = {
            type: 'warning',
            warn: 'Unsupported message',
            dta: msg,
          }
          return processGlobalWarning(warn, st, appid)
        }
      }
    })
    .with({ type: 'critical' }, () => {
      logFatal({
        updateStateErr: 'Not updatable (critical) state',
        msg: msg,
      })
      return state
    })
    .exhaustive()
}

export const emptyState: State = {
  type: 'critical',
  err: {
    msg: 'Not Initialized',
    hint: '',
    err: {},
  },
}

const bootstrapResR = (
  usr: User,
  { suggestions, guid, app_list }: InitialAppData,
): State => {
  const dta = app_list.reduce(
    (acc, appreg) => {
      const item: [Warning, AppRegistration] = [{ type: 'noWarn' }, appreg]

      return {
        lst: [
          ...acc.lst,
          {
            app_id: appreg.app_id,
            app_name: appreg.app_name,
            app_agent: appreg.app_agent,
          },
        ],
        mp: imSet(acc.mp)(appreg.app_id, item),
      }
    },
    {
      lst: [] as AppItem[],
      mp: imMapEmpty<AppId, [Warning, AppRegistration]>(),
    },
  )

  return {
    guid,
    suggestions,
    type: 'success',
    appList: dta.lst,
    apps: dta.mp,
    juvoUser: usr,
  }
}

const bootstrapResL = (fetchErr: FetchError): State => {
  return {
    type: 'critical',
    err: critNetworkErr('Failed to establish JUVO session', fetchErr),
  }
}

export const bootstrapRes = (
  usr: User,
  res: Either<FetchError, InitialAppData>,
): State => {
  if (res.type == 'left') return bootstrapResL(res.content)
  else return bootstrapResR(usr, res.content)
}
