import React from 'react'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import { DocPreviewModel } from '../../../types'
import { logWarn } from '../../../utils'

const DocPreviewUnsafe: React.FC<{ comp: DocPreviewModel }> = ({
  comp,
}) => {
  const { link, canDownload, title } = comp

  if (!link) {
    logWarn('Missing link property in DocPreview component')
    return <></>
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-around',
          marginBottom: 2,
        }}
      >
        <Typography variant="body1">{title ?? 'Document Preview'}</Typography>
        {canDownload && (
          <Tooltip arrow title="Download">
            <a
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
              download={link.name}
            >
              <IconButton
                className="fa-light fa-download"
                size="small"
                color="primary"
                sx={{
                  color: theme => theme.palette.primary.main,
                  background: theme => theme.palette.grey[200],
                  '&:hover': {
                    background: theme => theme.palette.grey[300],
                  },
                }}
              />
            </a>
          </Tooltip>
        )}
      </Box>

      <iframe
        src={`https://docs.google.com/viewerng/viewer?url=${encodeURIComponent(
          link.url,
        )}&embedded=true`}
        style={{
          width: '100%',
          height: '100vh',
          border: '1px solid transparent',
        }}
      />
    </>
  )
}

export default DocPreviewUnsafe
