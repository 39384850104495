import React from 'react'

import {
  EventHandlerMap,
  mergedComponentEvents,
  setComponentValue,
} from '../store'
import { Command, ComponentModel } from '../types'
import { validateSingleComponent } from '../store/Validation'

import { isDefined } from './Undefined'

export type InputStateProps = {
  handleBlur: () => void
  value: string | null
  setValue: (newValue: string) => void
  handlersFromServer: EventHandlerMap
  errorMessage: string | null
  hasErrors: boolean
}

/*
This component provides local state hook for displaying possibly large amount of input. 
This locally defined state hook is used ot support component displaying values and 
to issue 'lastminute-changes' outgoing messages to the server for onblur and key events. 
Otherwise the local state is discarded, main state is updated independently when onBlur happens.
*/
const MakeInputState: React.FC<{
  component: ComponentModel
  onCommand: (cmd: Command, c: ComponentModel) => void
  onChange: (component: ComponentModel) => void
  transformValueFromServer?: (value: string) => string
  transformValueToServer?: (value: string | null) => any
  children(props: InputStateProps): JSX.Element
  propagateValidation?: boolean
}> = ({
  component,
  onCommand,
  onChange,
  transformValueFromServer,
  transformValueToServer,
  children,
  propagateValidation,
}) => {
  const valueFromServer = component.value

  const newComp = (newValue: string | null) => {
    const valueToSend = isDefined(transformValueToServer)
      ? transformValueToServer(newValue)
      : newValue
    return setComponentValue(component)(valueToSend)
  }

  const changeHandler = React.useCallback(
    (newValue: string | null) => {
      onChange(newComp(newValue))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [component, onChange, transformValueToServer],
  )

  const transform = React.useCallback(
    (value: string) => {
      return isDefined(transformValueFromServer) && isDefined(value)
        ? transformValueFromServer(value)
        : value
    },
    [transformValueFromServer],
  )

  const [value, setLocalValue] = React.useState<string | null>(
    transform(valueFromServer),
  )
  const [visited, setVisited] = React.useState(false)

  React.useEffect(() => {
    if (isDefined(propagateValidation)) {
      setVisited(propagateValidation)
    }
  }, [propagateValidation])

  React.useEffect(() => {
    setLocalValue(transform(valueFromServer))
  }, [valueFromServer, transform])

  const eventHandlers = mergedComponentEvents(
    () => newComp(value),
    { type: 'from-lastminute-changes', fn: onCommand },
    changeHandler,
  )
  const {
    onChange: mergedChangeHandler,
    onBlur,
    ...handlersFromServer
  } = eventHandlers
  /* TODO: extend event handlers const { onKeyUp, onKeyDown, onKeyPress } = eventHandlers */

  const errors = visited ? validateSingleComponent(component) : []
  const hasErrors = visited && errors.length > 0

  const handleBlur = () => {
    const newValue = value ? value.toString().trim() : null
    mergedChangeHandler(newValue)
    setVisited(true)
    onBlur && onBlur() //PLAT419: this would trigger setApps (onAppChange) in JuvoApp in the same rendering
  }

  return children({
    handleBlur,
    value,
    setValue: value => {
      setLocalValue(value)
    },
    handlersFromServer,
    hasErrors,
    errorMessage: hasErrors ? errors[0].validationMsg : null,
  })
}

export default MakeInputState
