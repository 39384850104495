import { CompareData } from '../types/Model'

export const getComparisonClasses: <T>(
  chips: CompareData<T>[],
  value: T,
  compareFn: (a: T, b: T) => boolean,
) => string[] = (chips, value, compareFn) => {
  if (chips.length < 1) {
    return []
  } else if (chips.length === 1 && compareFn(chips[0].value, value)) {
    return ['valid']
  } else if (chips.length > 1) {
    for (const c of chips) {
      if (compareFn(c.value, value)) {
        return ['mismatch']
      }
    }
  }
  return ['invalid']
}

export const stringComparer = (a: string, b: string) => {
  return a.toLowerCase() === b.toLowerCase()
}

export const booleanComparer = (a: boolean, b: boolean) => {
  return a === b
}

export const dateComparer = (a: string | Date, b: string | Date) => {
  let dateA = a
  let dateB = b
  if (typeof a === 'string') {
    dateA = new Date(`${a}T00:00:00`)
  }
  if (typeof b === 'string') {
    dateB = new Date(`${b}T00:00:00`)
  }

  return (dateA as Date).getTime() === (dateB as Date).getTime()
}
