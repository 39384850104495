import React, { FormEvent, useLayoutEffect, useRef, useState } from 'react'

export const AutoResizeInput = React.forwardRef((props: any, ref: any) => {
  const [content, setContent] = useState('')
  const [width, setWidth] = useState(200)
  const ghostRef = useRef<HTMLDivElement>(null)

  // We use useLayoutEffect instead of useEffect so that the input element has time to match the ghost element BEFORE redraw, otherwise it looks jerky
  useLayoutEffect(() => {
    setWidth(ghostRef.current?.offsetWidth ?? 0)
    setContent(props.value)
  }, [props.value])

  const handleInputChange = (event: FormEvent<any>) => {
    if (props.onChange) {
      props.onChange(event)
    }
  }

  // Uses a ghost div element to determine the length the input should be, since inputs do not automatically resize
  return (
    <>
      <input
        ref={ref}
        value={content}
        {...props}
        className="juvo-compare-input"
        onBlur={event => handleInputChange(event)}
        style={{ width: width }}
      />
      <div className="juvo-compare-input-ghost" ref={ghostRef}>
        {props.value}
      </div>
    </>
  )
})

// Need this so that the linter doesn't get angry
AutoResizeInput.displayName = 'AutoResizeInput'
