import React from 'react'
import Typography from '@mui/material/Typography'

import { DashboardModel } from '../../types'

const Dashboard: React.FC<{ comp: DashboardModel }> = ({ comp }) => {
  const { height, title, url } = comp

  return (
    <>
      {title && (
        <Typography variant="h4" sx={{ marginBottom: 2, textAlign: 'center' }}>
          {title}
        </Typography>
      )}
      {url && (
        <iframe
          src={url}
          style={{
            width: '100%',
            height: height,
            border: '1px solid transparent',
          }}
        />
      )}
    </>
  )
}

const MemoizedDashboard = React.memo(Dashboard)

export default MemoizedDashboard
