import React from 'react'
import Typography from '@mui/material/Typography'

import { DocPreviewModel } from '../../../types'
import { logWarn } from '../../../utils'

const DocPreview: React.FC<{ comp: DocPreviewModel }> = ({ comp }) => {
  const { link, title } = comp

  if (!link) {
    logWarn('Missing link property in DocPreview component')
    return <></>
  }

  return (
    <>
      <Typography variant="body1">{title ?? 'Document Preview'}</Typography>
      {link.preview_url && (
        <iframe
          src={`${link.preview_url}&scrollbar=0&navpanes=0`}
          style={{
            width: '100%',
            height: '100vh',
            border: '1px solid transparent',
          }}
        />
      )}
    </>
  )
}

export default DocPreview
