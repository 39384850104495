import React, { useEffect, useState } from 'react'
import {
  AlertColor,
  Alert,
  Box,
  Typography,
  useTheme,
  Slide,
  SlideProps,
  Snackbar,
} from '@mui/material'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'

import { SnackbarModel } from '../../../types'
import { Nullable, isDefined, isUndefined } from '../../../utils'
import {
  getAlertSeverityBySubType,
  getNotificationColorFromSeverity,
  getNotificationIconFromSeverity,
} from '../../../utils/Notifications'
import {
  SNACKBAR_DURATION,
  SNACKBAR_TRANSITION,
} from '../../../constants/Constants'

import CircularProgressClose from './CircularProgressClose'

export const JuvoSnackbar: React.FC<{
  comp: Nullable<SnackbarModel>
  isOpen: boolean
  handleClose: (event?: React.SyntheticEvent | Event, reason?: string) => void
}> = ({ comp, isOpen, handleClose }) => {
  const theme = useTheme()
  const [isExpanded, setIsExpanded] = useState(false)
  const [severity, setSeverity] = useState<AlertColor>('success')
  const [title, setTitle] = useState('')
  const [subtitle, setSubtitle] = useState('')

  useEffect(() => {
    if (isDefined(comp)) {
      setTitle(comp.title)
      setSubtitle(comp.subtitle)
      setSeverity(getAlertSeverityBySubType(comp.sub_type))
    }
  }, [comp])

  return (
    <BaseSnackBar isOpen={isOpen} handleClose={handleClose}>
      <Alert
        action={
          <CircularProgressClose
            color={theme.palette.grey[600]}
            onClose={() => handleClose(undefined, 'buttonclose')}
            duration={comp?.duration ?? SNACKBAR_DURATION}
          />
        }
        icon={
          <Box
            sx={{
              height: '100%',
              width: '32px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              background: 'transparent',
              '.MuiSvgIcon-root': {
                color: theme => theme.palette[severity].main,
                width: '1.4em',
                height: '1.4em',
              },
            }}
          >
            {getNotificationIconFromSeverity(severity, {
              fontSize: '32px',
            })}
          </Box>
        }
        onClose={close}
        severity={severity}
        sx={{
          width: '100%',
          background: 'white',
          borderRadius: 0,
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
          border: style => `1px solid ${style.palette.grey[200]}`,
          position: 'relative',
          color: 'black',
          padding: '8px 16px 8px 30px',
          '.MuiAlert-action': {
            alignItems: 'center',
          },
          '&:before': {
            content: "''",
            position: 'absolute',
            top: '50%',
            left: '6px',
            background: getNotificationColorFromSeverity(severity, theme),
            transform: 'translateY(-50%)',
            height: '85%',
            width: '6px',
          },
        }}
      >
        <MuiAccordion
          expanded={isExpanded}
          onChange={() => setIsExpanded(!isExpanded)}
          TransitionProps={{
            mountOnEnter: true,
            unmountOnExit: true,
            timeout: 0,
          }}
          className="juvo-accordion"
          sx={{
            // margin is set to avoid the movement because of the MuiPaper-root-MuiAccordion-root.Mui-expanded
            // class that cannot be override in the Paper component
            margin: '6px 0',
            '.MuiAccordionSummary-root.Mui-expanded': {
              minHeight: '48px',
            },
            '.MuiAccordionDetails-root': {
              p: '8px 8px 8px 16px !important',
            },
            boxShadow: 'none',
            background: 'transparent',
          }}
        >
          <MuiAccordionSummary
            sx={{
              flexDirection: 'row-reverse',
              '.Mui-expanded': {
                // important is used to override the default margin of MuiAccordionSummary-content.Mui-expanded
                margin: '0 !important',
              },
              '.MuiAccordionSummary-content': {
                display: 'flex',
                alignItems: 'center',
                margin: '0',
              },
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: '18px',
                  color: theme => theme.palette.text.primary,
                  fontWeight: '600',
                  textAlign: 'left',
                }}
              >
                {title}
              </Typography>
              {!isExpanded && (
                <Typography
                  sx={{
                    color: theme => theme.palette.grey[700],
                    fontSize: '14px',
                    textAlign: 'left',
                  }}
                >
                  {truncateString(subtitle)}
                </Typography>
              )}
            </Box>
          </MuiAccordionSummary>
          <MuiAccordionDetails>
            <Typography
              sx={{
                color: theme => theme.palette.grey[700],
                fontSize: '14px',
                textAlign: 'left',
              }}
            >
              {subtitle}
            </Typography>
          </MuiAccordionDetails>
        </MuiAccordion>
      </Alert>
    </BaseSnackBar>
  )
}

const TransitionUp = (props: SlideProps) => {
  const transitionProps = { ...props, duration: SNACKBAR_TRANSITION }
  return <Slide {...transitionProps} direction="up" />
}

const BaseSnackBar: React.FC<{
  isOpen: boolean
  handleClose: (event: React.SyntheticEvent | Event, reason?: string) => void
  children: React.ReactElement
}> = ({ isOpen, handleClose, children }) => (
  <Snackbar
    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    TransitionComponent={TransitionUp}
    open={isOpen}
    ClickAwayListenerProps={{ onClickAway: event => event.preventDefault() }}
    onClose={handleClose}
    sx={{
      zIndex: theme => theme.zIndex.snackbar,
      maxWidth: { sm: '95%', md: '90%' },
      minWidth: { sm: '75%', md: '60%', lg: '50%' },
      marginBottom: '10px',
    }}
  >
    {children}
  </Snackbar>
)

export const truncateString = (s: string | undefined, length = 80): string => {
  if (isUndefined(s)) return ''
  return s.length <= length ? s : s.substring(0, length - 1) + '...'
}
