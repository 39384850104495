import { Box, Typography, darken, useTheme } from '@mui/material'
import React from 'react'

export const ErrorHeader: React.FC<{
  title: string
  subtitle: string
  children: React.ReactElement
}> = ({ title, subtitle, children }) => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        margin: '40px 0',
      }}
    >
      <Box
        sx={{
          width: '280px',
          margin: '4px auto',
          [theme.breakpoints.up('md')]: {
            width: '450px',
          },
          svg: {
            width: '100%',
          },
        }}
      >
        {children}
      </Box>
      <Box
        sx={{
          margin: '12px 0 24px',
        }}
      >
        <Typography
          variant="h2"
          sx={{
            color: style => style.palette.primary.dark,
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="h5"
          sx={{
            color: style => darken(style.palette.primary.light, 0.2),
            fontWeight: 400,
            margin: '6px 0',
          }}
        >
          {subtitle}
        </Typography>
      </Box>
    </Box>
  )
}
