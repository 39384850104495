import { IconButton, alpha } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { Box } from '@mui/system'
import React, { useLayoutEffect, useState } from 'react'

import { SNACKBAR_TIMER_WIDTH } from '../../../constants/Constants'

const CircularProgressClose: React.FC<{
  color: string
  onClose: () => void
  duration: number
}> = ({ color, onClose, duration }) => {
  const width = SNACKBAR_TIMER_WIDTH
  const [prog, setProg] = useState(0) // Controls the progress of the transition

  useLayoutEffect(() => {
    // Every time the snackbar reloads, this sets the progress to start animating
    setProg(100)
  }, [])

  return (
    <Box
      sx={{
        position: 'relative',
        width: width,
        height: width,
      }}
    >
      <CircularProgress
        variant="determinate"
        value={prog}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%) rotate(-90deg)!important',
          '.MuiCircularProgress-circle': {
            transitionDuration: `${duration}ms`,
            transitionTimingFunction: 'linear',
          },
          '.MuiCircularProgress-svg': {
            color: alpha(color, 0.6),
          },
        }}
      />
      <IconButton
        aria-label="close"
        color="inherit"
        sx={{
          position: 'absolute',
          width: width,
          height: width,
          fontSize: '1rem',
          color: color,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
        className="fa fa-close"
        onClick={onClose}
      ></IconButton>
    </Box>
  )
}

export default CircularProgressClose
