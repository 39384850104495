import React from 'react'
import { Typography } from '@mui/material'

import { ComponentModel, getFullComponentType } from '../../../types'
import { getJuvoInfo } from '../../../store'

export const MissingComponent: React.FC<{ comp: ComponentModel }> = ({
  comp,
}) => {
  const componentType = getFullComponentType(comp)

  return (
    <>
      <Typography variant="body1">{comp.text || ''}</Typography>
      <Typography {...getJuvoInfo('DefaultField', comp)} variant="body1">
        {componentType} is not recognized yet
      </Typography>
    </>
  )
}
