import React from 'react'
import { format } from 'date-fns'
import TextField from '@mui/material/TextField'
import { DatePicker } from '@mui/x-date-pickers'
import InputLabel from '@mui/material/InputLabel'
import FormHelperText from '@mui/material/FormHelperText'
import CalendarToday from '@mui/icons-material/CalendarTodayOutlined'
import { useTheme } from '@mui/system'
import clsx from 'clsx'
import { Box } from '@mui/material'

import { Command, DateModel } from '../../../types'
import { setComponentValue } from '../../../store'
import {
  getJuvoProps,
  sanitizeInputName,
  ValidationProps,
} from '../../../utils'
import { useOnSmallScreen } from '../../../hooks/useOnSmallScreen'

const DateField: React.FC<{
  comp: DateModel
  onCommand: (cmd: Command) => void
  onChange: (_: DateModel) => void
  validationProps: ValidationProps
}> = ({ comp, onCommand, onChange, validationProps }) => {
  const theme = useTheme()
  const handleChange = (date: Date) => {
    try {
      onChange(setComponentValue(comp)(format(date, 'yyyy-MM-dd')))
    } catch (error) {
      // Invalid date value
    }
  }
  const label = comp.text || ''
  const { eventHandlers, className, value, juvoInfo } = getJuvoProps<any, any>(
    'DateField',
    comp,
    onCommand,
    handleChange,
  )
  const {
    onChange: onChangeFromServer,
    onBlur: onBlurFromServer,
    onFocus: onFocusFromServer,
    ...handlersFromServer
  } = eventHandlers
  const onSmallScreen = useOnSmallScreen()
  const { handleVisited, hasErrors, errorMessage } = validationProps
  const inputName = sanitizeInputName(label)

  const [isFocused, setFocus] = React.useState(false)

  return (
    <Box
      className={clsx('juvo-date-field', isFocused && 'date-field-focus')}
      sx={{
        '&.date-field-focus .MuiInputBase-formControl': {
          outline: theme =>
            `2px solid ${theme.palette.primary.main} !important`,
        },
        '.MuiInputBase-formControl': {
          outline: theme => `1px solid ${theme.palette.grey[400]}`,
          '&:hover': {
            outline: theme => `2px solid ${theme.palette.grey[500]}`,
          },

          '.MuiInputBase-input': {
            padding: '10px 12px !important',
          },

          '.MuiOutlinedInput-notchedOutline': {
            border: '0 !important',
          },
        },
        marginBottom: '8px',
      }}
    >
      <InputLabel
        error={hasErrors}
        shrink
        htmlFor={inputName}
        className="juvo-input-label"
      >
        {label}
      </InputLabel>
      <DatePicker
        {...juvoInfo}
        value={value ? new Date(`${value}T00:00:00`) : null}
        {...handlersFromServer}
        onChange={(date: any) => {
          onChangeFromServer(date)
          handleVisited()
        }}
        components={{
          OpenPickerIcon: CalendarToday,
        }}
        OpenPickerButtonProps={{
          style: {
            fontSize: '1.25rem',
            color: `${theme.palette.primary.main}`,
          },
        }}
        renderInput={(params: any) => (
          <TextField
            {...params}
            variant="outlined"
            className={clsx(params.className, className)}
            id={inputName}
            name={inputName}
            error={hasErrors}
            sx={{
              minWidth: onSmallScreen ? '100%' : 'auto',
            }}
            onBlur={() => {
              onBlurFromServer && onBlurFromServer()
              setFocus(false)
              handleVisited()
            }}
            onFocus={() => {
              onFocusFromServer && onFocusFromServer()
              setFocus(true)
            }}
          />
        )}
      />
      <FormHelperText
        error={hasErrors}
        sx={{ visibility: hasErrors ? 'visible' : 'hidden' }}
      >
        {errorMessage || ' '}
      </FormHelperText>
    </Box>
  )
}

export default DateField
