import React from 'react'
import { Map } from 'immutable'

import InputTextField from '../InputTextField/InputTextField'
import DefaultField from '../DefaultField/DefaultField'
import Buttons from '../Buttons/Buttons'
import Accordion from '../../juvo-container/Accordion/Accordion'
import Columns from '../../juvo-container/Columns/Columns'
import Folder from '../../juvo-container/Folder/Folder'
import List from '../../juvo-container/List/List'
import StaticText from '../StaticText/StaticText'
import {
  adjustStdComponentArgsBindToChild,
  ComponentModel,
  isAccordionModel,
  isBindModel,
  isButtonsComponent,
  isCarouselModel,
  isCheckboxModel,
  isClaimListModel,
  isColumnModel,
  isCurrencyInputModel,
  isDateModel,
  isFileDropModel,
  isFilterResultsModel,
  isFolderModel,
  isLinkButtonModel,
  isListModel,
  isMultiSelectModel,
  isNumberInputModel,
  isOptionsModel,
  isRatingModel,
  isSelectModel,
  isStaticTextModel,
  isStepperModel,
  isTextAreaModel,
  isTextInputModel,
  isWhenModel,
  StdComponentArgs,
  isCaseModel,
  isTagListModel,
  isTableModel,
  isTabSelectModel,
  isNormalCurveModel,
  isTextComparisonInputModel,
  isDateComparisonInputModel,
  isDashboardModel,
  isChatDisplayModel,
  isInputCommandModel,
  isBooleanComparisonInputModel,
  isModalModel,
  isDownloadsModel,
  isDocPreviewModel,
  isFormModel,
  isDocPreviewUnsafeModel,
} from '../../../types'
import CheckBoxField from '../CheckBoxField/CheckBoxField'
import When from '../../juvo-container/When/When'
import Carousel from '../../juvo-container/Carousel/Carousel'
import Stepper from '../../juvo-container/Stepper/Stepper'
import SelectField from '../SelectField/SelectField'
import OptionsField from '../OptionsField/OptionsField'
import DateField from '../DateField/DateField'
import LinkButton from '../LinkButton/LinkButton'
import CurrencyInput from '../CurrencyInput/CurrencyInput'
import NumberInput from '../NumberInput/NumberInput'
import MultiSelect from '../MultiSelect/MultiSelect'
import UseValidationErrState from '../../juvo-use/UseValidationErrState'
import ClaimList from '../ClaimList/ClaimList'
import MakeInputState from '../../../utils/MakeInputState'
import { isDefined, isUndefined, MakeLabelMeasurements } from '../../../utils'
import MakeValidation from '../../../utils/MakeValidation'
import WithDisabledWheelEvent from '../../../utils/WithDisabledWheelEvent'
import FilterResults from '../FilterResults/FilterResults'
import Rating from '../Rating/Rating'
import TagList from '../TagList/TagList'
import Table from '../../juvo-container/Table/Table'
import MakeAdditionalState from '../../../utils/MakeAdditionalState'
import TabSelect from '../../juvo-container/TabSelect/TabSelect'
import NormalCurve from '../Graph/NormalCurve'
import CompareTextInput from '../CompareInputs/CompareTextInput/CompareTextInput'
import CompareDateInput from '../CompareInputs/CompareDateInput/CompareDateInput'
import Dashboard from '../../Dashboard/Dashboard'
import ChatDisplay from '../Chat/ChatDisplay'
import InputCommand from '../InputCommand/InputCommand'
import { CompareBooleanInput } from '../CompareInputs/CompareBooleanInput/CompareBooleanInput'
import JuvoModal from '../../../containers/JuvoModal/JuvoModal'
import { FileUpload } from '../FileUpload/FileUpload'
import { FileDownload } from '../FileDownload/FileDownload'
import DocPreviewUnsafe from '../DocPreview/DocPreviewUnsafe'
import Form from '../../juvo-container/Form/Form'
import DocPreview from '../DocPreview/DocPreview'

const SwitchYard: React.FC<StdComponentArgs<ComponentModel>> = props => {
  const comp = props.comp
  if (isUndefined(comp)) {
    return <h2>Developer error, null model in switchyard</h2>
  }
  if (isWhenModel(comp)) {
    return (
      <div className="when-combo">
        {/* The top component defines the when condition in model value */}
        <SwitchYardNoWhen {...props} />
        <When {...props} />
      </div>
    )
  } else {
    return <SwitchYardNoWhen {...props} />
  }
}

const SwitchYardNoWhen: React.FC<StdComponentArgs<ComponentModel>> = props => {
  const {
    customReactComps,
    comp,
    onComponentChange,
    onCommand,
    appInfo,
    user,
    propagateValidation,
    handlerValidation,
  } = props
  //Would we ever need to Bind when?
  if (isBindModel(comp)) {
    const childProps = adjustStdComponentArgsBindToChild(props, Map())
    return <SwitchYardNoWhen {...childProps} />
  }

  const CustomComponent = customReactComps(comp)

  if (CustomComponent !== null) {
    // OfficeIntegration component allows custom components defined,
    // specifically for outlook, word, etc. Web piggybacks on this design as well.
    return (
      <CustomComponent
        onComponentChange={onComponentChange}
        onCommand={onCommand}
        appInfo={appInfo}
      />
    )
  }

  if (isButtonsComponent(comp)) {
    return (
      <UseValidationErrState>
        {validationParms => (
          <Buttons
            comp={comp}
            onCommand={onCommand}
            appInfo={appInfo}
            validationState={validationParms}
            handlerValidation={handlerValidation}
          />
        )}
      </UseValidationErrState>
    )
  }

  if (isStaticTextModel(comp)) {
    return <StaticText comp={comp} />
  }

  if (isDashboardModel(comp)) {
    return <Dashboard comp={comp} />
  }

  if (isModalModel(comp)) {
    return (
      <JuvoModal
        component={comp}
        subType={comp.sub_type}
        customReactComps={customReactComps}
        appInfo={appInfo}
        user={user}
      />
    )
  }

  if (isCheckboxModel(comp)) {
    return (
      <MakeValidation
        component={comp}
        propagateValidation={propagateValidation}
      >
        {props => (
          <CheckBoxField
            comp={comp}
            onCommand={onCommand}
            onChange={onComponentChange}
            validationProps={props}
          />
        )}
      </MakeValidation>
    )
  }

  if (isSelectModel(comp)) {
    return (
      <MakeValidation component={comp}>
        {validationProps => (
          <SelectField
            comp={comp}
            onCommand={onCommand}
            onChange={onComponentChange}
            validationProps={validationProps}
          />
        )}
      </MakeValidation>
    )
  }

  if (isMultiSelectModel(comp)) {
    return (
      <MakeValidation component={comp}>
        {validationProps => (
          <MultiSelect
            comp={comp}
            onCommand={onCommand}
            onChange={onComponentChange}
            validationProps={validationProps}
          />
        )}
      </MakeValidation>
    )
  }

  if (isOptionsModel(comp)) {
    return (
      <MakeValidation component={comp}>
        {props => (
          <OptionsField
            comp={comp}
            onCommand={onCommand}
            onChange={onComponentChange}
            validationProps={props}
          />
        )}
      </MakeValidation>
    )
  }

  if (isTabSelectModel(comp)) {
    return (
      <TabSelect
        comp={comp}
        onCommand={onCommand}
        onChange={onComponentChange}
      />
    )
  }

  if (isRatingModel(comp)) {
    return (
      <MakeValidation component={comp}>
        {props => (
          <Rating
            comp={comp}
            onCommand={onCommand}
            onChange={onComponentChange}
            validationProps={props}
          />
        )}
      </MakeValidation>
    )
  }

  if (isDownloadsModel(comp)) {
    return <FileDownload component={comp} />
  }

  if (isCaseModel(comp)) {
    return (
      // Note, use When container divs to get DOM debugging info, e.g $(".when-no-selection") or $(".when-selection")
      //   Case does not have any rendering, it allows programmatic control from the backend of what is being displayed
      //   similar to case or switch in a programming language
      <></>
    )
  }

  if (isBooleanComparisonInputModel(comp)) {
    return (
      // TODO this could use the MakeValidation in the future but for now BE does not support validations
      <CompareBooleanInput
        comp={comp}
        onCommand={onCommand}
        onChange={onComponentChange}
      />
    )
  }

  if (isTextComparisonInputModel(comp)) {
    return (
      <MakeInputState
        component={comp}
        onCommand={onCommand}
        onChange={onComponentChange}
      >
        {inputStateProps => (
          <CompareTextInput comp={comp} inputStateProps={inputStateProps} />
        )}
      </MakeInputState>
    )
  }

  if (isDateComparisonInputModel(comp)) {
    // TODO this could use the MakeValidation in the future but for now BE does not support validations
    return (
      <CompareDateInput
        comp={comp}
        onCommand={onCommand}
        onChange={onComponentChange}
      />
    )
  }

  if (isTextInputModel(comp) || isTextAreaModel(comp)) {
    return (
      <MakeInputState
        component={comp}
        onCommand={onCommand}
        onChange={onComponentChange}
        propagateValidation={propagateValidation}
      >
        {inputStateProps => (
          <InputTextField
            comp={comp}
            multiline={isTextAreaModel(comp)}
            inputStateProps={inputStateProps}
          />
        )}
      </MakeInputState>
    )
  }

  if (isNumberInputModel(comp)) {
    const sanitizeValue = (value: string | null | number) => {
      if (typeof value !== 'number') {
        return !isDefined(value) || value === ''
          ? null
          : Number(value.replace(',', '.'))
      } else {
        return value
      }
    }

    return (
      <MakeInputState
        component={comp}
        onCommand={onCommand}
        onChange={onComponentChange}
        transformValueToServer={sanitizeValue}
      >
        {inputStateProps => (
          <MakeLabelMeasurements
            label={comp.text || ''}
            defaultLabel="Please enter a value"
          >
            {({ ref, ...measurementProps }) => (
              <WithDisabledWheelEvent
                elementHandle={measurementProps.elementHandle}
              >
                {disabledWheelProps => (
                  <NumberInput
                    ref={ref}
                    comp={comp}
                    inputStateProps={inputStateProps}
                    disabledWheelProps={disabledWheelProps}
                  />
                )}
              </WithDisabledWheelEvent>
            )}
          </MakeLabelMeasurements>
        )}
      </MakeInputState>
    )
  }

  if (isClaimListModel(comp)) {
    return <ClaimList {...props} />
  }

  if (isCurrencyInputModel(comp)) {
    const transformValue = (value: string) => {
      // TODO: the BE is sending a string with the value and the currency symbol, which has to be removed
      return value.replace(/[^\d.]/g, '')
    }

    return (
      <MakeInputState
        component={comp}
        onCommand={onCommand}
        onChange={onComponentChange}
        transformValueFromServer={transformValue}
      >
        {inputStateProps => (
          <MakeLabelMeasurements
            label={comp.text || ''}
            defaultLabel="Please enter a value"
          >
            {({ ref, ...measurementProps }) => (
              <WithDisabledWheelEvent
                elementHandle={measurementProps.elementHandle}
              >
                {disabledWheelProps => (
                  <CurrencyInput
                    ref={ref}
                    comp={comp}
                    inputStateProps={inputStateProps}
                    disabledWheelProps={disabledWheelProps}
                  />
                )}
              </WithDisabledWheelEvent>
            )}
          </MakeLabelMeasurements>
        )}
      </MakeInputState>
    )
  }

  if (isStepperModel(comp)) {
    return (
      <UseValidationErrState>
        {validationParms => (
          <Stepper stdCompArgs={props} validationState={validationParms} />
        )}
      </UseValidationErrState>
    )
  }

  if (isCarouselModel(comp)) {
    return <Carousel stdCompArgs={props} />
  }

  if (isAccordionModel(comp)) {
    return <Accordion {...props} />
  }

  if (isColumnModel(comp)) {
    return <Columns {...props} />
  }

  if (isFormModel(comp)) {
    return <Form {...props} />
  }

  if (isFolderModel(comp)) {
    return <Folder {...props} />
  }

  if (isNormalCurveModel(comp)) {
    return <NormalCurve {...props} />
  }

  if (isListModel(comp)) {
    return <List {...props} />
  }

  if (isDateModel(comp)) {
    return (
      <MakeValidation component={comp}>
        {validationProps => (
          <DateField
            comp={comp}
            onCommand={onCommand}
            onChange={onComponentChange}
            validationProps={validationProps}
          />
        )}
      </MakeValidation>
    )
  }

  if (isFileDropModel(comp)) {
    return (
      <FileUpload
        comp={comp}
        onCommand={onCommand}
        onChange={onComponentChange}
      />
    )
  }

  if (isFilterResultsModel(comp)) {
    return <FilterResults comp={comp} />
  }

  if (isLinkButtonModel(comp)) {
    return <LinkButton comp={comp} />
  }

  if (isTagListModel(comp)) {
    return (
      <MakeAdditionalState initialValue="">
        {newState => (
          <TagList
            comp={comp}
            onChange={onComponentChange}
            stateHandler={newState}
          />
        )}
      </MakeAdditionalState>
    )
  }

  if (isTableModel(comp)) {
    return <Table comp={comp} />
  }

  if (isChatDisplayModel(comp)) {
    return <ChatDisplay comp={comp} user={user} />
  }

  if (isInputCommandModel(comp)) {
    return (
      <MakeInputState
        component={comp}
        onCommand={onCommand}
        onChange={onComponentChange}
      >
        {inputStateProps => (
          <InputCommand
            comp={comp}
            onCommand={onCommand}
            onChange={onComponentChange}
            appInfo={appInfo}
            inputStateProps={inputStateProps}
          />
        )}
      </MakeInputState>
    )
  }

  if (isDocPreviewUnsafeModel(comp)) {
    return <DocPreviewUnsafe comp={comp} />
  }

  if (isDocPreviewModel(comp)) {
    return <DocPreview comp={comp} />
  }

  return (
    <MakeValidation component={comp}>
      {props => (
        <DefaultField
          comp={comp}
          onChange={onComponentChange}
          validationProps={props}
        />
      )}
    </MakeValidation>
  )
}

export default SwitchYard
