import React from 'react'

const AppLoadingSplash: React.FC = () => (
  <svg
    width="480.21"
    height="358.79"
    fill="none"
    version="1.1"
    viewBox="0 0 480.21 358.79"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <clipPath id="clipPath2558-3">
        <path
          d="m-772.19 734.8 49.269 0.85251-1.0673 26.499-46.49-1.178"
          fill="#e6e7ef"
          strokeWidth="1.4877"
        />
      </clipPath>
      <linearGradient
        id="linearGradient2030"
        x1="430.73"
        x2="477"
        y1="268.23"
        y2="230.5"
        gradientTransform="translate(-6.9431,306.62)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#f15c5e" offset="0" />
        <stop stopColor="#fff" offset="1" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="linearGradient2032"
        x1="157.24"
        x2="15.516"
        y1="212.92"
        y2="247.3"
        gradientTransform="translate(720.91,-5.4727)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#f15c5e" offset="0" />
        <stop stopColor="#fff" offset="1" stopOpacity="0" />
      </linearGradient>
    </defs>
    <g transform="rotate(-15 1712 2535)" fill="#e6e7ef">
      <path
        d="m957.12-275.92c-13.237-0.074-25.662 2.7559-35.959 9.2422l-5.0918 3.209h-146.59v292.95h292.95v-292.95h-56.082c-5.0253-2.6392-10.15-4.9093-15.32-6.6309-3.1565-1.051-6.3228-1.9554-9.4863-2.7129v0c-8.2422-1.9736-16.476-3.063-24.418-3.1074z"
        color="#000000"
        strokeLinecap="square"
      />
    </g>
    <rect
      transform="rotate(-15)"
      x="71.043"
      y="109.25"
      width="260.95"
      height="260.95"
      fill="#6b337c"
    />
    <g transform="translate(8.291 11.968)" fill="#e6e7ef">
      <g transform="translate(1005.7,-451.46)" clipPath="url(#clipPath2558-3)">
        <rect
          transform="matrix(-.97019 .24233 .24233 .97019 0 0)"
          x="754.14"
          y="294.2"
          width="256.29"
          height="255.37"
          rx="21.644"
          ry="21.644"
          fill="#e6e7ef"
          strokeWidth="102.26"
        />
      </g>
      <path
        d="m235.5 224.79-31.766 36.249-19.472-17.86 30.106-34.197"
        strokeWidth="1.4877"
      />
      <path
        d="m288.84 226.14 29.889 37.811 20.352-16.851-28.335-35.678"
        strokeWidth="1.4877"
      />
    </g>
    <g transform="translate(8.291 11.968)" strokeWidth="1.4877">
      <path
        d="m217.49 211.09 91.183 2.9042-7.189 64.944-86.212-3.7678"
        fill="#ced0e1"
      />
      <path
        d="m277.28 108.87c6.1184 0.15503 11.204-4.6791 11.359-10.797 0.15502-6.1184-4.6791-11.204-10.797-11.359-6.1184-0.15502-11.204 4.6791-11.359 10.797-0.15502 6.1184 4.6791 11.204 10.797 11.359z"
        fill="#e6e7ef"
      />
      <path
        d="m275.33 96.727-6.6652 50.619 5.6896 0.74836 6.6652-50.619z"
        fill="#e6e7ef"
      />
      <path
        d="m205.4 133.18 120.55 8.004-11.536 64.636-108.27-8.0874"
        fill="#ced0e1"
      />
      <path
        d="m279.17 199.76c6.7207 0.17028 12.308-5.1408 12.478-11.861 0.17032-6.7222-5.1407-12.309-11.861-12.48-6.7222-0.17032-12.309 5.1407-12.48 11.863-0.17028 6.7207 5.1408 12.308 11.863 12.478z"
        fill="#fff"
      />
      <path
        d="m238.13 195.14c5.7358 3.5086 13.23 1.7028 16.737-4.033 3.5086-5.7358 1.7028-13.23-4.0329-16.738-1.8357 5.1412-12.412 9.2588-17.037 9.1416-0.58646 3.8811-0.22406 8.8429 4.3335 11.63z"
        fill="#fff"
      />
    </g>
    <g transform="matrix(1.1246 0 0 1.1246 -26.663 -395.83)">
      <path
        d="m448.24 491.09c-13.891 91.99-98.657 118.02-169.22 77.78"
        stroke="url(#linearGradient2030)"
        strokeWidth="7"
      />
      <path
        d="m280.76 579.76c-0.422 0.946-1.496 1.414-2.476 1.078l-17.339-5.948c-1.111-0.382-1.656-1.634-1.178-2.707l8.363-18.76c0.473-1.061 1.748-1.498 2.772-0.949l16.135 8.65c0.924 0.495 1.308 1.62 0.882 2.577z"
        fill="#f15c5e"
      />
      <rect
        transform="rotate(204.03)"
        x="-472.19"
        y="-420.89"
        width="7.8561"
        height="28.806"
        rx="2"
        fill="#600809"
      />
    </g>
    <g transform="matrix(1.0362 .51806 -.51806 1.0362 -636.58 -442.19)">
      <path
        d="m759.42 287.03c36.497 4.996 60.997-7.004 67.541-45.385 7.056-41.381 70.456-60.619 100.96-10.864"
        stroke="url(#linearGradient2032)"
        strokeWidth="7"
      />
      <rect
        transform="rotate(-47.786)"
        x="456.88"
        y="849.69"
        width="3.928"
        height="19.64"
        rx="1.964"
        fill="#e1e1e1"
      />
      <rect
        transform="rotate(-47.786)"
        x="448.37"
        y="849.58"
        width="3.928"
        height="19.64"
        rx="1.964"
        fill="#e1e1e1"
      />
      <path
        d="m926.61 215.63c0.693-0.77 1.859-0.883 2.687-0.261l14.658 11.008c0.939 0.706 1.071 2.065 0.285 2.938l-13.744 15.264c-0.778 0.864-2.125 0.885-2.929 0.047l-12.679-13.207c-0.726-0.756-0.745-1.944-0.043-2.723z"
        fill="#f15c5e"
      />
      <rect
        transform="rotate(42)"
        x="853.18"
        y="-465.45"
        width="7.8561"
        height="28.806"
        rx="2"
        fill="#600809"
      />
    </g>
    <g fill="none" stroke="#ced0e1" strokeWidth="9">
      <path
        d="m265.22 89.872c8.291-6.7728 29.112-14.656 46.075 7.9862"
        strokeLinecap="round"
        strokeWidth="10"
      />
      <path
        d="m255.54 68.199c11.018-11.902 52.095-18.17 72.252 13.034"
        strokeLinecap="round"
        strokeWidth="10"
      />
      <path
        d="m238.03 47.39c16.96-18.321 80.193-27.971 111.22 20.064"
        strokeLinecap="round"
        strokeWidth="10"
      />
    </g>
  </svg>
)

export default AppLoadingSplash
