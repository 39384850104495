import React from 'react'
import Typography from '@mui/material/Typography'
import { Box, SxProps, Theme, Tooltip } from '@mui/material'
import MuiButton from '@mui/material/Button'
import { Chip } from '@mui/material'
import Icon from '@mui/material/Icon'

import { FilterResultModel } from '../../../types'
import { sanitizeUrl } from '../../../utils'

// The width of the date icon area. (DO we need measurements?)
// const LABEL_OFFSET_PX = 36

const FilterResult: React.FC<{
  comp: FilterResultModel
}> = ({ comp }) => {
  const name = comp.name || ''
  const clause = comp.clause || ''
  const people = comp.people || []
  const jurisdiction = comp.jurisdiction || ''
  const url = sanitizeUrl(comp.imanage_id) || ''
  const highlight = comp.highlight || []
  const date = comp.date
  const documentId = comp.document_id

  // TODO Daniel please review stayling decisions

  //TODO do we need measurements here?
  // const { ref, inputWidth, inputFitsInsideContainer } = useLabelMeasurements({
  //   label: name,
  //   labelOffset: LABEL_OFFSET_PX,
  // })
  // const onSmallScreen = useOnSmallScreen()
  const styles: SxProps<Theme> = [
    {
      display: 'inline-block',
      marginTop: '1rem',
      margin: theme => theme.spacing(2),
      float: 'right',
      marginBottom: '1rem !important',
    },
    {
      backgroundColor: theme => theme.palette.primary.light,
      '&:hover': {
        backgroundColor: theme => theme.palette.primary.main,
      },
    },
  ]

  const highlightText = (clause: string, highlight: string[]) => {
    const highlightLower = highlight.map(text => text.toLowerCase())
    const splittingExp = new RegExp(`(${highlightLower.join('|')})`, 'gi')
    const parts = clause.split(splittingExp)
    return (
      <>
        {parts.map((part, index) => (
          <span
            key={index}
            className={
              highlightLower.includes(part.toLowerCase())
                ? 'juvo-recommendation'
                : undefined
            }
          >
            {part}
          </span>
        ))}
      </>
    )
  }

  const formatDate = (dateString: string): string => {
    const split = dateString.split('-')
    const date = new Date(
      parseInt(split[0]),
      parseInt(split[1]) - 1,
      parseInt(split[2]),
    )
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box
        sx={{
          alignItems: 'flex-start',
          alignContent: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          backgroundColor: theme => theme.palette.grey.A200,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            variant="h6"
            sx={{
              padding: { xs: '8px', sm: '8px', md: '16px' },
              color: theme => theme.palette.grey[700],
            }}
          >
            {name}
          </Typography>
          <Box
            sx={{
              padding: '0 12px',
              display: 'flex',
              gap: '8px',
              '.MuiChip-root': {
                height: '24px',
                fontSize: '0.7em',
                '.MuiChip-icon': {
                  color: theme => theme.palette.primary.main,
                  fontSize: '1.2em',
                },
              },
            }}
          >
            {date && (
              <Chip
                label={formatDate(date)}
                variant="outlined"
                icon={<Icon className="fa fa-calendar" />}
              />
            )}
            {documentId && (
              <Chip
                label={documentId}
                variant="outlined"
                icon={<Icon className="fa fa-info-circle" />}
              />
            )}
          </Box>
        </Box>
        <Box sx={{ m: { xs: '0px', sm: '0px', md: '8px' } }}>
          {jurisdiction && (
            <Tooltip arrow enterDelay={1000} title={jurisdiction}>
              <Chip
                label={jurisdiction}
                icon={<Icon className="fa fa-gavel" fontSize="medium" />}
                sx={{
                  margin: theme => `5px ${theme.spacing(1)}`,
                  maxWidth: {
                    xs: '230px',
                    sm: '450px',
                    md: '700px',
                    lg: '820px',
                  },
                }}
              />
            </Tooltip>
          )}
          {people.map((x, index) => {
            return (
              <Chip
                key={index}
                label={x}
                icon={<Icon className="fa fa-user" fontSize="medium" />}
                sx={{ margin: theme => `5px ${theme.spacing(1)}` }}
              />
            )
          })}
        </Box>
      </Box>
      <Typography
        variant="body2"
        sx={{
          marginBottom: theme => theme.spacing(1),
          marginTop: theme => theme.spacing(2),
          marginLeft: theme => theme.spacing(2),
          marginRight: theme => theme.spacing(2),
        }}
      >
        {highlightText(clause, highlight)}
      </Typography>
      {documentId && (
        <Box>
          <MuiButton
            sx={styles}
            href={url}
            variant="contained"
            target="_blank"
            rel="noopener noreferrer"
          >
            IManage Link
          </MuiButton>
        </Box>
      )}
    </Box>
  )
}

export default FilterResult
