import { useState } from "react"

export const useCopy = () => {
  const [isCopied, setIsCopied] = useState(false)

  const copyHandler = () => {
    setIsCopied(true)
    setTimeout(() => {
      setIsCopied(false)
    }, 2500)
  }

  return [isCopied, copyHandler] as const
}
