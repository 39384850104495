import React from 'react'
import Typography from '@mui/material/Typography'
import { Theme } from '@mui/material'

import { StaticTextModel, ThemeStyle } from '../../../types'
import { getJuvoInfo } from '../../../store'
import { Nullable } from '../../../utils'

// for darker backgrounds, the default fg color is too dark and illegible. We'll
// use the pallete contrast text to avoid this.
// ref: https://mui.com/system/palette/#color
const getColor =
  (theme: Theme) =>
  (themeStyle: Nullable<ThemeStyle>): string =>
    themeStyle
      ? theme.palette[themeStyle].contrastText
      : theme.palette.text.primary

const getBgColor =
  (theme: Theme) =>
  (themeStyle: Nullable<ThemeStyle>): string => {
    switch (themeStyle) {
      case 'primary':
        return theme.palette.primary.main
      case 'secondary':
        return theme.palette.secondary.main
      case 'info':
        return theme.palette.info.main
      case 'error':
        return theme.palette.error.main
      default:
        return 'transparent'
    }
  }

const getPadding = (themeStyle: Nullable<ThemeStyle>): string => {
  return themeStyle ? '1rem' : '0'
}

const getBorder = (themeStyle: Nullable<ThemeStyle>): string => {
  return themeStyle ? '1px solid' : 'none'
}

const StaticText: React.FC<{ comp: StaticTextModel }> = ({ comp }) => {
  const themeStyle = comp['theme-style']

  return (
    <Typography
      {...getJuvoInfo('StaticText', comp)}
      variant={comp.variant ?? 'body1'}
      sx={{
        display: 'block',
        bgcolor: theme => getBgColor(theme)(themeStyle),
        color: theme => getColor(theme)(themeStyle),
        padding: getPadding(themeStyle),
        border: getBorder(themeStyle),
        margin: '1rem 0 !important',
        whiteSpace: 'pre-line'
      }}
    >
      {comp.recommendation ?? comp.title ?? comp.text}
    </Typography>
  )
}

const MemoizedStaticText = React.memo(StaticText)

export default MemoizedStaticText
