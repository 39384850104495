import {
  Alert,
  AlertColor,
  AlertTitle,
  SxProps,
  Theme,
  Typography,
} from '@mui/material'
import React from 'react'

import { getNotificationIconFromSeverity } from '../../utils/Notifications'

const styles: SxProps<Theme> = {
  position: 'sticky',
  top: 0,
  overflow: 'auto',
  paddingTop: '0px',
  zIndex: theme => theme.zIndex.snackbar,
  borderRadius: 0,
  '&:not(:last-child)': {
    marginBottom: theme => theme.spacing(0.5),
  },
  padding: { xs: '16px', sm: '24px' },
  '& .MuiAlert-action': {
    position: 'absolute',
    padding: '0px',
    margin: '0px',
    top: { xs: '8px', sm: '16px' },
    right: '16px',
  },
  '.MuiAlert-message': {
    padding: '0px',
    width: '100%',
    minWidth: 'unset',
  },
}

export const JuvoAlertContainer: React.FC<{
  severity: AlertColor
  onClose: () => void
  children: React.ReactElement
  title: string
}> = ({ severity, onClose, children, title }) => {
  return (
    <Alert
      severity={severity}
      onClose={onClose}
      sx={styles}
      icon={false}
      data-testid="notification"
    >
      <AlertTitle
        sx={{
          marginTop: 0,
        }}
      >
        <Typography
          align="left"
          variant="body1"
          data-testid="notificationMessage"
          sx={{
            fontWeight: 600,
            fontSize: '0.9em',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {getNotificationIconFromSeverity(severity, {
            marginRight: '8px',
            fontSize: '16px',
          })}
          {title}
        </Typography>
      </AlertTitle>
      {children}
    </Alert>
  )
}
