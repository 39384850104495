import { Box, Tooltip } from '@mui/material'
import React from 'react'

export const InfoTooltip: React.FC<{
  tooltip: string
}> = ({ tooltip }) => {
  return (
    <Tooltip title={tooltip} arrow>
      <Box
        className="fa-sharp fa-solid fa-circle-info"
        sx={{
          color: styles => styles.palette.primary.light,
          verticalAlign: 'middle',
          margin: '0 10px',
        }}
      />
    </Tooltip>
  )
}
