import React from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import Chip from '@mui/material/Chip'
import InputLabel from '@mui/material/InputLabel'
import FormHelperText from '@mui/material/FormHelperText'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'

import {
  getJuvoProps,
  sanitizeInputName,
  ValidationProps,
} from '../../../utils'
import { Command, MultiSelectModel } from '../../../types'

const MultiSelect: React.FC<{
  comp: MultiSelectModel
  onCommand: (cmd: Command) => void
  onChange: (_: MultiSelectModel) => void
  validationProps: ValidationProps
}> = ({ comp, onCommand, onChange, validationProps }) => {
  const handleChange = (values: string[]) => {
    // REVIEW: we cannot pass the processed onChange handler to the component directly
    onChange({ ...comp, selections: values })
  }
  const { eventHandlers, className, juvoInfo } = getJuvoProps(
    'MultiSelect',
    comp,
    onCommand,
    handleChange,
  )
  const { onBlur: onBlurFromServer, ...handlersFromServer } = eventHandlers
  // TODO: we have to figure out a way to incorporate recommendations
  const value = comp.selections || []
  const label = comp.text || ''
  const { handleVisited, hasErrors, errorMessage } = validationProps
  const inputName = sanitizeInputName(label)

  return (
    <Box sx={{ marginBottom: '8px' }}>
      <InputLabel
        error={hasErrors}
        shrink
        htmlFor={inputName}
        className="juvo-input-label"
      >
        {label}
      </InputLabel>
      <Autocomplete
        {...juvoInfo}
        multiple
        sx={{
          '.MuiInputBase-root:after, .MuiInputBase-root:before': {
            border: 'none !important',
          },
          '.MuiInputBase-root': {
            border: '1px solid var(--color-border)',
            padding: '10px 56px 10px 12px',
            '&:hover': {
              outline: '2px solid var(--color-hover)',
              borderColor: 'transparent',
            },
            '.MuiInput-input': {
              padding: '0',
            },
            '&.Mui-focused': {
              outline: style => `2px solid ${style.palette.primary.main}`,
              borderColor: 'transparent',
            },
          },
          '.MuiChip-root': {
            marginBottom: '0 !important', // !important used here to override !important from mui
          },
        }}
        options={comp.options.concat(
          comp?.bound?.options?.value ||
            comp?.bound?.options?.recommendation ||
            [],
        )}
        renderInput={params => (
          <TextField
            {...params}
            fullWidth
            variant="standard"
            id={inputName}
            placeholder={
              value.length === 0 ? 'None selected. Click to expand.' : ''
            }
            name={inputName}
            onBlur={() => {
              handleVisited()
              onBlurFromServer && onBlurFromServer()
            }}
            error={hasErrors}
          />
        )}
        renderTags={(value: string[], getTagProps) =>
          value.map((option: string, index: number) => (
            // eslint-disable-next-line react/jsx-key
            <Chip
              sx={{
                marginBottom: '6px !important',
                marginRight: '4px !important',
                background: 'transparent !important',
                border: style => `1px solid ${style.palette.primary.main}`,
              }}
              label={option}
              data-testid={`multi_select_option_${option}`}
              {...getTagProps({ index })}
            />
          ))
        }
        {...handlersFromServer}
        value={value}
        onChange={(_: any, values: string[]) => handleChange(values)}
        className={className}
      />
      <FormHelperText
        error={hasErrors}
        sx={{ visibility: hasErrors ? 'visible' : 'hidden' }}
      >
        {errorMessage || ' '}
      </FormHelperText>
    </Box>
  )
}

export default MultiSelect
