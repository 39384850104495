import React from 'react'
import { AlertColor, Icon, SxProps, Theme } from '@mui/material'
import assertNever from 'assert-never'

import { BatchResult, ReplyState, ResultSubType } from '../types'

export const getNotificationIconClassFromSeverity = (
  severity: AlertColor,
): string => {
  switch (severity) {
    case 'error':
      return 'fa-circle-exclamation'
    case 'info':
      return 'fa-circle-info'
    case 'success':
      return 'fa-circle-check'
    case 'warning':
      return 'fa-triangle-exclamation'
  }
}

export const getNotificationIconFromSeverity = (
  severity: AlertColor,
  extraSx?: SxProps,
): JSX.Element => {
  const iconClass = getNotificationIconClassFromSeverity(severity)

  return (
    <Icon
      className={`fa ${iconClass}`}
      sx={{
        color: style => getNotificationColorFromSeverity(severity, style),
        ...extraSx,
      }}
    />
  )
}

export const getNotificationColorFromSeverity = (
  severity: AlertColor,
  theme: Theme,
): string => {
  if (severity === 'info') {
    // Special rule for info because the default color looks icky
    return theme.palette.primary.dark
  } else {
    return theme.palette[severity].main
  }
}

export const getAlertSeverity = (replyState: ReplyState): AlertColor => {
  switch (replyState) {
    case ReplyState.Success:
    case ReplyState.PartialSuccess:
      return 'success'
    case ReplyState.Failure:
    case ReplyState.Error:
      return 'error'
    case ReplyState.Warning:
      return 'warning'
    case ReplyState.None:
      return 'info'
    default:
      assertNever(replyState)
  }
}

export const getAlertSeverityBySubType = (
  subType: ResultSubType,
): AlertColor => {
  switch (subType) {
    case 'failure':
      return 'error'
    case 'success':
      return 'success'
    case 'info':
      return 'info'
  }
}

export const getBatchResultSeverity = (c: BatchResult): AlertColor => {
  switch (c.type) {
    case 'Error':
      return 'error'
    case 'Warning':
      return 'warning'
    case 'Result':
      return 'success'
  }
}
