import React from 'react'

const NotFoundSplash: React.FC = () => (
  <svg
    width="333.13"
    height="366.93"
    fill="none"
    version="1.1"
    viewBox="0 0 333.13 366.93"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="translate(-704.79 92.678)">
      <path
        d="m780.84-49.262 247.28 66.258-66.258 247.28-247.28-66.258z"
        color="#000000"
        fill="#6b337c"
        strokeLinecap="square"
        strokeWidth="16"
      />
      <path
        d="m760.77-92.678-10.891 11.725-35.391 38.1 24.91 23.139c-0.5997-0.55724 3.4613 3.3718 8.3594 8.2148 3.0167 2.9828 7.5052 7.4551 11.639 11.566l-54.604 203.79 262.73 70.398 70.398-262.73-102.72-27.523c1.2775-1.2489 4.0457-3.9619 3.7735-3.709v2e-3l24.914-23.141-46.283-49.824-24.91 23.143c-4.5472 4.224-14.153 13.858-29.006 28.678-1.2767 1.2739-2.9685 2.9693-4.4863 4.4883l-54.725-14.664c-8.6315-8.6091-15.517-15.452-18.801-18.502zm1.6641 45.227 1.4648 1.3613c1.0423 0.96814 3.3841 3.2612 6.2383 6.0762l-1.1953 4.4609c-3.8726-3.834-6.1941-6.1458-7.7676-7.6074l-1.4648-1.3613zm153.51 0 2.7226 2.9297-1.4668 1.3613c-1.7803 1.6538-4.1429 4.024-9.0801 8.9062-2.2635 2.2384-5.6684 5.6307-8.7109 8.6562l-4.4785-1.2012c9.6643-9.627 17.26-17.165 19.549-19.291zm-129.43 8.166 231.82 62.117-62.117 231.82-231.82-62.117z"
        color="#000000"
        fill="#f4f3f8"
        strokeLinecap="square"
      />
      <path
        d="m761.6-70.244-24.502 26.377 13.188 12.25c1.1773 1.0936 12.851 12.605 27.67 27.391 10.161 10.138 23.245 23.249 35.797 35.818-31.81 31.903-63.984 64.217-63.984 64.217l-12.697 12.758 25.518 25.395 12.697-12.76s32.142-32.281 63.9-64.133c31.759 31.852 63.902 64.133 63.902 64.133l12.695 12.76 25.52-25.395-12.697-12.758s-32.175-32.314-63.984-64.217c12.551-12.569 25.636-25.68 35.797-35.818 14.819-14.786 26.491-26.297 27.668-27.391l13.189-12.25-24.502-26.377-13.188 12.252c-3.7741 3.5058-13.74 13.459-28.596 28.281-10.176 10.154-23.257 23.258-35.805 35.824-12.548-12.566-25.627-25.671-35.803-35.824-14.855-14.822-24.822-24.775-28.596-28.281z"
        color="#000000"
        fill="#ccc0d8"
      />
      <path
        d="m866.57 177.13-36.926 33.405-17.849-20.653 34.978-31.499"
        fill="#cf4256"
        strokeWidth="1.4437"
      />
      <path
        d="m912.49 177.13 36.926 33.405 17.849-20.653-34.978-31.499"
        fill="#cf4256"
        strokeWidth="1.4437"
      />
      <path
        d="m847.9 151.91 91.979 3.2096-10.551 72.998-82.11-6.4524"
        fill="#f15c5e"
        strokeWidth="1.5668"
      />
      <g fill="#cf4256">
        <path
          d="m929.31 228.1 0.59747-4.1337-82.711-6.9524v4.6333z"
          strokeLinecap="square"
          strokeWidth="1.8898"
        />
        <rect
          transform="matrix(.99625 .086515 -.18764 .98224 0 0)"
          x="896.02"
          y="-12.872"
          width="9.8808"
          height="23.429"
          strokeWidth="3.1115"
        />
        <rect
          transform="rotate(67.339)"
          x="404.71"
          y="-835.39"
          width="9.925"
          height="34.414"
          strokeWidth="3.7795"
        />
      </g>
      <g transform="matrix(3.7136 .70288 -.70288 3.7136 -2033.7 -805.58)">
        <path
          d="m810.06 83.782c2.3569 0.26963 4.4861-1.4224 4.7558-3.7794 0.26963-2.3569-1.4225-4.4861-3.7794-4.7559-2.3569-0.26964-4.4862 1.4225-4.7559 3.7794-0.26963 2.3569 1.4225 4.4861 3.7794 4.7559z"
          fill="#f15c5e"
          strokeWidth=".49304"
        />
        <path
          d="m810.54 76.651c-1.4219-0.16266-2.7065 0.85815-2.8691 2.28"
          fill="none"
          stroke="#f6b4a4"
          strokeLinecap="round"
          strokeWidth=".95254"
        />
      </g>
      <g transform="matrix(3.756 -.42118 .42118 3.756 -2176.9 92.541)">
        <path
          d="m790.73 84.48 34.884 4.8135-4.6873 18.555-31.313-4.5848"
          fill="#f15c5e"
          strokeWidth=".40731"
        />
        <path
          d="m789.61 103.26 0.12233-1.9509 31.505 5.3001-0.31644 1.2501z"
          fill="#cf4256"
          strokeLinecap="square"
          strokeWidth=".5"
        />
        <path
          d="m811.88 94.042c-1.2918 0.38567-2.293 1.5108-2.4528 2.9351-0.21321 1.9014 1.1557 3.6156 3.0574 3.8288 1.8726 0.20999 3.5624-1.1148 3.8169-2.9712-0.43289 0.12919-0.89811 0.17598-1.3753 0.12248-1.9018-0.21326-3.2706-1.9282-3.0574-3.8296 3e-3 -0.02869 7e-3 -0.05708 0.0112-0.0855z"
          fill="#fff"
          strokeWidth=".3977"
        />
        <path
          d="m802.39 92.277c1.1743 0.66221 1.9015 1.9812 1.7418 3.4054-0.21321 1.9014-1.9278 3.2698-3.8296 3.0566-1.8726-0.20998-3.227-1.8761-3.064-3.7428 0.39352 0.22186 0.83682 0.37053 1.314 0.42404 1.9018 0.21325 3.6164-1.156 3.8296-3.0574 3e-3 -0.02869 6e-3 -0.05726 8e-3 -0.08586z"
          fill="#fff"
          strokeWidth=".3977"
        />
      </g>
      <path
        d="m914.7 228.11-42.312 0.42187 0.2598 3.5508 41.869 11.219z"
        fill="#cf4256"
      />
    </g>
  </svg>
)

export default NotFoundSplash
