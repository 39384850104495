import React, { DragEventHandler, useState } from 'react'
import { Box, Icon, IconButton, Typography } from '@mui/material'
import { alpha } from '@mui/material'

import { FileDropModel, Command, FileModel, Base64 } from '../../../types'
import { mergedComponentEvents } from '../../../store'
import { isDefined } from '../../../utils/Undefined'
import { getDownloadIconFromFileName } from '../../../utils/Common'
import { logWarn } from '../../../utils/Logger'
import { useSnackbar } from '../../../providers/SnackbarProvider'

//this for loop scales to bigger files
const arrayBufferToBase64 = (buffer: Uint8Array) => {
  let binary = ''
  const len = buffer.byteLength
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(buffer[i])
  }
  return btoa(binary)
}

const setFileDropValue = (
  component: FileDropModel,
  files: FileModel[],
): FileDropModel => {
  return {
    ...component,
    files_raw: files.length === 0 ? null : files,
  }
}

const concatFiles = (
  component: FileDropModel,
  files: FileModel[],
): FileModel[] | null => {
  if (isDefined(component.files_raw) && component.files_raw.length > 0) {
    return component.files_raw.concat(files)
  }
  return files.length === 0 ? null : files
}

async function getFileContent(file: File): Promise<Base64> {
  const reader = new FileReader()

  return new Promise((resolve, reject) => {
    reader.onload = () => {
      const result = reader.result as ArrayBuffer
      const fileContentaB64 = arrayBufferToBase64(new Uint8Array(result))
      resolve(fileContentaB64)
    }
    reader.onerror = () => {
      reject(reader.error)
    }
    reader.readAsArrayBuffer(file)
  })
}

export const FileUpload: React.FC<{
  comp: FileDropModel
  onCommand: (cmd: Command) => void
  onChange: (_: FileDropModel) => void
}> = ({ comp, onChange, onCommand }) => {
  const [loading, setLoading] = useState(false)
  const { queueMessage } = useSnackbar()

  const files = comp.files_raw ?? []

  files.forEach(value => {
    if (!isDefined(value.success) && value.contents) {
      value.success = true
    }
  })

  const clearField = () => {
    onChange(setFileDropValue(comp, []))
  }

  const checkFilesPresent = (
    newFiles: File[],
    oldFiles: FileModel[],
  ): File[] => {
    return newFiles.reduce((accumulator: File[], currentValue: File) => {
      if (!oldFiles.some(obj => obj.name === currentValue.name)) {
        accumulator.push(currentValue)
      }
      return accumulator
    }, [])
  }

  const processFiles = async (files: FileList | null): Promise<void> => {
    if (isDefined(files) && files.length > 0) {
      setLoading(true)
      const trimmedFiles = checkFilesPresent(
        Array.from(files),
        comp.files_raw ?? [],
      )
      const getAllContents = trimmedFiles.map(async file => {
        const fileData = await getFileContent(file).catch(reason => {
          logWarn(`File failed to upload: ${reason}`)
          queueMessage({
            title: 'File upload error',
            subtitle: 'File failed to upload. Please try again.',
            subType: 'failure',
            duration: 5000,
          })
          return ''
        })
        return {
          name: file.name,
          contents: fileData,
          success: isDefined(fileData) && fileData !== '',
        }
      })
      const allFiles = await Promise.all(getAllContents).finally(() => {
        setLoading(false)
      })
      const concFiles = concatFiles(comp, allFiles) ?? []
      onChange(setFileDropValue(comp, concFiles))
    } else {
      clearField()
    }
  }

  const onChangeHandler = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const files = event.currentTarget.files
    if (isDefined(files)) {
      await processFiles(files)
    }
  }

  const evAttr: any = mergedComponentEvents(
    () => comp,
    { type: 'from-stablestate', fn: onCommand },
    onChangeHandler,
  )

  const removeFile = (filename: string): void => {
    const filtered = comp.files_raw?.filter(f => f.name !== filename)
    onChange(setFileDropValue(comp, filtered ?? []))
  }

  const handleDrop: DragEventHandler<HTMLDivElement> = event => {
    event.preventDefault()
    event.stopPropagation()
    // Prevent multiple drops if multiple=false
    if (
      !comp.multiple &&
      isDefined(comp.files_raw) &&
      comp.files_raw.length > 0
    ) {
      return
    }
    const files = event.dataTransfer?.files
    processFiles(files)
  }

  return (
    <Box
      sx={{
        width: {
          sm: '100%',
          md: '600px',
        },
        marginBottom: '24px',
      }}
    >
      <Box
        sx={{
          border: theme => `1px dashed ${theme.palette.primary.main}`,
          padding: '24px',
          textAlign: 'center',
          marginBottom: '8px',
          position: 'relative',
          transition: 'all 0.14s ease',
          '&:hover': {
            background: theme => alpha(theme.palette.primary.light, 0.2),
            '.fa-file-arrow-up': {
              color: theme => theme.palette.primary.main,
            },
          },
          '& input[type="file"]': {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            opacity: 0,
            cursor: 'pointer',
          },
        }}
        onDrop={handleDrop}
        onDragOver={e => e.preventDefault()}
      >
        {loading ? (
          <>
            <Icon
              className="fa-duotone fa-spinner-third fa-spin"
              fontSize="large"
              sx={{
                color: theme => theme.palette.text.secondary,
                fontSize: '4rem',
                padding: 0,
                boxSizing: 'unset',
                width: 'unset',
                height: 'unset',
              }}
            />
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '1.2rem',
              }}
            >
              Uploading...
            </Typography>
          </>
        ) : (
          <>
            <Icon
              className="fa-solid fa-file-arrow-up"
              fontSize="large"
              sx={{
                color: theme => theme.palette.text.secondary,
                transition: 'all 0.14s ease',
                fontSize: '4rem',
              }}
            />
            <input
              type="file"
              {...evAttr}
              multiple={comp.multiple ? true : null}
              accept={comp.accept}
              aria-labelledby={`${comp.id}-label`}
              id={comp.id}
            />
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '1.2rem',
              }}
              id={`${comp.id}-label`}
            >
              {comp.text}
            </Typography>
            <Typography
              sx={{
                fontSize: '0.8rem',
              }}
            >
              Drag and drop, or click to browse
            </Typography>
          </>
        )}
      </Box>

      {files.map((file, idx) => (
        <Box
          key={idx}
          sx={{
            padding: '8px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            border: theme =>
              `1px solid ${
                file.success
                  ? theme.palette.secondary.dark
                  : theme.palette.error.main
              }`,
            marginBottom: '6px',
            background: theme =>
              file.success
                ? theme.palette.secondary.main
                : alpha(theme.palette.error.light, 0.2),
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '6px',
              alignItems: 'center',
            }}
          >
            <Icon
              className={`fa-regular ${getDownloadIconFromFileName(file.name)}`}
              fontSize="medium"
              sx={{
                color: theme => theme.palette.text.secondary,
                fontSize: '1.2rem',
              }}
            />
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '0.9rem',
                color: theme => theme.palette.text.secondary,
              }}
            >
              {file.name}
            </Typography>
            {!file.success && (
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: '0.9rem',
                  color: theme => theme.palette.error.main,
                }}
              >
                - File failed to upload
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              alignItems: 'center',
            }}
          >
            <IconButton
              className="fa-regular fa-trash"
              size="small"
              sx={{
                color: theme => theme.palette.error.main,
                width: '22px',
                fontSize: '1rem',
                height: '22px',
              }}
              onClick={() => removeFile(file.name)}
            />
          </Box>
        </Box>
      ))}
    </Box>
  )
}
