import React, { useMemo } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import AppBar from '@mui/material/AppBar'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import Icon from '@mui/material/Icon'
import Box from '@mui/material/Box'
import { LinearProgress } from '@mui/material'
import clsx from 'clsx'

import {
  AppId,
  AppRegistration,
  AppSkeleton,
  Message,
  Warning,
} from '../../types'
import Eraser from '../../components/Eraser/Eraser'
import MagicWand from '../../components/MagicWand/MagicWand'
import Favorite from '../../components/Favorite/Favorite'
import { getJuvoConfig, getLastPathSegment } from '../../utils'
import { useOnSmallScreen } from '../../hooks/useOnSmallScreen'
import ConnectionStatus from '../../components/ConnectionStatus/ConnectionStatus'
import DocLink from '../../components/DocLink/DocLink'
import { imGet, ImmutableMap } from '../../utils/ImmutableMap'

const Header: React.FC<{
  apps: ImmutableMap<AppId, [Warning, AppRegistration]>
  onAppSkelChange: (appId: AppId) => (appsk: AppSkeleton) => void
  onAppRegChange: (appId: AppId) => (appsk: AppRegistration) => void
  onOutMsg: (msg: Message) => Promise<void>
  toggleTestApp: () => void
  isDisconnected: boolean
}> = ({ apps, onAppSkelChange, onOutMsg, toggleTestApp, isDisconnected }) => {
  const location = useLocation()
  const onSmallScreen = useOnSmallScreen()
  const { headerConfig, feedbackEmail } = getJuvoConfig()

  const appReg = useMemo(() => {
    // HACK: We need to get last path segment, but we cannot use useParams, because header is outside the Route context.
    const appId = getLastPathSegment()
    const app = imGet(apps)(appId)
    if (app === undefined) {
      return null
    } else {
      const [, appReg] = app
      return appReg
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, apps])

  return (
    <AppBar
      position="relative"
      className="header"
      sx={{
        boxShadow: 'none !important',
        background: style => style.palette.grey[900],
        paddingRight: {
          xs: '4px',
          sm: '16px',
        },
        paddingLeft: {
          xs: 'var(--header-height-mobile)',
          sm: 'var(--header-height)',
        },
      }}
    >
      <Box
        sx={{
          color: style => style.palette.common.white,
          height: {
            xs: 'var(--header-height-mobile)',
            sm: 'var(--header-height)',
          },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {headerConfig.menu && (
          <>
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                minWidth: 0,
              }}
            >
              {appReg && (
                <>
                  <Icon
                    className={clsx(appReg.app_agent?.icon)}
                    sx={{
                      width: 'var(--header-height-mobile)',
                      height: 'var(--header-height-mobile)',
                      fontSize: '1.2rem',
                      padding: 0,
                      alignItems: 'center',
                      justifyContent: 'center',
                      display: { xs: 'none', sm: 'flex' },
                    }}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      flexDirection: 'column',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                      }}
                    >
                      <Tooltip
                        arrow
                        title={
                          appReg.app_name +
                          (appReg.app_version ? ' ' + appReg.app_version : '')
                        }
                      >
                        <Typography
                          sx={{
                            marginLeft: { xs: '0.25rem', sm: '0rem' },
                            marginRight: { xs: '0.25rem', sm: '1rem' },
                            paddingLeft: { xs: '8px', sm: 0 },
                            fontSize: '1.1rem',
                            lineHeight: 'initial',
                            fontWeight: style =>
                              style.typography.fontWeightBold,
                            color: style => style.palette.common.white,
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                          }}
                        >
                          {appReg.app_name}
                        </Typography>
                      </Tooltip>
                    </Box>
                    {appReg.app_version && (
                      <Typography
                        sx={{
                          display: { xs: 'none', sm: 'block' },
                          color: style => style.palette.grey[400],
                          fontWeight: 500,
                          fontSize: '0.85rem',
                          lineHeight: 'initial',
                        }}
                      >
                        v{appReg.app_version}
                      </Typography>
                    )}
                  </Box>
                </>
              )}
            </Box>

            <Box
              sx={{
                display: 'flex',
                gap: { xs: '0px', sm: '8px' },
                color: style => style.palette.grey[300],
                'button:hover, a:hover': {
                  color: style => style.palette.primary.light,
                },
              }}
            >
              {appReg && headerConfig.eraser && (
                <Eraser app={appReg} onAppDataChange={onAppSkelChange} />
              )}
              {appReg && headerConfig.magicWand && (
                <MagicWand app={appReg} onOutMsg={onOutMsg} />
              )}
              {appReg && headerConfig.docLink && (
                <DocLink aria-label="menu" app={appReg} />
              )}
              {headerConfig.ml && (
                <Tooltip arrow title="Datascience information">
                  <IconButton
                    aria-label="datascience information"
                    className="fa-solid fa-flask"
                    component={Link}
                    to="/suggestions"
                    size="small"
                  />
                </Tooltip>
              )}
              {headerConfig.testApp && !onSmallScreen && (
                <Tooltip arrow title="Show Test Application">
                  <IconButton
                    aria-label="show test application"
                    className="fa-sharp fa-solid fa-file-pen"
                    onClick={toggleTestApp}
                    size="small"
                  />
                </Tooltip>
              )}
              <Tooltip arrow title="Feedback">
                <IconButton
                  component="a"
                  href={`mailto:${feedbackEmail}?subject=Feedback from ${
                    appReg
                      ? appReg.app_name + ' ' + appReg.app_version
                      : 'Juvo dashboard'
                  }`}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="provide feedback"
                  size="small"
                  className="fa-solid fa-message"
                ></IconButton>
              </Tooltip>
              <Tooltip arrow title="Account">
                <IconButton
                  component={NavLink}
                  to="/account"
                  aria-label="show account page"
                  size="small"
                  className="fa-solid fa-user"
                ></IconButton>
              </Tooltip>
              {headerConfig.connectionStatus && isDisconnected && (
                <ConnectionStatus />
              )}
              {appReg && <Favorite aria-label="menu" appId={appReg.app_id} />}
            </Box>
          </>
        )}
        {isDisconnected && (
          <LinearProgress
            sx={{
              position: 'absolute',
              bottom: '0',
              left: '0',
              right: '0',
            }}
            color="error"
          />
        )}
      </Box>
    </AppBar>
  )
}

export default Header
