import React from 'react'
import { Box, useTheme } from '@mui/material'

import Button from '../Button/Button'
import { AppRegistration, ButtonsModel, Command, Warning } from '../../../types'
import { getButtons, getJuvoInfo } from '../../../store'
import AppWarnings from '../AppWarnings/AppWarnings'
import { ValidationError } from '../../../store/Validation'

const Buttons: React.FC<{
  comp: ButtonsModel
  onCommand: (cmd: Command) => void
  appInfo: [Warning, AppRegistration]
  validationState: {
    validationErrs: ValidationError[]
    setValidationErrs: (_: ValidationError[]) => void
  }
  handlerValidation?: (value: boolean) => void
}> = ({ comp, onCommand, appInfo, validationState, handlerValidation }) => {
  const theme = useTheme()
  const buttons = getButtons(comp)
  const { validationErrs, setValidationErrs } = validationState

  return (
    <div {...getJuvoInfo('Buttons', comp)}>
      {validationErrs.length > 0 && (
        <AppWarnings warnings={appInfo[0]} validationErrs={validationErrs} />
      )}
      <Box
        className="juvo-buttons"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          margin: '1rem 0',
          [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            justifyContent: 'flex-end',
          },
          '& > :not(:last-child)': {
            marginBottom: '0.5rem',
            [theme.breakpoints.up('md')]: {
              marginBottom: 0,
              marginRight: '0.5rem',
            },
          },
        }}
      >
        {buttons.map((button, index) => (
          <Button
            key={index}
            comp={button}
            onCommand={onCommand}
            setValidationErrs={setValidationErrs}
            app={appInfo[1]}
            handlerValidation={handlerValidation}
            compId={comp.id}
          />
        ))}
      </Box>
    </div>
  )
}

export default Buttons
