import React from 'react'
import { Box, Button } from '@mui/material'

import { ErrorHeader } from '../ErrorHeader/ErrorHeader'

import ConnectionLostSplash from './ConnectionLostSplash'

const ConnectionLost: React.FC = () => {
  return (
    <Box
      sx={{
        textAlign: 'center',
      }}
    >
      <ErrorHeader title="Connection lost" subtitle="Please refresh the page">
        <ConnectionLostSplash />
      </ErrorHeader>
      <Box
        sx={{
          margin: '24px 0 0 0',
        }}
      >
        <Button
          variant="contained"
          onClick={() => {
            location.reload()
          }}
        >
          Refresh
        </Button>
      </Box>
    </Box>
  )
}

export default ConnectionLost
