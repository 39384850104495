import React from 'react'
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Dialog,
  AlertColor,
} from '@mui/material'

import { isDefined } from '../../utils'
import { getNotificationIconFromSeverity } from '../../utils/Notifications'

export const JuvoDialog: React.FC<{
  title: string
  children: React.ReactNode
  severity: AlertColor
  open: boolean
  handleClose: () => void
  buttonText?: string
}> = ({ title, children, severity, open, handleClose, buttonText }) => {
  return (
    <Dialog
      open={open}
      aria-labelledby="juvo-modal-title"
      aria-describedby="juvo-modal-description"
      sx={{
        zIndex: theme => theme.zIndex.modal,
        '.MuiDialog-paper': {
          width: { xs: '90%', sm: '75%', lg: '50%' },
          borderRadius: 0,
          maxWidth: 'unset',
        },
      }}
    >
      <DialogTitle
        sx={{
          background: style =>
            // avoid info yellow background
            severity === 'info'
              ? style.palette.primary.dark
              : style.palette[severity].dark,
          padding: '22px 32px',
          display: 'flex',
          alignItems: 'center',
          gap: '12px',
          color: 'white',
        }}
      >
        {getNotificationIconFromSeverity(severity, {
          background: 'white',
          padding: '8px',
          fontSize: '1.6rem',
        })}
        {title}
      </DialogTitle>

      <DialogContent
        sx={{
          padding: '20px 32px !important',
          minHeight: '100px',
        }}
      >
        {children}
      </DialogContent>

      <DialogActions
        sx={{
          padding: '12px 32px',
          display: 'flex',
          justifyContent: 'right',
          gap: '12px',
          background: style => style.palette.secondary.main,
        }}
      >
        <Button onClick={handleClose}>
          {isDefined(buttonText) ? buttonText : 'Close'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
