import { Box, Button, useTheme } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

import { ErrorHeader } from '../ErrorHeader/ErrorHeader'
import { getSessionId } from '../../../utils/Logger'

import NotFoundSplash from './NotFoundSplash'

const NotFound: React.FC<{
  message: string
  homeLink: boolean
  action?: () => void
}> = ({ message, homeLink, action }) => {
  const theme = useTheme()

  return (
    <>
      <Box
        sx={{
          textAlign: 'center',
        }}
      >
        <ErrorHeader title="Oops!" subtitle="Something went wrong.">
          <NotFoundSplash />
        </ErrorHeader>
        <Box
          sx={{
            fontWeight: '500',
            background: style => style.palette.secondary.main,
            color: style => style.palette.primary.dark,
            borderRadius: '4px',
            margin: '0 auto',
            padding: '14px 18px',
            lineHeight: '1.6',
            [theme.breakpoints.up('xs')]: {
              minWidth: 'unset',
              width: 'unset',
            },
            [theme.breakpoints.up('sm')]: {
              minWidth: '400px',
              width: '60%',
            },
            [theme.breakpoints.up('md')]: {
              minWidth: '500px',
              width: '50%',
            },
          }}
        >
          {message}
        </Box>
        <Box
          sx={{
            fontWeight: '400',
            color: style => style.palette.primary.dark,
            borderRadius: '4px',
            margin: '0 auto',
            padding: '10px 18px',
          }}
        >
          Session ID: {getSessionId() ?? 'N/A'}
        </Box>
        <Box
          sx={{
            margin: '24px 0 0 0',
          }}
        >
          {homeLink && (
            <Button
              variant="contained"
              component={Link}
              to="/"
              onClick={() => {
                action && action()
              }}
            >
              Go Home
            </Button>
          )}
        </Box>
      </Box>
    </>
  )
}

export default NotFound
