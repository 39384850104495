import React from 'react'
import Paper from '@mui/material/Paper'

import {
  adjustStdComponentArgs,
  ComponentModel,
  StdComponentArgs,
  StepFinalModel,
} from '../../../types'
import SwitchYard from '../../juvo-component/SwitchYard/SwitchYard'
import { containerSetChild } from '../../../store'
import { Nullable } from '../../../utils'

const StepContent: React.FC<
  StdComponentArgs<Nullable<StepFinalModel>>
> = props => {
  const { comp, onComponentChange } = props
  const children: ComponentModel[] = comp?.tchildren || []

  return (
    <Paper
      variant="outlined"
      className="step-paper"
      sx={{
        position: 'relative',
        padding: '2rem',
        marginTop: '1rem',
        border: '2px solid lightgray',
      }}
    >
      {children.map((cp, idx) => {
        const childProps = adjustStdComponentArgs(props, cp, nc =>
          onComponentChange(containerSetChild(nc, idx, comp)),
        )
        return <SwitchYard key={idx} {...childProps} />
      })}
    </Paper>
  )
}

export default StepContent
