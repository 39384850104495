import React from 'react'
import Icon from '@mui/material/Icon'
import Tooltip from '@mui/material/Tooltip'

const ConnectionStatus: React.FC = () => {
  return (
    <Tooltip arrow title="Not connected">
      <Icon
        className="fa fa-plug"
        fontSize="medium"
        sx={{
          color: theme => theme.palette.error.main,
        }}
      />
    </Tooltip>
  )
}

export default ConnectionStatus
