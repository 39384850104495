import React from 'react'

import './AppLoadingSplash.scss'

const AppLoadingSplash: React.FC = () => (
  <svg
    version="1.1"
    viewBox="0 0 97.565 111.89"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="translate(-63.029 -44.686)">
      <g
        transform="translate(683.21 129.61)"
        fill="#492354"
        strokeLinecap="round"
        strokeLinejoin="round"
        aria-label="Hello!"
      >
        <path d="m-602.11-12.5h4.8006v20.743h-4.8006zm-9.4234 20.743h-4.8006v-20.743h4.8006zm9.779-8.5048h-10.135v-4.0598h10.135z" />
        <path d="m-583.74 8.48q-2.7263 0-4.8006-1.0668-2.0447-1.0668-3.1708-2.9041-1.1261-1.8669-1.1261-4.2376 0-2.4003 1.0964-4.2376 1.1261-1.8669 3.0522-2.9041 1.9262-1.0668 4.3561-1.0668 2.341 0 4.2079 1.0075 1.8965 0.9779 2.993 2.8448 1.0964 1.8373 1.0964 4.4154 0 0.2667-0.0296 0.6223-0.0296 0.32597-0.0593 0.6223h-12.95v-2.6966h10.52l-1.778 0.8001q0-1.2446-0.50376-2.1632-0.50377-0.91863-1.3928-1.4224-0.889-0.5334-2.0743-0.5334t-2.104 0.5334q-0.889 0.50377-1.3928 1.452-0.50376 0.91863-0.50376 2.1929v0.7112q0 1.3039 0.56303 2.3114 0.59266 0.9779 1.6298 1.5113 1.0668 0.50377 2.4892 0.50377 1.2742 0 2.2225-0.38523 0.97789-0.38523 1.778-1.1557l2.4596 2.667q-1.0964 1.2446-2.7559 1.9262-1.6595 0.65193-3.8227 0.65193z" />
        <path d="m-571.9 8.2429v-21.988h4.6228v21.988z" />
        <path d="m-561.92 8.2429v-21.988h4.6228v21.988z" />
        <path d="m-544.36 8.48q-2.5485 0-4.5339-1.0668-1.9558-1.0668-3.1115-2.9041-1.1261-1.8669-1.1261-4.2376 0-2.4003 1.1261-4.2376 1.1557-1.8669 3.1115-2.9041 1.9854-1.0668 4.5339-1.0668 2.5188 0 4.5043 1.0668 1.9854 1.0372 3.1115 2.8744 1.1261 1.8373 1.1261 4.2672 0 2.3707-1.1261 4.2376-1.1261 1.8373-3.1115 2.9041t-4.5043 1.0668zm0-3.7931q1.1557 0 2.0743-0.5334t1.452-1.5113q0.5334-1.0075 0.5334-2.3707 0-1.3928-0.5334-2.3707-0.5334-0.9779-1.452-1.5113t-2.0743-0.5334-2.0743 0.5334-1.4817 1.5113q-0.5334 0.9779-0.5334 2.3707 0 1.3631 0.5334 2.3707 0.56304 0.9779 1.4817 1.5113t2.0743 0.5334z" />
        <path d="m-531.15 1.042-0.91863-13.542h5.5118l-0.91863 13.542zm1.8373 7.438q-1.2446 0-2.0447-0.8001t-0.8001-1.8965q0-1.1261 0.8001-1.8669 0.8001-0.77047 2.0447-0.77047 1.2742 0 2.0447 0.77047 0.80009 0.74083 0.80009 1.8669 0 1.0964-0.80009 1.8965-0.77047 0.8001-2.0447 0.8001z" />
      </g>
      <g
        transform="translate(683.21 129.61)"
        fill="#78398c"
        strokeLinecap="round"
        strokeLinejoin="round"
        aria-label="Welcome to Juvo"
      >
        <path d="m-617.19 26.891-2.9972-8.89h0.9652l2.8067 8.3693h-0.4826l2.8956-8.3693h0.8636l2.8575 8.3693h-0.4572l2.8321-8.3693h0.9017l-2.9972 8.89h-0.9906l-2.7305-7.9375h0.254l-2.7432 7.9375z" />
        <path d="m-603.95 26.955q-1.0414 0-1.8288-0.4318-0.7874-0.4445-1.2319-1.2065-0.4445-0.7747-0.4445-1.7653t0.4191-1.7526q0.4318-0.762 1.1684-1.1938 0.7493-0.4445 1.6764-0.4445 0.9398 0 1.6637 0.4318 0.7366 0.4191 1.1557 1.1938 0.4191 0.762 0.4191 1.7653 0 0.0635-0.0127 0.1397 0 0.0635 0 0.1397h-5.8039v-0.6731h5.3213l-0.3556 0.2667q0-0.7239-0.3175-1.2827-0.3048-0.5715-0.8382-0.889t-1.2319-0.3175q-0.6858 0-1.2319 0.3175t-0.8509 0.889-0.3048 1.3081v0.1397q0 0.762 0.3302 1.3462 0.3429 0.5715 0.9398 0.9017 0.6096 0.3175 1.3843 0.3175 0.6096 0 1.1303-0.2159 0.5334-0.2159 0.9144-0.6604l0.508 0.5842q-0.4445 0.5334-1.1176 0.8128-0.6604 0.2794-1.4605 0.2794z" />
        <path d="m-599.9 26.891v-9.4234h0.9017v9.4234z" />
        <path d="m-594.51 26.955q-0.9906 0-1.778-0.4318-0.7747-0.4445-1.2192-1.2065-0.4445-0.7747-0.4445-1.7653 0-1.0033 0.4445-1.7653t1.2192-1.1938q0.7874-0.4318 1.778-0.4318 0.8509 0 1.5367 0.3302t1.0795 0.9906l-0.6731 0.4572q-0.3429-0.508-0.8509-0.7493t-1.1049-0.2413q-0.7112 0-1.2827 0.3302-0.5715 0.3175-0.9017 0.9017t-0.3302 1.3716 0.3302 1.3716 0.9017 0.9144q0.5715 0.3175 1.2827 0.3175 0.5969 0 1.1049-0.2413t0.8509-0.7366l0.6731 0.4572q-0.3937 0.6477-1.0795 0.9906-0.6858 0.3302-1.5367 0.3302z" />
        <path d="m-588.32 26.955q-0.9652 0-1.7399-0.4318-0.762-0.4445-1.2065-1.2065-0.4445-0.7747-0.4445-1.7653 0-1.0033 0.4445-1.7653t1.2065-1.1938 1.7399-0.4318q0.9906 0 1.7526 0.4318 0.7747 0.4318 1.2065 1.1938 0.4445 0.762 0.4445 1.7653 0 0.9906-0.4445 1.7653-0.4318 0.762-1.2065 1.2065-0.7747 0.4318-1.7526 0.4318zm0-0.8001q0.7239 0 1.2827-0.3175 0.5588-0.3302 0.8763-0.9144 0.3302-0.5969 0.3302-1.3716 0-0.7874-0.3302-1.3716-0.3175-0.5842-0.8763-0.9017-0.5588-0.3302-1.27-0.3302t-1.27 0.3302q-0.5588 0.3175-0.889 0.9017t-0.3302 1.3716q0 0.7747 0.3302 1.3716 0.3302 0.5842 0.889 0.9144 0.5588 0.3175 1.2573 0.3175z" />
        <path d="m-575.53 20.16q0.8128 0 1.4097 0.3175 0.6096 0.3048 0.9398 0.9398 0.3429 0.635 0.3429 1.6002v3.8735h-0.9017v-3.7846q0-1.0541-0.508-1.5875-0.4953-0.5461-1.4097-0.5461-0.6858 0-1.1938 0.2921-0.4953 0.2794-0.7747 0.8255-0.2667 0.5334-0.2667 1.2954v3.5052h-0.9017v-3.7846q0-1.0541-0.508-1.5875-0.508-0.5461-1.4224-0.5461-0.6731 0-1.1811 0.2921-0.508 0.2794-0.7874 0.8255-0.2667 0.5334-0.2667 1.2954v3.5052h-0.9017v-6.6675h0.8636v1.8034l-0.1397-0.3175q0.3048-0.7239 0.9779-1.1303 0.6858-0.4191 1.6129-0.4191 0.9779 0 1.6637 0.4953 0.6858 0.4826 0.889 1.4605l-0.3556-0.1397q0.2921-0.8128 1.0287-1.3081 0.7493-0.508 1.7907-0.508z" />
        <path d="m-568.35 26.955q-1.0414 0-1.8288-0.4318-0.7874-0.4445-1.2319-1.2065-0.4445-0.7747-0.4445-1.7653t0.4191-1.7526q0.4318-0.762 1.1684-1.1938 0.7493-0.4445 1.6764-0.4445 0.9398 0 1.6637 0.4318 0.7366 0.4191 1.1557 1.1938 0.4191 0.762 0.4191 1.7653 0 0.0635-0.0127 0.1397 0 0.0635 0 0.1397h-5.8039v-0.6731h5.3213l-0.3556 0.2667q0-0.7239-0.3175-1.2827-0.3048-0.5715-0.8382-0.889t-1.2319-0.3175q-0.6858 0-1.2319 0.3175t-0.8509 0.889-0.3048 1.3081v0.1397q0 0.762 0.3302 1.3462 0.3429 0.5715 0.9398 0.9017 0.6096 0.3175 1.3843 0.3175 0.6096 0 1.1303-0.2159 0.5334-0.2159 0.9144-0.6604l0.508 0.5842q-0.4445 0.5334-1.1176 0.8128-0.6604 0.2794-1.4605 0.2794z" />
        <path d="m-559.67 26.955q-0.9398 0-1.4478-0.508t-0.508-1.4351v-6.2611h0.9017v6.2103q0 0.5842 0.2921 0.9017 0.3048 0.3175 0.8636 0.3175 0.5969 0 0.9906-0.3429l0.3175 0.6477q-0.2667 0.2413-0.6477 0.3556-0.3683 0.1143-0.762 0.1143zm-3.1496-5.9817v-0.7493h4.1275v0.7493z" />
        <path d="m-554.93 26.955q-0.9652 0-1.7399-0.4318-0.762-0.4445-1.2065-1.2065-0.4445-0.7747-0.4445-1.7653 0-1.0033 0.4445-1.7653t1.2065-1.1938 1.7399-0.4318q0.9906 0 1.7526 0.4318 0.7747 0.4318 1.2065 1.1938 0.4445 0.762 0.4445 1.7653 0 0.9906-0.4445 1.7653-0.4318 0.762-1.2065 1.2065-0.7747 0.4318-1.7526 0.4318zm0-0.8001q0.7239 0 1.2827-0.3175 0.5588-0.3302 0.8763-0.9144 0.3302-0.5969 0.3302-1.3716 0-0.7874-0.3302-1.3716-0.3175-0.5842-0.8763-0.9017-0.5588-0.3302-1.27-0.3302t-1.27 0.3302q-0.5588 0.3175-0.889 0.9017t-0.3302 1.3716q0 0.7747 0.3302 1.3716 0.3302 0.5842 0.889 0.9144 0.5588 0.3175 1.2573 0.3175z" />
        <path d="m-546.81 26.968q-0.7493 0-1.4097-0.3302-0.6477-0.3302-1.0414-0.9398l0.5461-0.635q0.3683 0.5334 0.8509 0.8128t1.0668 0.2794q1.651 0 1.651-1.9558v-5.3848h-3.2512v-0.8128h4.1783v6.1595q0 1.4097-0.6604 2.1082-0.6477 0.6985-1.9304 0.6985z" />
        <path d="m-539.62 26.955q-0.8509 0-1.4859-0.3175t-0.9906-0.9525q-0.3429-0.635-0.3429-1.5875v-3.8735h0.9017v3.7719q0 1.0668 0.5207 1.6129 0.5334 0.5334 1.4859 0.5334 0.6985 0 1.2065-0.2794 0.5207-0.2921 0.7874-0.8382 0.2794-0.5461 0.2794-1.3081v-3.4925h0.9017v6.6675h-0.8636v-1.8288l0.1397 0.3302q-0.3175 0.7366-0.9906 1.1557-0.6604 0.4064-1.5494 0.4064z" />
        <path d="m-532.92 26.891-2.9591-6.6675h0.9398l2.7178 6.1849h-0.4445l2.7559-6.1849h0.889l-2.9718 6.6675z" />
        <path d="m-526.02 26.955q-0.9652 0-1.7399-0.4318-0.762-0.4445-1.2065-1.2065-0.4445-0.7747-0.4445-1.7653 0-1.0033 0.4445-1.7653t1.2065-1.1938 1.7399-0.4318q0.9906 0 1.7526 0.4318 0.7747 0.4318 1.2065 1.1938 0.4445 0.762 0.4445 1.7653 0 0.9906-0.4445 1.7653-0.4318 0.762-1.2065 1.2065-0.7747 0.4318-1.7526 0.4318zm0-0.8001q0.7239 0 1.2827-0.3175 0.5588-0.3302 0.8763-0.9144 0.3302-0.5969 0.3302-1.3716 0-0.7874-0.3302-1.3716-0.3175-0.5842-0.8763-0.9017-0.5588-0.3302-1.27-0.3302t-1.27 0.3302q-0.5588 0.3175-0.889 0.9017t-0.3302 1.3716q0 0.7747 0.3302 1.3716 0.3302 0.5842 0.889 0.9144 0.5588 0.3175 1.2573 0.3175z" />
      </g>
      <g transform="translate(-697.69 -30.534)">
        <path
          d="m796.91 115.48-9.7701 8.8384-4.7226-5.4644 9.2545-8.3341"
          fill="#cf4256"
          strokeWidth=".38198"
        />
        <path
          d="m792.2 107.84 24.336 0.84921-2.7917 19.314-21.725-1.7072"
          fill="#f15c5e"
          strokeWidth=".41455"
        />
        <path
          d="m796.94 128.77 11.195-0.11179-0.0899 7.4731h-10.566"
          fill="#cf4256"
          strokeWidth=".35359"
        />
        <rect
          transform="rotate(13.764)"
          x="804.76"
          y="-114.49"
          width="2.626"
          height="9.1055"
          fill="#cf4256"
        />
        <path
          d="m790.73 84.472 34.882 4.8296-4.6959 18.553-31.311-4.5993"
          fill="#f15c5e"
          strokeWidth=".40731"
        />
        <path
          d="m810.06 83.783c2.3568 0.27072 4.4868-1.4203 4.7576-3.7772 0.27072-2.3568-1.4204-4.4868-3.7772-4.7576-2.3568-0.27073-4.4869 1.4204-4.7576 3.7772-0.27072 2.3568 1.4204 4.4868 3.7772 4.7576z"
          fill="#f15c5e"
          strokeWidth=".49304"
        />
        <path
          d="m810.55 76.652c-1.4218-0.16332-2.7069 0.8569-2.8702 2.2787"
          fill="none"
          stroke="#f6b4a4"
          strokeLinecap="round"
          strokeWidth=".95254"
        />
        <g
          transform="matrix(1.7649 .20256 -.20256 1.7649 287.83 -204.2)"
          fill="#fff"
          strokeWidth=".26458"
        >
          <path d="m310.85 134.89c-5e-3 -0.0646-8e-3 -0.12984-8e-3 -0.19585 0-1.2731 1.0322-2.3053 2.3053-2.3053 1.273 0 2.3048 1.0322 2.3048 2.3053 0 0.066-3e-3 0.13125-8e-3 0.19585z" />
          <path d="m303.63 134.89c-5e-3 -0.0646-8e-3 -0.12984-8e-3 -0.19585 0-1.2731 1.0322-2.3053 2.3053-2.3053 1.273 0 2.3048 1.0322 2.3048 2.3053 0 0.066-3e-3 0.13125-8e-3 0.19585z" />
        </g>
        <g fill="#cf4256">
          <path
            d="m813.74 128 0.15808-1.0937-21.884-1.8395v1.2259z"
            strokeLinecap="square"
            strokeWidth=".5"
          />
          <path
            d="m789.61 103.26 0.12233-1.9509 31.505 5.3001-0.31644 1.2501z"
            strokeLinecap="square"
            strokeWidth=".5"
          />
          <path
            className="hand"
            d="m812.16 112.6 10.471-9.4728 5.0616 5.8567-9.9188 8.9324"
            strokeWidth=".4094"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default AppLoadingSplash
