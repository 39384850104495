import React from 'react'

import { AppItem, MLData, mlDataKeys, mlDataMap } from '../../types'
import { logDebug } from '../../utils/Logger'
import { Nullable } from '../../utils/Undefined'

import MLStatsElement from './MLStatsElement'

const MLStats: React.FC<{
  appInfo: AppItem
  mlData: Nullable<MLData>
}> = ({ appInfo, mlData }) => {
  logDebug({ mldata: mlData })

  //This needs to be a carousel, will implement when we get material merged in
  const mlkeys = mlDataKeys(mlData)
  const mlmap = mlDataMap(mlData)

  if (mlmap.size > 0) {
    const todokey = mlkeys[0]
    const todoval = mlmap.get(todokey)
    return (
      <div>
        {/* <h1 debug-info={getDebugInfo(mlData)}> Stats for {appInfo.app_name}</h1> */}
        <span>(Not fully implemented yet)</span>
        {todoval && (
          <MLStatsElement statKey={todokey} mlDataElement={todoval} />
        )}
      </div>
    )
  } else {
    return <h1>No ML Data for {appInfo.app_name}</h1>
  }
}

export default MLStats
