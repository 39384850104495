import React from 'react'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import SendIcon from '@mui/icons-material/Send'
import { InputBase } from '@mui/material'
import clsx from 'clsx'

import { buttonEvents, getJuvoInfo, setComponentValue } from '../../../store'
import {
  InputStateProps,
  isDefined,
  isUndefined,
  sanitizeInputName,
} from '../../../utils'
import {
  AppRegistration,
  Command,
  TextInputModel,
  Warning,
} from '../../../types'

const InputCommand: React.FC<{
  comp: TextInputModel
  onCommand: (cmd: Command) => void
  onChange: (_: TextInputModel) => void
  appInfo: [Warning, AppRegistration]
  inputStateProps: InputStateProps
}> = ({ comp, onCommand, appInfo, onChange, inputStateProps }) => {
  const juvoInfo = getJuvoInfo('input.input-command', comp)
  const label = comp.text || ''
  const recommendation = comp.recommendation
  const { value, setValue, handleBlur, handlersFromServer } = inputStateProps
  const inputName = sanitizeInputName(label)
  const inputValue = isDefined(value)
    ? value
    : isDefined(recommendation)
    ? recommendation
    : ''

  const evAttr = buttonEvents(
    comp,
    { type: 'from-stablestate', fn: onCommand },
    false,
    () => undefined,
    appInfo[1],
    '',
  )

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(setComponentValue(comp)(e.target.value))
    setValue(e.target.value)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (evAttr && event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      evAttr.onClick()
    }
  }

  return (
    <Box
      sx={{
        backgroundColor: 'var(--color-white)',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        margin: '24px auto 0px auto',
      }}
    >
      <FormControl
        variant="standard"
        fullWidth
        className="juvo-input-control"
        {...juvoInfo}
      >
        <InputBase
          {...handlersFromServer}
          type="text"
          size="small"
          multiline
          id={inputName}
          name={inputName}
          value={inputValue}
          placeholder={label}
          onChange={handleChange}
          onClick={() => undefined} // to override handlersFromServer onClick
          onBlur={handleBlur}
          className={clsx(
            'juvo-input-base',
            isDefined(recommendation) && isUndefined(value) && 'recommendation',
          )}
          onKeyDown={handleKeyDown}
        />
      </FormControl>
      {evAttr && (
        <IconButton size="small" sx={{ marginTop: '5px' }} {...evAttr}>
          <SendIcon sx={{ color: theme => theme.palette.primary.main }} />
        </IconButton>
      )}
    </Box>
  )
}

export default InputCommand
