import React from 'react'
import clsx from 'clsx'
import { FormControlLabel, Checkbox, Tooltip, Box } from '@mui/material'
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox'

import {
  Command,
  ComparisonBooleanInputModel,
  isCheckboxChecked,
  userCheckboxAction,
} from '../../../../types'
import {
  checkBoxTooltipTitle,
  getJuvoProps,
  isUndefinedBool,
  sanitizeInputName,
} from '../../../../utils'
import {
  booleanComparer,
  getComparisonClasses,
} from '../../../../utils/Comparison'
import ValueChips from '../ValueChips/ValueChips'

export const CompareBooleanInput: React.FC<{
  comp: ComparisonBooleanInputModel
  onCommand: (cmd: Command) => void
  onChange: (_: ComparisonBooleanInputModel) => void
}> = ({ comp, onCommand, onChange }) => {
  const label = comp.text || ''
  const chips = comp.compValues ?? []

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(userCheckboxAction(comp, e.target.checked))
  }

  const { eventHandlers, juvoInfo } = getJuvoProps(
    'CompareBooleanInput',
    comp,
    onCommand,
    handleChange,
  )
  const { onClick: onClickFromServer, ...handlersFromServer } = eventHandlers

  const valueChangeFromChip = (chip: boolean) => {
    onChange(userCheckboxAction(comp, chip))
  }

  return (
    <Box
      className={clsx(
        'juvo-compare-input-base',
        ...getComparisonClasses<boolean>(
          chips,
          isCheckboxChecked(comp),
          (a, b) => a === b,
        ),
      )}
      sx={{
        padding: '4px 10px',
        '.comparison-checkbox': {
          padding: '6px',
          margin: '0 2px',
        },
      }}
    >
      <FormControlLabel
        {...juvoInfo}
        label={label}
        control={
          <Tooltip
            arrow
            title={checkBoxTooltipTitle(comp.value, comp.recommendation)}
          >
            <Checkbox
              {...handlersFromServer}
              checked={isCheckboxChecked(comp)}
              indeterminate={
                isUndefinedBool(comp.value) &&
                isUndefinedBool(comp.recommendation)
              }
              indeterminateIcon={<IndeterminateCheckBoxIcon color="disabled" />}
              name={sanitizeInputName(label)}
              onClick={() => {
                onClickFromServer && onClickFromServer()
              }}
              sx={{
                color: styles => `${styles.palette.grey[800]}!important`,
              }}
            />
          </Tooltip>
        }
      />
      <Box
        sx={{
          display: 'flex',
          flexGrow: '1',
        }}
      >
        <ValueChips
          chips={chips}
          currentValue={isCheckboxChecked(comp)}
          comparisonFunc={booleanComparer}
          onClickEvent={valueChangeFromChip}
        />
      </Box>
    </Box>
  )
}
