import { useState } from 'react'

import { ValidationError } from '../../store/Validation'

const UseValidationErrState: React.FC<{
  children(props: {
    validationErrs: ValidationError[]
    setValidationErrs: (_: ValidationError[]) => void
  }): JSX.Element
}> = ({ children }) => {
  const [validationErrs, setValidationErrs] = useState<ValidationError[]>([])

  return children({ validationErrs, setValidationErrs })
}

export default UseValidationErrState
