import React from 'react'
import { TabPanelProps, useTabContext } from '@mui/lab'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import clsx from 'clsx'

import './TabPanel.scss'

const TabPanel: React.FC<TabPanelProps> = ({
  children,
  value: id,
  className,
  ...rest
}) => {
  const context = useTabContext()

  if (context === null) {
    console.error('Missing TabContext!')

    return (
      <Typography
        variant="h3"
        sx={{ textAlign: 'center', marginTop: theme => theme.spacing(4) }}
      >
        Something went wrong
      </Typography>
    )
  } else {
    const currentTabId = context.value
    const visible = id === currentTabId

    return (
      <Box
        className={clsx(className, visible ? 'panel-visible' : 'panel-hidden')}
        {...rest}
      >
        {children}
      </Box>
    )
  }
}

export default TabPanel
