import React from 'react'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { useCopy } from '../../hooks/useCopy'

const CopyBox: React.FC<{
  copyContent: string
  background?: string
  hoverBackground?: string
}> = ({ copyContent, background, hoverBackground }) => {
  const theme = useTheme()
  const [isCopied, copyHandler] = useCopy()

  return (
    <Box
      className="copy-button"
      sx={{
        display: 'none',
        position: 'absolute',
        top: 0,
        right: 0,
      }}
    >
      <CopyToClipboard text={copyContent} onCopy={copyHandler}>
        <Tooltip arrow title={isCopied ? 'Copied' : 'Copy'}>
          <IconButton
            className={`fa-light ${isCopied ? 'fa-check' : 'fa-copy'} `}
            size="small"
            color="primary"
            sx={{
              background: background ?? theme.palette.grey[200],
              '&:hover': {
                background: hoverBackground ?? theme.palette.grey[300],
              },
            }}
          />
        </Tooltip>
      </CopyToClipboard>
    </Box>
  )
}

export default CopyBox
