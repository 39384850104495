import React, { ReactElement } from 'react'
import {
  JuvoApp,
  noCustomComponents,
  notImplementedHandler,
  webLoginHandler,
} from '@juvo/common'
import { useWebContext } from '@juvo/common/src/hooks/useWebContext'

import { intakePortalTheme } from './intake-portal-theme'
import logo from './logo.svg'
import './App.scss'

const App = (): ReactElement => {
  const webContext = useWebContext()
  return (
    <JuvoApp
      customReactComps={noCustomComponents}
      officeCmdHandler={notImplementedHandler}
      ctx={webContext}
      loginHandler={webLoginHandler}
      theme={intakePortalTheme}
      logo={<img src={logo} alt="logo" data-testid='intake-logo' className="intake-portal-logo" />}
    />
  )
}

export default App
