import React, { useEffect } from 'react'
import Checkbox from '@mui/material/Checkbox'
import Tooltip from '@mui/material/Tooltip'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormLabel from '@mui/material/FormLabel'
import clsx from 'clsx'
import {
  CheckBoxOutlineBlankSharp,
  CheckBoxSharp,
  IndeterminateCheckBoxSharp,
} from '@mui/icons-material'
import { Box } from '@mui/material'

import {
  CheckboxModel,
  Command,
  isCheckboxChecked,
  userCheckboxAction,
  userCheckboxActionTristate,
} from '../../../types'
import {
  getJuvoProps,
  sanitizeInputName,
  isUndefinedBool,
  ValidationProps,
  checkBoxTooltipTitle,
} from '../../../utils'
import { InfoTooltip } from '../InfoTooltip/InfoTooltip'

const CheckBoxField: React.FC<{
  comp: CheckboxModel
  onCommand: (cmd: Command) => void
  onChange: (_: CheckboxModel) => void
  validationProps: ValidationProps
}> = ({ comp, onCommand, onChange, validationProps }) => {
  // handle two state default false
  useEffect(() => {
    if (comp.sub_type === 'two-state') {
      onChange(userCheckboxAction(comp, false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const helpText =
    comp.bound?.helpText?.recommendation || comp.bound?.helpText?.value
  const hasTwoStates = comp.sub_type === 'two-state'

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(userCheckboxAction(comp, e.target.checked))
  }
  const { eventHandlers, className, juvoInfo } = getJuvoProps(
    'CheckBox',
    comp,
    onCommand,
    handleChange,
  )
  const { onClick: onClickFromServer, ...handlersFromServer } = eventHandlers
  const label = comp.text || ''
  const { handleVisited, hasErrors, errorMessage } = validationProps

  // Handle render as options
  const translateOptionValue = (value: string) => {
    switch (value) {
      case 'true':
        return true
      case 'false':
        return false
      default:
        return null
    }
  }
  const handleOptionsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(
      userCheckboxActionTristate(comp, translateOptionValue(e.target.value)),
    )
  }

  return (
    <FormControl
      {...juvoInfo}
      error={hasErrors}
      sx={{ display: 'block', marginBottom: theme => theme.spacing(2) }}
      className={className}
    >
      {comp.render_as === 'options' ? (
        <>
          <Box>
            <FormLabel className={clsx(hasErrors && 'juvo-error')}>
              {label}
            </FormLabel>
            {comp.tooltip && <InfoTooltip tooltip={comp.tooltip} />}
          </Box>

          <RadioGroup
            {...handlersFromServer}
            value={
              isUndefinedBool(comp.value) &&
              isUndefinedBool(comp.recommendation)
                ? 'null'
                : isCheckboxChecked(comp)
            }
            row
            color={hasErrors ? 'error' : 'primary'}
            onChange={e => {
              handleOptionsChange(e)
              handleVisited()
              onClickFromServer && onClickFromServer()
            }}
          >
            <FormControlLabel value="true" control={<Radio />} label="Yes" />
            <FormControlLabel value="false" control={<Radio />} label="No" />
            <FormControlLabel
              value="null"
              control={<Radio />}
              label="Undecided"
            />
          </RadioGroup>
        </>
      ) : (
        <>
          <FormControlLabel
            label={label}
            className={clsx(hasErrors && 'juvo-error')}
            sx={{
              marginRight: 0,
            }}
            control={
              <>
                <Tooltip
                  arrow
                  title={checkBoxTooltipTitle(
                    comp.value,
                    comp.recommendation,
                    hasTwoStates,
                  )}
                >
                  <Checkbox
                    {...handlersFromServer}
                    icon={<CheckBoxOutlineBlankSharp />}
                    checkedIcon={<CheckBoxSharp />}
                    checked={isCheckboxChecked(comp)}
                    indeterminate={
                      isUndefinedBool(comp.value) &&
                      isUndefinedBool(comp.recommendation) &&
                      !hasTwoStates
                    }
                    color={hasErrors ? 'error' : 'primary'}
                    indeterminateIcon={
                      <IndeterminateCheckBoxSharp color="disabled" />
                    }
                    className={clsx(hasErrors && 'juvo-error')}
                    name={sanitizeInputName(label)}
                    onClick={() => {
                      handleVisited()
                      onClickFromServer && onClickFromServer()
                    }}
                  />
                </Tooltip>
              </>
            }
          />
          {comp.tooltip && <InfoTooltip tooltip={comp.tooltip} />}
        </>
      )}
      {hasErrors && <FormHelperText error>{errorMessage}</FormHelperText>}
      {helpText && <FormHelperText>{helpText}</FormHelperText>}
    </FormControl>
  )
}

export default CheckBoxField
