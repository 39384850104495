import { isUser, User } from '../types'
import { Either, eitherChain, left, right } from '../utils/Common'
import {
  betterFetchJson,
  FetchError,
  unexpectedFetchError,
} from '../utils/Fetch'
import { getJuvoConfig } from '../utils/JuvoConfig'
import { logDebug } from '../utils/Logger'

const checkUser = (res: any): Either<FetchError, User> => {
  if (isUser(res)) {
    return right(res)
  } else {
    logDebug({ source: 'checkUser', err: 'User parse err', dta: res })
    return left(unexpectedFetchError('New Result parse err'))
  }
}

export const user = async (): Promise<Either<FetchError, User>> => {
  const { platformHttpUrl } = getJuvoConfig()

  const res = await betterFetchJson(`${platformHttpUrl}/user`, {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
    },
  })

  return eitherChain(res, checkUser)
}
