import { AppTheme } from '@juvo/common'

export const intakePortalTheme: AppTheme = {
  typography: {
    allVariants: {
      color: '#323232',
    },
    fontFamily: `'HarmoniaSans', 'Merriweather', 'Helvetica Neue', sans-serif`,
  },
  palette: {
    primary: {
      main: '#3F534E',
      light:'#CDDADE',
      dark: '#33433F',
    },
    secondary: {
      main: '#87A7B3',
      light: '#CDDADE',
    },
    tertiary: {
      main: '#FF5C5E',
      light: '#FD9697',
      dark: '#DC383A',
    },
    info: {
      main: '#ffffcc',
      light: '#FFFFF5',
      dark: '#FFFF94',
    },
    error: {
      main: '#d32f2f',
      light: '#DA5151',
      dark:'#A72323',
    },
    text: {
      primary: '#323232',
      secondary: '#5B5B5B',
      disabled: '#8DA8A1',
    },
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        standardSuccess: {
          backgroundColor: '#D9F4D9',
        },
        standardError: {
          backgroundColor: '#ffd0d2',
        },
        standardWarning: {
          backgroundColor: '#F9E3C6',
        },
        standardInfo: {
          backgroundColor: '#E0E0E0',
        },
      },
    },
  },
}
