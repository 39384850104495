import { Logger } from 'tslog'
import { datadogRum } from '@datadog/browser-rum'

import { User } from '../types'

import { getJuvoConfig } from './JuvoConfig'
import { isDefined } from './Undefined'

const tsLogger = {
  logger: null as Logger<any> | null,
  errorLogger: null as Logger<any> | null,
  init: (defaultLogLevel: number) => {
    tsLogger.logger = new Logger({
      type: 'pretty',
      // Technical debt: I tried to come up with a way of preserving the location of the original log in code, but to no avail. Would be worth revisiting in the future.
      prettyLogTemplate: '[{{hh}}:{{MM}}:{{ss}}:{{ms}}] {{logLevelName}} - ',
      prettyErrorTemplate:
        '{{errorName}} {{errorMessage}}\nerror stack:\n{{errorStack}}',
      prettyLogTimeZone: 'local',
      overwrite: {
        // Overwrite the default transport so that json objects are still collapsed in the output.
        // Otherwise, each json object is fully expanded and very large.
        transportFormatted: (meta, args) => {
          logData(meta, args)
        },
      },
      minLevel: isDefined(defaultLogLevel) ? defaultLogLevel : 3,
      // minLevel: 3, // Overwrite minLevel to hide annoying messages while testing
      prettyLogStyles: {
        logLevelName: {
          '*': ['bold', 'black', 'bgWhiteBright', 'dim'],
          DEBUG: ['bold', 'green'],
          INFO: ['bold', 'blue'],
          WARN: ['bold', 'yellow'],
          ERROR: ['bold', 'red'],
          FATAL: ['bold', 'redBright'],
        },
        dateIsoStr: 'white',
        filePathWithLine: 'white',
        name: ['white', 'bold'],
        nameWithDelimiterPrefix: ['white', 'bold'],
        nameWithDelimiterSuffix: ['white', 'bold'],
        errorName: ['bold', 'bgRedBright', 'whiteBright'],
        fileName: ['yellow'],
      },
    })
    // Separate error logger so that it can be formatted differently
    tsLogger.errorLogger = tsLogger.logger.getSubLogger({
      prettyLogTemplate:
        '[{{hh}}:{{MM}}:{{ss}}:{{ms}}] {{logLevelName}} - see below ',
      overwrite: {
        // Overwrite the default transport so that json objects are still collapsible in the output
        transportFormatted: (meta, args, errors) => {
          logData(meta, args, 'group')
          console.log(...errors)
          console.groupEnd()
        },
      },
    })

    // Can't figure out a way to get the typing of this function to work without using 'any'
    // This sends manual errors on to datadog
    tsLogger.errorLogger.attachTransport((obj: any) => {
      if (obj._meta.logLevelId >= 5) {
        for (const prop in obj) {
          if (isDefined(obj[prop]?.nativeError)) {
            datadogRum.addError(obj[prop].nativeError)
            return
          }
        }
        datadogRum.addError('Something went wrong.')
      }
    })
  },
}

export const initializeLogging = () => {
  const {
    datadogApplicationId,
    datadogClientToken,
    datadogEndpoint,
    datadogService,
    environment,
    defaultLogLevel,
    disableDatadog,
  } = getJuvoConfig()

  if (!disableDatadog) {
    datadogRum.init({
      applicationId: datadogApplicationId,
      clientToken: datadogClientToken,
      site: datadogEndpoint,
      service: datadogService,
      env: environment,
      // version: '1.0.0', TODO: we need a reliable way to get juvo version number
      sessionSampleRate: 100,
      trackUserInteractions: true,
      trackLongTasks: true,
      trackFrustrations: true,
      defaultPrivacyLevel: 'mask',
      beforeSend: event => {
        if (event.type === 'action') {
          ;(event.action as any).target = { name: 'REDACTED' }
        }
      },
    })
    datadogRum.startSessionReplayRecording()
  }
  tsLogger.init(defaultLogLevel)
}

export const getSessionId = (): string => {
  return datadogRum.getInternalContext()?.session_id ?? 'N/A'
}

export const trackDDEvent = (name: string, attributes: any) => {
  datadogRum.addAction(name, attributes)
}

export const configureUserSession = (u: User) => {
  datadogRum.setUser({
    id: u?.token ?? 'N/A',
    name: u.userName,
    email: u.userEmail,
  })
}

const logData = (
  meta: string,
  args: any[],
  method: 'log' | 'group' = 'log',
) => {
  const argsOut = args.flat().filter(value => {
    return (
      typeof value === 'string' ||
      value.length > 0 ||
      Object.keys(value).length > 0
    )
  })
  console[method](meta, ...argsOut)
}

const logIfInitialized = (
  method: 'debug' | 'info' | 'warn',
  s?: any,
  ...optional: any[]
) => {
  if (isDefined(tsLogger.logger)) {
    tsLogger.logger[method](s, optional)
  } else {
    console.log(s, optional)
  }
}

const errorIfInitialized = (
  method: 'error' | 'fatal',
  s?: any,
  ...optional: any[]
) => {
  if (isDefined(tsLogger.errorLogger)) {
    // In newer versions of tslog library, the way it handles Errors causes an error, see https://github.com/fullstack-build/tslog/issues/227
    // Therefore I've locked the library version to 4.7.4 for now
    const message = typeof s === 'string' ? new Error(s) : s
    tsLogger.errorLogger[method](message, optional)
  } else {
    console.error(s, optional)
  }
}

export const logDebug = (s?: any, ...optional: any[]) => {
  logIfInitialized('debug', s, optional)
}

export const logInfo = (s?: any, ...optional: any[]) => {
  logIfInitialized('info', s, optional)
}

export const logWarn = (s?: any, ...optional: any[]) => {
  logIfInitialized('warn', s, optional)
}

export const logErr = (s?: any, ...optional: any[]) => {
  errorIfInitialized('error', s, optional)
}

export const logFatal = (s?: any, ...optional: any[]) => {
  errorIfInitialized('fatal', s, optional)
}
