import { useMemo } from "react"

export const getQueryParams = (
  query: string,
): Record<string, string> | null => {
  if (query) {
    const result = {} as Record<string, string>
    new URLSearchParams(query).forEach((value, key) => {
      result[key] = value
    })
    return result
  } else {
    return null
  }
}

export const useQuery = (): Record<string, string> | null => {
  const query = location.search

  return useMemo(() => {
    return getQueryParams(query)
  }, [query])
}
