import React, { useEffect, useState } from 'react'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'

import { useFavorites } from '../../providers/FavoritesProvider'

const Favorite: React.FC<{
  appId: string
  hidden?: boolean
}> = ({ appId, hidden }) => {
  const { favorites, addFavorite, deleteFavorite } = useFavorites()
  const [isFavorite, setIsFavorite] = useState(false)

  useEffect(() => {
    setIsFavorite(favorites.includes(appId))
  }, [favorites, appId])

  const handlerFavorite = () => {
    if (isFavorite) {
      deleteFavorite(appId)
    } else {
      addFavorite(appId)
    }
    setIsFavorite(!isFavorite)
  }

  return (
    <Tooltip
      arrow
      title={isFavorite ? 'Delete from favorites' : 'Make this app favorite'}
      sx={{
        background: 'black',
      }}
    >
      <IconButton
        aria-hidden={hidden}
        tabIndex={hidden ? -1 : 0}
        data-testid={`favorite-btn-${appId}`}
        className={`fa-sharp ${
          isFavorite ? 'fa-solid' : 'fa-regular'
        } fa-heart `}
        onClick={handlerFavorite}
        size="small"
        sx={{ color: theme => theme.palette.primary.light }}
      />
    </Tooltip>
  )
}

export default Favorite
