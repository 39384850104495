import React from 'react'

import {
  AppRegistration,
  ComponentModel,
  User,
  Warning,
} from '../../types/Model'
import { SwitchYard } from '../../components'
import { CustomComponentHandler } from '../../store/CustomComponents'
import { logWarn } from '../../utils/Logger'

// TODO this interface should be more like in switchyard
// to allow for centralized handling of user state.
// in the future we will want to send messages to the server
// so onComponentChange events need to be centrally collected
// using StdComponentArgs<ComponentModel> as argument would be preferred
export const ResultChildrenMapper: React.FC<{
  children: ComponentModel[]
  customReactComps: CustomComponentHandler
  appInfo: [Warning, AppRegistration]
  user: User
}> = ({ children, customReactComps, appInfo, user }) => {
  // TODO move out (see above)
  const [userValue, setUserValue] = React.useState<ComponentModel[]>(children)

  //This allows user to interact with the components in cases component relies on global state, e.g. stepper position
  const updateComp = (i: number, newval: ComponentModel) => {
    setUserValue(userValue.map((cp, index) => (index === i ? newval : cp)))
  }

  return (
    <>
      {userValue.map((cp, index) => {
        const childProps = {
          comp: cp,
          onCommand: () =>
            logWarn('Commands from result binding are not supported'),
          customReactComps: customReactComps,
          onComponentChange: (newComponent: ComponentModel) =>
            updateComp(index, newComponent),
          appInfo: appInfo,
          user: user,
        }
        return <SwitchYard key={index} {...childProps} />
      })}
    </>
  )
}
