import { Map } from 'immutable'
import { Variant } from '@mui/material/styles/createTypography'

import { CustomComponentHandler } from '../store'
import { Nullable, isDefined, isUndefined } from '../utils/Undefined'
import { genGuid } from '../utils/Common'
import { logDebug, logErr } from '../utils/Logger'

import { AppId, AppName, Guid } from './Common'
import { Command, dismissDisplay, removeInternals } from './Command'
import { MLData } from './ML'

//union of all validations
export type Validation = 'required' | 'required-true'

export type ComponentModel = any

export type InputAction = {
  destination: string
  event: string
  key: any
  perform_validation?: Nullable<boolean>
  trigger_loading?: Nullable<boolean>
}

export const isRecommended = (component: ComponentModel): boolean => {
  return isDefined(component.recommendation) && !isDefined(component.value)
}

export const getComponentValidations = (comp: ComponentModel): Validation[] => {
  //TODO server format is inconsistent and seems broken
  if (comp.validations === 'required') return ['required']
  else if (comp.validations === 'required-true') return ['required-true']
  else return []
}

export const getFullComponentType = (component: ComponentModel): string => {
  if (component.sub_type) {
    return `${component.type}.${component.sub_type}`
  }
  return component.type
}

const idiosyncraticComponentTypes = [
  'file-drop',
  'multi-select',
  'text-snippets',
  'claim-list',
  'filter-results',
] as const

/**
 * Union type for idiosyncratic Component Types
 */
export type IdiosyncraticComponentType =
  (typeof idiosyncraticComponentTypes)[number]

export const isIdiosyncraticCompType = (
  u: unknown,
): u is IdiosyncraticComponentType => {
  return typeof u === 'string' && idiosyncraticComponentTypes.includes(u as any)
}

/**
 * Helper needed in clearComponent
 */
const clearIdiosyncratic = (
  ctype: IdiosyncraticComponentType,
  comp: ComponentModel,
): ComponentModel => {
  switch (ctype) {
    case 'claim-list':
      return comp //TODO claim-list is not yet merged
    case 'file-drop': {
      if (isFileDropModel(comp)) {
        return { ...comp, files_raw: null }
      } else {
        logErr('clearIdiosyncratic unexpected file-drop component', comp)
        return comp
      }
    }
    case 'multi-select': {
      if (isMultiSelectModel(comp)) {
        return { ...comp, selections: [] }
      } else {
        logErr('clearIdiosyncratic unexpected multi-select component', comp)
        return comp
      }
    }
    case 'text-snippets':
      return comp //TODO text-snippets need work
    case 'filter-results': {
      if (isFilterResultsModel(comp)) {
        return { ...comp, tchildren: [] }
      } else {
        logErr('clearIdiosyncratic unexpected filter-results component', comp)
        return comp
      }
    }
  }
}

export const clearComponent = (comp: ComponentModel): ComponentModel => {
  if (isIdiosyncraticCompType(comp.type)) {
    return clearIdiosyncratic(comp.type, comp)
  } else {
    const res = { ...comp }
    if (!isCaseModel(res)) {
      // case component is not user entrable so do not modify it
      if (!isUndefined(res.value)) res.value = null
      if (!isUndefined(res.recommendation)) res.recommendation = null
    }
    if (isListModel(res)) {
      res.tchildren = []
      //reset internal data (trecommend, tignore)
      //this assumes that list does not have a static content.
      // TODO PLAT-538
      res.trecommend = undefined
      res.tignore = undefined
      res.numchildren = 0
    } else if (isCarouselModel(res)) {
      //this assumes that carousel can have static content.
      // TODO PLAT-538
      res.tchildren = res.tchildren.map(clearComponent)
      res.position = null
    } else if (Array.isArray(res.tchildren)) {
      res.tchildren = res.tchildren.map(clearComponent)
    } else if (isDefined(res.child)) {
      res.child = clearComponent(comp.child)
    }
    return res
  }
}

export type ButtonModel = {
  text: string
  input_actions: InputAction[]
  variant?: Nullable<string>
  validate?: Nullable<boolean>
  icon?: Nullable<string>
}

export type ButtonsModel = {
  type: 'buttons'
  buttons: ButtonModel[]
  id: string
}

export const isButtonsComponent = (component: ComponentModel): boolean => {
  return Array.isArray(component.buttons)
}

export type DashboardModel = {
  id: Nullable<string>
  type: 'dashboard'
  title: Nullable<string>
  url: Nullable<string>
  height: string
}

export const isDashboardModel = (
  component: any,
): component is DashboardModel => {
  return getFullComponentType(component) === 'dashboard'
}

export type ThemeStyle = 'primary' | 'secondary' | 'info' | 'error'

export type StaticTextModel = {
  type: 'text'
  title?: Nullable<string>
  text?: Nullable<string>
  recommendation?: Nullable<string>
  'theme-style'?: Nullable<ThemeStyle>
  variant?: Nullable<Variant>
}

export const isStaticTextModel = (
  component: any,
): component is StaticTextModel => {
  return getFullComponentType(component) === 'text'
}

export const isBooleanComparisonInputModel = (
  component: any,
): component is ComparisonBooleanInputModel => {
  return getFullComponentType(component) === 'bool-comparison'
}

export type CompareData<T> = {
  value: T
  source: string
}

export type ComparisonBooleanInputModel = {
  type: 'bool-comparison'
  text: Nullable<string>
  value: Nullable<boolean>
  recommendation: Nullable<boolean>
  tooltip: Nullable<string>
  compValues: Nullable<CompareData<boolean>[]>
}

/**
 * Currently serves both generic type="input", no sub-type and "textarea"
 */
type InputSubtype = 'input-command'

export type TextInputModel = {
  id: Nullable<string>
  type: 'input' | 'textarea'
  text: Nullable<string>
  value: Nullable<string>
  recommendation: Nullable<string>
  input_options: Nullable<TextInputOptions>
  tooltip: Nullable<string>
  tooltip_icon: Nullable<string>
  suggestions: Nullable<string[]>
  bound?: Nullable<{
    suggestions: Nullable<{
      recommendation: string[]
      value: string[]
    }>
  }>
  sub_type?: InputSubtype
  // icon?: Nullable<string>
}

export type ComparisonTextInputModel = {
  id: Nullable<string>
  type: 'text-comparison'
  text: Nullable<string>
  value: Nullable<string>
  recommendation: Nullable<string>
  tooltip: Nullable<string>
  compValues: Nullable<CompareData<string>[]>
}

export const isInputCommandModel = (
  component: any,
): component is TextInputModel => {
  return getFullComponentType(component) === 'input.input-command'
}

export type TextInputOptions = {
  'read-only': boolean
  'display-only': boolean
  hidden: boolean
  password: boolean
}

export const isTextyModel = (component: any): component is TextInputModel => {
  return ['input', 'textarea', 'combo-box'].includes(component.type)
}

export const isTextComparisonInputModel = (
  component: any,
): component is ComparisonTextInputModel => {
  return getFullComponentType(component) === 'text-comparison'
}

export const isTextInputModel = (
  component: any,
): component is TextInputModel => {
  return getFullComponentType(component) === 'input'
}

export type TextAreaModel = TextInputModel

export const isTextAreaModel = (component: any): component is TextAreaModel => {
  return getFullComponentType(component) === 'textarea'
}

export type CheckboxRenderAs = 'options'

type CheckboxSubtype = 'two-state'

export type CheckboxModel = {
  type: 'checkbox'
  text: Nullable<string>
  value: Nullable<boolean>
  tooltip: Nullable<string>
  recommendation: Nullable<boolean>
  //relies on not typechecked convention that bound type matches Nullable<{[key: string]: { value: Nullable<string>; recommendation: Nullable<string> }}>
  bound: Nullable<{
    helpText: Nullable<{
      value: Nullable<string>
      recommendation: Nullable<string>
    }>
  }>
  sub_type?: CheckboxSubtype
  render_as?: Nullable<CheckboxRenderAs>
  tchildren?: Nullable<ComponentModel>
}

export const isCheckboxModel = (component: any): component is CheckboxModel => {
  return (
    getFullComponentType(component) === 'checkbox' ||
    getFullComponentType(component) === 'checkbox.two-state'
  )
}

export type SelectModel = {
  type: 'select'
  text: Nullable<string>
  value: Nullable<string>
  id?: string
  recommendation: Nullable<string>
  options: string[]
  bound: Nullable<{
    options: Nullable<{
      recommendation: Nullable<string[]>
      value: Nullable<string[]>
    }>
  }>
}

export const isSelectModel = (component: any): component is SelectModel => {
  return getFullComponentType(component) === 'select'
}

export type TagListModel = {
  type: 'tag-list'
  placeholder: Nullable<string>
  title: Nullable<string>
  value: Nullable<string[]>
  recommendation: Nullable<string[]>
}

export const isTagListModel = (component: any): component is TagListModel => {
  return component.type === 'tag-list'
}

export type TableModel = {
  type: 'table'
  title: Nullable<string>
  withHeaders: boolean
  value: string[]
}

export const isTableModel = (component: any): component is TableModel => {
  return component.type === 'table'
}

export type ChatRole = 'system' | 'user' | 'assistant'

export type ChatData = {
  content: string
  date: Date
  role: ChatRole
}

export type ChatDisplayModel = {
  type: 'chat-display'
  id: Nullable<string>
  title: Nullable<string>
  value: Nullable<ChatData[]>
}

export const isChatDisplayModel = (
  component: any,
): component is ChatDisplayModel => {
  return component.type === 'chat-display'
}

export type OptionsModel = {
  type: 'options'
  text: Nullable<string>
  value: Nullable<string>
  tooltip: Nullable<string>
  // id?: string
  recommendation: Nullable<string>
  options: string[]
}

export type MultiSelectModel = {
  type: 'multi-select'
  text: Nullable<string>
  value: any // it is always null, the actual value is in the selections field
  selections: string[]
  recommendation: Nullable<string[]>
  options: string[]

  bound: Nullable<{
    options: Nullable<{
      recommendation: Nullable<string[]>
      value: Nullable<string[]>
    }>
  }>
}

export const isMultiSelectModel = (
  component: any,
): component is MultiSelectModel => {
  return getFullComponentType(component) === 'multi-select'
}

export const isOptionsModel = (component: any): component is OptionsModel => {
  return getFullComponentType(component) === 'options'
}

export type RatingModel = {
  type: 'rating'
  text: Nullable<string>
  value: Nullable<string>
  // id?: string
  recommendation: Nullable<string>
  options: string[]
}

export const isRatingModel = (component: any): component is RatingModel => {
  return component.type === 'rating'
}

/**
 * this has no visual rendering used with When component even though "when" is not shown here.
 */
export type CaseModel = {
  type: 'case'
  value: Nullable<string>
  // id?: string
  recommendation: Nullable<string>
  options: string[]
}

export const isCaseModel = (component: any): component is CaseModel => {
  return component.type === 'case'
}

/**
 * User action is golden rule, sets value, should not reset back to recommend state
 */
export const userCheckboxAction: <
  T extends CheckboxModel | ComparisonBooleanInputModel,
>(
  component: T,
  checked: boolean,
) => T = (component, checked) => {
  return { ...component, value: checked }
}

export const userCheckboxActionTristate = (
  component: CheckboxModel,
  value: boolean | null,
): CheckboxModel => {
  return { ...component, value }
}

export const isCheckboxChecked = (
  component: CheckboxModel | ComparisonBooleanInputModel,
): boolean => {
  if (isUndefined(component.value)) {
    return component.recommendation || false
  } else {
    return component.value
  }
}

export type DateModel = {
  type: 'input'
  sub_type: 'date'
  text: Nullable<string>
  value: Nullable<string> //example "value": "2021-12-1",
  recommendation: Nullable<string>
}

export type ComparisonDateInputModel = {
  type: 'date-comparison'
  text: Nullable<string>
  value: Nullable<string>
  recommendation: Nullable<string>
  tooltip: Nullable<string>
  compValues: Nullable<CompareData<string>[]>
}

export const isDateComparisonInputModel = (
  component: any,
): component is ComparisonDateInputModel => {
  return getFullComponentType(component) === 'date-comparison'
}

export const isDateModel = (component: any): component is DateModel => {
  return getFullComponentType(component) === 'input.date'
}

export type NumberInputModel = {
  type: 'input'
  sub_type: 'number'
  text: Nullable<string>
  value: Nullable<number>
  recommendation: Nullable<number>
}

export const isNumberInputModel = (
  component: any,
): component is NumberInputModel => {
  return getFullComponentType(component) === 'input.number'
}

export type Currency = string

export type CurrencyInputModel = {
  type: 'input'
  sub_type: 'currency'
  text: Nullable<string>
  value: Nullable<string>
  recommendation: Nullable<string>
  currency_code: Currency
}

export const isCurrencyInputModel = (
  component: any,
): component is CurrencyInputModel => {
  return getFullComponentType(component) === 'input.currency'
}

export type Base64 = string

export type FileModel = {
  contents: Base64
  name: string
  success: boolean
}

export type Link = {
  link: string
  name: string
}

// TODO: lots of places in code where we have the notion of links. We should consolidate
export type LinkDownload = {
  '@': 'FileDownloadLink'
  url: string
  name: string
  preview_url: Nullable<string>
}

//link is custom in the variant list below
export type LinkVariant = 'text' | 'contained' | 'outlined' | 'link'

export type LinkButtonModel = {
  type: 'url'
  id: Nullable<string>
  text: Nullable<string>
  value: Nullable<string> // REVIEW: Which one is the href? The value or recommendation?
  recommendation: Nullable<string>
  href: Nullable<string>
  inlined: 'right' | 'left'
  variant?: Nullable<LinkVariant>
  // REVIEW: do we support custom events? Links are pretty straightforward, they should not need them.
}

export const isLinkButtonModel = (
  component: any,
): component is LinkButtonModel => {
  return getFullComponentType(component) === 'url'
}

export type FileDownloadsModel = {
  type: 'file-downloads'
  id: Nullable<string>
  links: Nullable<LinkDownload[]>
}

export const isDownloadsModel = (
  component: any,
): component is FileDownloadsModel => {
  return getFullComponentType(component) === 'file-downloads'
}

export type DocPreviewModel = {
  type: 'doc-preview'
  id: Nullable<string>
  link: Nullable<LinkDownload>
  canDownload: boolean
  title: Nullable<string>
  unsafe: boolean
}

export const isDocPreviewModel = (
  component: any,
): component is DocPreviewModel => {
  return getFullComponentType(component) === 'doc-preview'
}

export const isDocPreviewUnsafeModel = (
  component: any,
): component is DocPreviewModel => {
  return getFullComponentType(component) === 'doc-preview' && component.unsafe
}

export type FileDropModel = {
  type: 'file-drop'
  id: Nullable<string>
  text: Nullable<string>
  files_raw: Nullable<FileModel[]>
  accept: Nullable<string>
  multiple: boolean
}

export const isFileDropModel = (component: any): component is FileDropModel => {
  return getFullComponentType(component) === 'file-drop'
}

export type FolderRenderModes =
  | 'border-box-default'
  | 'border-box-success'
  | 'floating-action-bar'

export type FolderSubType = 'form'

export type FolderModel = {
  label: Nullable<string>
  tchildren: Nullable<ComponentModel[]>
  indent: Nullable<number>
  render_as?: Nullable<FolderRenderModes>
  padding?: Nullable<number>
  sub_type?: FolderSubType
}

export const isFolderModel = (component: any): component is FolderModel => {
  return component.type === 'folder'
}

export const isFormModel = (component: any): component is FolderModel => {
  return getFullComponentType(component) === 'folder.form'
}

export type ResultSubType = 'success' | 'failure' | 'info'

export type ResultModel = {
  tchildren: Nullable<ComponentModel[]>
  sub_type: ResultSubType
}

export const isResultModel = (component: any): component is ResultModel => {
  return component.type === 'result'
}

export type AlertModel = {
  tchildren: Nullable<ComponentModel[]>
  sub_type: ResultSubType
  title: string
  id: Nullable<string>
  type: 'alert'
}

export const isAlertModel = (component: any): component is AlertModel => {
  return component.type === 'alert'
}

export type ColumnAlignment = 'horizontal' | 'vertical'

export type ColumnModel = {
  label: Nullable<string>
  tchildren: Nullable<ComponentModel[]>
  indent: Nullable<number>
  columns: number
  render_as?: FolderRenderModes
  padding?: Nullable<number>
  alignment?: Nullable<ColumnAlignment>
}

export const isColumnModel = (component: any): component is ColumnModel => {
  return component.columns && component.columns > 1
}

export type ModalModel = {
  title: string
  type: 'modal'
  sub_type: ResultSubType
  id: Nullable<string>
  tchildren: Nullable<ComponentModel[]>
}

export const isModalModel = (component: any): component is ModalModel => {
  return component.type === 'modal'
}

export type WhenChildModel = {
  when: any
  when_default?: Nullable<boolean>
}

export const isWhenChildModel = (
  component: any,
): component is WhenChildModel => {
  return isDefined(component.when) || isDefined(component.when_default)
}

/**
 * Component can be both WhenModel and something else, e.g CheckboxModel.
 * WhenModel makes user see selected branch of tchildren, the selection happens
 * via use of a paired model, e.g. CheckboxModel
 */
export type WhenModel = {
  tchildren: WhenChildModel & ComponentModel
  value: Nullable<string | boolean>
  recommendation: Nullable<string | boolean>
  options: Nullable<string[]>
  type: Nullable<string>
}

export const isWhenModel = (component: any): component is WhenModel => {
  return (
    Array.isArray(component.tchildren) &&
    component.tchildren.length > 0 &&
    isWhenChildModel(component.tchildren[0])
  )
  // !isUndefined(component.value) REVIEW: is this supposed to be part of the predicate?
}

export const whenSelection = (comp: WhenModel): any => {
  logDebug('whenSelection', comp)
  if (isDefined(comp.value)) return comp.value
  else if (isDefined(comp.recommendation)) return comp.recommendation
  else if (comp.type == 'tab-select') {
    //Only TabSelect defaults to first if not recommended or fixed
    // TabSelelect is defaulting to first if not present
    return comp.options?.find(() => true) //JS version of head function
  } else {
    return undefined
  }
}

export type NormalCurveModel = {
  type: 'graph-normal-curve'
  id: Nullable<string>
  label: string
  mean: Nullable<number> //mean needs to be present for this to show graph
  'std-dev': Nullable<number>
  z: Nullable<number>
  color?: Nullable<string>
  empty_msg: Nullable<string> //to be displayed if mean is not present

  bound: Nullable<{
    'std-dev': Nullable<{
      recommendation: Nullable<number>
      value: Nullable<number>
    }>
    empty_msg: Nullable<{
      recommendation: Nullable<string>
      value: Nullable<string>
    }>
    z: Nullable<{
      recommendation: Nullable<number>
      value: Nullable<number>
    }>
  }>
}

export const isNormalCurveModel = (
  component: any,
): component is NormalCurveModel => {
  return getFullComponentType(component) === 'graph-normal-curve'
}

export type AccordionModel = {
  label: Nullable<string>
  tchildren: Nullable<ComponentModel[]>
  type: 'accordion'
  badges: boolean
  labelVariant: Nullable<Variant>
}

export const isAccordionModel = (
  component: any,
): component is AccordionModel => {
  return getFullComponentType(component) === 'accordion'
}

export type StepModel = {
  type: 'step'
  label: string
  tchildren: ComponentModel[]
  propagateValidation?: boolean
}

export type StepFinalModel = {
  type: 'step-final'
  label: string
  tchildren: ComponentModel[]
}

export const isStepModel = (c: any): c is StepModel => {
  return c.type === 'step'
}

export const isStepFinalModel = (c: any): c is StepFinalModel => {
  return c.type === 'step-final'
}

export type StepperModel = {
  value?: Nullable<number> //stores active step (user defined) future will have recommendation as well
  perform_validation?: Nullable<boolean>
  std_buttons?: Nullable<boolean>
  tchildren: (StepModel | StepFinalModel)[]
}

export const isStepperModel = (c: any): c is StepperModel => {
  return c.type === 'stepper'
}

export type TabSelectModel = {
  type: 'tab-select'
  id: Nullable<string>
  value: Nullable<string>
  tchildren: Nullable<ComponentModel[]>
  options: string[]
  recommendation?: Nullable<string>
}

export const isTabSelectModel = (
  component: any,
): component is TabSelectModel => {
  return component.type === 'tab-select'
}

export type ContentHash = string

export type ContentHashedComponent = {
  content_hash: ContentHash
  component: ComponentModel
}

export type AppRegUpdateFn = (
  appRegistration: AppRegistration,
) => AppRegistration

export type ListRenderModes = 'list' | 'carousel'

// TODO: ListModel merge needs special semantics for carousel (it is server owned)
export type ListModel = {
  type: 'list'
  render_as: Nullable<ListRenderModes>
  carousel_type: Nullable<'rude' | 'static'>
  position: Nullable<number> //currenly used for carousel only, it is 0-based index of active list item
  tchildren: ComponentModel[]
  emptycomponent: ComponentModel //empty component could be null or {} for static lists or carousel
  numchildren: number
  label: string
  addlabel: string //"" for carousel
  id?: Nullable<string>
  trecommend?: ContentHashedComponent[] //not used for carousel
  tignore?: ContentHash[] //not used for carousel
  actions: Nullable<InputAction[]> // solve bug whn adding a new item and us
}

export const isListModel = (component: any): component is ListModel => {
  return (
    component.type === 'list' &&
    (isUndefined(component.render_as) || component.render_as === 'list')
  )
}

export type CarouselModel = ListModel

export const isCarouselModel = (component: any): component is CarouselModel => {
  return component.type === 'list' && component.render_as === 'carousel'
}

export type FilterResultsModel = {
  type: 'filter-results'
  tchildren?: Nullable<FilterResultModel[]> //observed as null in server message
  id?: Nullable<string>
}

export const isFilterResultsModel = (c: any): c is FilterResultsModel => {
  return c.type == 'filter-results'
}

export type FilterResultModel = {
  type: 'filter-result'
  name: string
  clause: string
  people: string[]
  jurisdiction: string
  imanage_id: string
  highlight: string[]
  date: string
  document_id: string
}

export type ClaimListModel = {
  type: 'claim-list'
  claims: ClaimModel[]
  internal_condensed_claims: Nullable<ClaimView[]>
}

export type ClaimViewListModel = {
  claims: ClaimView[]
}

export type ClaimModel = {
  type: 'claim'
  preamble: ComponentText[]
  claimNumber: Nullable<number>
  claimStatus: Nullable<string>
  isIndependent: Nullable<boolean>
  features: Feature[]
}

export type Feature = {
  type: 'claim-feature'
  featureComponents: FeatureComponent[]
  featureText: ComponentText[]
}

export type FeatureComponent = {
  type: 'feature-component'
  componentText: ComponentText[]
}

export type ComponentText = {
  type: 'component-text'
  tag: Nullable<string>
  contents: Nullable<string>
}

export type ClaimView = {
  claims: string
  status: Nullable<string>
  isIndependent: Nullable<boolean>
}

export const isClaimListModel = (
  component: any,
): component is ClaimListModel => {
  return component.type === 'claim-list'
}

export const isClaimModel = (component: any): component is ClaimModel => {
  return component.type == 'claim'
}

export type WarningIssue = { type: 'warning'; warn: string; dta: any }

// TODO: needs list, mostly for developer now
export type Warning = { type: 'noWarn' } | WarningIssue
/**
 * Juvo User information, retrieved from /user endpoint
 */
export type User = {
  token?: Nullable<string>
  userName: string
  userEmail: string
}

export function isUser(d: any): d is User {
  return typeof d.userName === 'string' && typeof d.userEmail === 'string'
}

/**
 * Part of /new content:
 * "app_agent": {
                "icon": "fas fa-lightbulb-dollar",
                "name": "Juvo"
            }
 */
export type AppAgent = {
  icon: string
}

//used for app list
export type AppItem = {
  app_id: AppId
  app_name: AppName
  app_agent?: Nullable<AppAgent>
}

export type AppPopup = {
  popup_title: string
  popup_text: string
  popup_buttons: string[][]
}

type AppDocLink = string

export type AppRegistration = {
  app_id: AppId
  app_name: AppName
  app_version?: Nullable<string>
  app_agent?: Nullable<AppAgent>
  app_skeleton: AppSkeleton
  app_popup: Nullable<AppPopup>
  app_doclink?: Nullable<AppDocLink>
  app_mldata?: Nullable<MLData>
  out_command?: Nullable<Command> //internal state, command sent to server, removed when response is received
}

//NOTE (and please do not remove):
//export const registrationAppItem : (_: AppRegistration) => AppItem = r => r

// TODO: move this
// Note: the isXyz functions like this one are good enough because Types use '| Undefined'
// A better approch could be to parse JS value into TS type insance (aka FromJSON)
// see failing test for 'Paritial AppSkeleton json parses'
// need to examine existing tooling in the ecosystem as this is largely boilerplate
export function isAppRegistration(d: any): d is AppRegistration {
  return (
    typeof d.app_id === 'string' &&
    typeof d.app_name === 'string' &&
    isAppSkeleton(d.app_skeleton)
  )
}

export function appRegistrationLoadingState(
  appReg: AppRegistration,
): 'loading' | null {
  return (
    appReg.out_command?.internal_loading_state ||
    (appReg.app_skeleton.command?.display_state?.state === 'Loading'
      ? 'loading'
      : null)
  )
  //return appReg.out_command?.internal_loading_state || null
}

export const clearOutCommand = (appReg: AppRegistration): AppRegistration => {
  return { ...appReg, out_command: null }
}

export function clearAppRegistration(appReg: AppRegistration): AppRegistration {
  const newSkel = clearAppSkeleton(appReg.app_skeleton)
  return { ...appReg, app_skeleton: newSkel }
}

//data initially received from server about the apps
export type InitialAppData = {
  suggestions?: Nullable<AppId[]>
  guid: Guid
  app_list: AppRegistration[]
}

export const isInitialAppData = (value: any): value is InitialAppData => {
  return (
    typeof value.guid === 'string' &&
    Array.isArray(value.app_list) &&
    value.app_list.every(isAppRegistration)
  )
}

export type AppSkeleton = {
  components: ComponentModel[]
  command?: Nullable<Command>
  actions?: Nullable<Action[]>
  tabs?: Nullable<Tab[]>
}

export function isAppSkeleton(component: any): component is AppSkeleton {
  return (
    (Array.isArray(component.components) ||
      isUndefined(component.components)) &&
    (Array.isArray(component.actions) || isUndefined(component.actions)) &&
    (Array.isArray(component.tabs) || isUndefined(component.tabs))
  )
}

export function clearAppSkeleton(appSkel: AppSkeleton): AppSkeleton {
  const command = appSkel?.command
  const res = {
    ...appSkel,
    components: appSkel.components.map(clearComponent),
    command: command && dismissDisplay(command),
  }
  logDebug('clearAppSkeleton', res)
  return res
}

//TODO we need better typing on these Models
export const getInputActions = (
  comp: ComponentModel | ButtonModel,
): InputAction[] | null => comp.input_actions

export type JuvoMessage = {
  components?: Nullable<ComponentModel[]>
  command?: Nullable<Command>
  actions?: Nullable<Action[]>
  // reqid: Nullable<string>
  app_id: AppId //TODO allow for non-app directed messages (future platf enhancement)
  from?: Nullable<any>
  tabs?: Nullable<any[]>
  req_id?: Nullable<string>
}

export type PingMessage = {
  msgtype: 'ping'
}

export const isJuvoMessage = (component: any): component is JuvoMessage => {
  return Array.isArray(component.components) || component.command !== undefined
}

export const isPingMessage = (t: any): t is PingMessage => {
  return t?.msgtype === 'ping'
}

// wrapping in {} forces null check to retrieve payload
// creation controlled by the program, use null instead of Undefined
export type Message = { type: 'msg'; payload: JuvoMessage } | null
export type CtxMessage = { type: 'msgctx'; payload: Context }
export const emptyMsg: Message = null

export type Action = object

export type SnackbarModel = {
  title: string
  type: 'snackbar'
  subtitle: string
  sub_type: ResultSubType
  duration?: number
}

export const isSnackbar = (comp: any): comp is SnackbarModel => {
  return comp.type === 'snackbar'
}

export type Tab = {
  tab_name: string
  id: number
  tab_icon: string
}

export type TabComponent = {
  tab: Tab
  components: ComponentModel[]
}

/**
 * This assume that app has no tabs or only has tabs, no tabless components if tabs are present
 */
export const allocateTabs = (
  appSkel: AppSkeleton,
):
  | { type: 'tabs'; data: TabComponent[] }
  | { type: 'notabs'; data: ComponentModel[] } => {
  const tabs: Tab[] = appSkel.tabs || []
  const emptyComps: ComponentModel[] = []
  if (tabs.length > 0) {
    const tabComps: [number, TabComponent][] = tabs.map(tab => {
      return [tab.id, { tab: tab, components: emptyComps }]
    })

    const initTabComps: Map<number, TabComponent> = Map(tabComps)

    const resMap: Map<number, TabComponent> = appSkel.components.reduce(
      (acc: Map<number, TabComponent>, comp) => {
        if (isDefined(comp.tab_id) && isDefined(acc.get(comp.tab_id))) {
          const tab = acc.get(comp.tab_id)

          return tab
            ? acc.set(comp.tab_id, {
                ...tab,
                components: [...tab.components, comp],
              })
            : acc
        } else return acc
      },
      initTabComps,
    )
    const dta = tabComps.map(x => {
      return resMap.get(x[0]) || ({} as TabComponent)
    })
    return { type: 'tabs', data: dta }
  } else {
    return { type: 'notabs', data: appSkel.components }
  }
}

export type Context = any | { '@': string }

// TODO: check that this is really not used, it is not used anywhere in Haskell
// passed to AtticusMessage messageFrom field
const notUsedMsgFrom = {
  name: 'Not Used',
  email: 'Not Used',
  host_type: null,
  icon: 'Not Used',
}

export const commandMessage =
  (appReg: AppRegistration) =>
  (cmd: Command): Message => {
    const appSkeleton = appReg.app_skeleton
    const jm: JuvoMessage = {
      components: appSkeleton.components.map(cleanupOutMessage),
      command: removeInternals(cmd),
      actions: appSkeleton.actions,
      // reqid: Nullable<string>,
      from: notUsedMsgFrom,
      tabs: appSkeleton.tabs,
      app_id: appReg.app_id,
      req_id: genGuid(),
    }

    return { type: 'msg', payload: jm }
  }

const cleanupOutMessage = (component: ComponentModel): ComponentModel => {
  const res = { ...component }
  if (Array.isArray(component.tchildren)) {
    res.tchildren = component.tchildren.map(cleanupOutMessage)
  }
  if (isDefined(component.child)) {
    res.child = cleanupOutMessage(component.child)
  }

  const { internal_condensed_claims, ...filteredComp } = res

  // Trimming texty components to prevent sending to server trailing spaces values in case onBlur was defined
  const modifiedComp =
    isTextyModel(filteredComp) && isNotTrimmed(filteredComp.value)
      ? { ...filteredComp, value: filteredComp.value?.trim() }
      : filteredComp

  return modifiedComp
}

const isNotTrimmed = (value: Nullable<string>) => {
  if (value) {
    return value[0] === ' ' || value[value.length - 1] === ' '
  }
  return false
}

// typical component arguments, all nested components need to use it
export type StdComponentArgs<T> = {
  comp: T
  onCommand: (cmd: Command) => void
  customReactComps: CustomComponentHandler
  onComponentChange: (newComponent: T) => void
  appInfo: [Warning, AppRegistration]
  user: User
  propagateValidation?: boolean
  handlerValidation?: (value: boolean) => void
}

/**
 * Allow for code that is agnostic to changes in StdComponentArgs content.
 * This changes component specific stuff, that is 'comp' and 'onComponentChange', keeps rest intact.
 * This, e.g. allows to set child arguments based on parent.
 *
 * TODO better typing allowing parent and child types to different
 */
export const adjustStdComponentArgs = (
  sc: StdComponentArgs<any>,
  comp: ComponentModel,
  onComponentChange: (newComponent: ComponentModel) => void,
): StdComponentArgs<any> => {
  return { ...sc, comp: comp, onComponentChange: onComponentChange }
}

export type BindModel<T> = {
  type: 'bind'
  to: string //bind to property
  child: ComponentModel
  value: Nullable<T>
  recommendation: Nullable<T>
}

export const isBindModel = <T>(component: any): component is BindModel<T> => {
  return component.type === 'bind'
}

export type MakeState<T> = [T, (t: T) => void]

/**
 * Any model with this extra field has additionally bound elements,
 * We keep them untyped for now
 */
export type BoundModel = {
  //object representation of Map<string, { value: Nullable<any>; recommendation: Nullable<any>}>
  bound: Nullable<{
    [key: string]: { value: Nullable<string>; recommendation: Nullable<string> }
  }>
}

//REVIEW:
//
//(Issue)
//User changes are to bound value are disregarded.
//Do we ever want bound value to be changed by user? If so we would need to adjust it on the way back on
// the bind component itself.
//
//(Issue)
//
//This keeps things untyped,
//
// Alternative approach is to move the extra bound data to a Map maintained in SwitchYard
//this should not be hard to do at all.
//
// Really the BEST approach would be to properly encode Partial in JSON and frontend.
export const adjustStdComponentArgsBindToChild = (
  args: StdComponentArgs<BindModel<any>>,
  bindings: Map<
    string,
    { value: Nullable<any>; recommendation: Nullable<any> }
  >,
): StdComponentArgs<BoundModel> => {
  const newBindings = bindings.set(args.comp.to, {
    value: args.comp.value,
    recommendation: args.comp.recommendation,
  })
  const childComp = args.comp.child
  if (isBindModel(childComp)) {
    const onCpChange = (comp: BindModel<any>): void => {
      //simply change the nested child on the container setter
      args.onComponentChange({ ...args.comp, child: comp })
    }
    //since the child is nested bind recurse with adjusted arguments and bindings
    return adjustStdComponentArgsBindToChild(
      adjustStdComponentArgs(args, childComp, onCpChange),
      newBindings,
    )
  } else {
    const bc = makeBoundComponent(childComp, newBindings)
    const onCpChange = (childComp: ComponentModel): void => {
      //undo the added bounds on the child component
      args.onComponentChange({ ...args.comp, child: childComp })
    }
    return adjustStdComponentArgs(args, bc, onCpChange)
  }
}

const makeBoundComponent = (
  comp: ComponentModel,
  bindings: Map<
    string,
    { value: Nullable<any>; recommendation: Nullable<any> }
  >,
): BoundModel => {
  return { ...comp, bound: bindings.toJS() }
}

/**
 * data is sanitized on the way out already so this is not used,
 * lets keep if for documentation purpose
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const removeBound = (comp: BoundModel): ComponentModel => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { bound, ...rest } = comp
  return rest
}

// TODO: move
//test demo reply
export const testMessage: Message = {
  type: 'msg',
  payload: {
    components: [
      {
        text: 'Hello World',
        error: null,
        value: 'test',
        loading: false,
        input_actions: [],
        id: '0c3ce87f-6966-42d9-8405-ddd834ce6b50',
        options: [],
        type: 'input',
        validations: {},
        recommendation: 'Change Me!',
        focus: false,
      },
      {
        buttons: [
          {
            color: 'Green',
            text: 'Hello World Button',
            input_actions: [
              {
                destination: 'hello',
                event: 'Click',
                perform_validation: false,
                trigger_loading: true,
                key: null,
              },
            ],
          },
        ],
        id: 'cdfa4726-d519-411b-8ff2-20756d9babab',
        type: 'buttons',
      },
    ],
    command: {
      '@': 'action',
      value: 'hello',
      token: null,
    },
    actions: [],
    // "reqid": "WEB---07c3766f-7a27-416b-9855-9231096991cf",
    from: {
      name: 'Atticus',
      email: 'test@jurisfutura.com',
      host_type: null,
      icon: 'futura_logo.svg',
    },
    tabs: [],
    app_id: 'mod-hello',
    req_id: 'a9e9c71f-8769-4cf8-9909-98e7c59c5814',
  },
}
