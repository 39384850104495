import React from 'react'
import MuiAccordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import { alpha } from '@mui/material'

import {
  AccordionModel,
  adjustStdComponentArgs,
  ComponentModel,
  StdComponentArgs,
} from '../../../types'
import SwitchYard from '../../juvo-component/SwitchYard/SwitchYard'
import { containerSetChild, getJuvoInfo } from '../../../store'

// components that does not count inside the accordion
const COMPONENTS_ARRAY = ['list', 'folder', 'stepper']
// values that does not count inside the accordion
const VALUES_ARRAY = [null, undefined, '']

const Accordion: React.FC<StdComponentArgs<AccordionModel>> = props => {
  const { comp, onComponentChange } = props
  const children: ComponentModel[] = comp.tchildren || []
  const totalNoFilledComponents = children.filter(
    (children: ComponentModel) =>
      !COMPONENTS_ARRAY.includes(children.type) &&
      !VALUES_ARRAY.includes(children.value),
  )

  return (
    <MuiAccordion
      {...getJuvoInfo('AccordionCont', comp)}
      TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
      className="juvo-accordion"
      sx={{
        boxShadow: 'none !important',
        // margin is set to avoid the movement because of the MuiPaper-root-MuiAccordion-root.Mui-expanded
        // class that cannot be override in the Paper component
        margin: '16px 0',
        border: theme => `1px solid ${theme.palette.secondary.dark}`,
        '.MuiAccordionSummary-root.Mui-expanded': {
          minHeight: '48px',
          borderBottom: theme => `1px solid ${theme.palette.secondary.dark}`,
        },
        background: theme => alpha(theme.palette.secondary.main, 0.5),
        '&:before': {
          height: '0 !important',
        },
      }}
    >
      <AccordionSummary
        expandIcon={
          <IconButton
            className="fa fa-chevron-up"
            size="small"
            aria-label="open accordion"
          />
        }
        sx={{
          flexDirection: 'row-reverse',
          background: theme => theme.palette.secondary.main,
          '.Mui-expanded': {
            // important is used to override the default margin of MuiAccordionSummary-content.Mui-expanded
            margin: '12px 0 !important',
          },
          '.MuiAccordionSummary-content': {
            display: 'flex',
            alignItems: 'center',
          },
        }}
      >
        <Typography
          variant={comp.labelVariant ?? 'subtitle2'}
          sx={{
            marginLeft: '16px',
            letterSpacing: '0.15px',
            lineHeight: '28px',
          }}
        >
          {comp.label}
        </Typography>
        {comp.badges && totalNoFilledComponents.length > 0 && (
          <Box
            sx={{
              background: theme => theme.palette.grey[400],
              height: '20px',
              width: '20px',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginLeft: '15px',
              fontSize: '12px',
            }}
          >
            {totalNoFilledComponents.length}
          </Box>
        )}
      </AccordionSummary>
      <AccordionDetails>
        {children.map((cp, idx) => {
          const childProps = adjustStdComponentArgs(props, cp, nc =>
            onComponentChange(containerSetChild(nc, idx, comp)),
          )

          return <SwitchYard key={idx} {...childProps} />
        })}
      </AccordionDetails>
    </MuiAccordion>
  )
}

export default Accordion
