import { isUndefined } from './Undefined'

declare global {
  interface Window {
    JuvoConfig: JuvoConfig
  }
}

export enum DebugInfo {
  None = 'none',
  Short = 'short',
  Full = 'full',
}

export enum LogLevel {
  silly = 0,
  trace = 1,
  debug = 2,
  info = 3,
  warn = 4,
  error = 5,
  fatal = 6,
}

/**
 *
 * Type for the global config variables. Needs to be updated each time a variable is added, removed or it's type is
 * modified.
 *
 */
export type JuvoConfig = {
  readonly disableEslintPlugin: boolean
  readonly showDebugInfo: DebugInfo
  readonly heartbeatIntervalMS: number
  readonly maxDisconnectTimeMS: number
  readonly heartbeatMarginMS: number
  readonly datadogApplicationId: string
  readonly datadogClientToken: string
  readonly datadogEndpoint: string
  readonly datadogService: string
  readonly disableDatadog: boolean
  readonly defaultLogLevel: LogLevel
  readonly serverReconnectAttempts: number
  readonly serverReconnectIntervalMS: number
  readonly platformHttpUrl: string
  readonly platformWsUrl: string
  readonly feedbackEmail: string
  readonly basePath: string
  readonly headerConfig: HeaderConfig
  readonly defaultApp?: string
  readonly environment: string
  readonly attachOutlook?: string
}

export type HeaderConfig = {
  readonly ml: boolean
  readonly testApp: boolean
  readonly menu: boolean
  // readonly removeLoading: boolean //TODO remove from IAC
  readonly eraser: boolean
  readonly magicWand: boolean
  readonly docLink: boolean
  readonly connectionStatus: boolean
}

/**
 *
 * Getter for the global config variables.
 * Throws a runtime error if the config is unavailable.
 *
 */
export const getJuvoConfig = (): JuvoConfig => {
  if (isUndefined(window.JuvoConfig)) {
    throw new Error('No config found!')
  } else {
    return window.JuvoConfig
  }
}
