import React from 'react'
import MuiTextField from '@mui/material/TextField'
import clsx from 'clsx'

import {
  ComponentModel,
  getFullComponentType,
  isRecommended,
} from '../../../types'
import {
  getComponentRecommendation,
  getJuvoInfo,
  setComponentValue,
} from '../../../store'
import { isDefined, sanitizeInputName, ValidationProps } from '../../../utils'
import { MissingComponent } from '../MissingComponent/MissingComponent'

const DefaultField: React.FC<{
  comp: ComponentModel
  onChange: (_: ComponentModel) => void
  validationProps: ValidationProps
}> = ({ comp, onChange, validationProps }) => {
  const componentType = getFullComponentType(comp)

  if (componentType?.startsWith('input')) {
    const recommendation = getComponentRecommendation(comp)
    const fieldValue = isDefined(comp.value) ? comp.value : recommendation
    const label = comp.text
    const { handleVisited, hasErrors, errorMessage } = validationProps

    return (
      <MuiTextField
        {...getJuvoInfo('DefaultField.text', comp)}
        variant="standard"
        type="text"
        fullWidth
        value={fieldValue}
        name={sanitizeInputName(label)}
        onChange={e => onChange(setComponentValue(comp)(e.target.value))}
        onBlur={handleVisited}
        label={comp.text || ''}
        className={clsx(isRecommended(comp) && 'juvo-recommendation')}
        error={hasErrors}
        helperText={errorMessage}
        sx={{ marginBottom: theme => theme.spacing(2) }}
      />
    )
  }

  return <MissingComponent comp={comp} />
}

export default DefaultField
