import { useEffect, useRef, useState } from 'react'
import useWebSocket from 'react-use-websocket'

import {
  CtxMessage,
  isJuvoMessage,
  isPingMessage,
  JuvoMessage,
  Message,
} from '../types'
import {
  getJuvoAuthToken,
  getJuvoConfig,
  isDefined,
  isUndefined,
  logDebug,
  logInfo,
  logWarn,
  Nullable,
} from '../utils'

export type InMsg = (msg: Message) => void

export enum WebsocketState {
  CONNECTED = 0, // The application is alive and actively receiving pings
  DISCONNECTED = 1, // The application has missed one or more pings
  TERMINATED = 2, // The application has exhausted its reconnect attempts and will no longer try
}

export type WsConnectionParams = {
  guid: Nullable<string>
  outMsg: Message | CtxMessage
  inMsg: InMsg
}

// All times are in milliseconds
const juvoConfigDefaults = {
  heartbeatIntervalMS: 10000, // How often to expect a ping from the server. Set to 0 to disable heartbeat monitoring
  maxDisconnectTimeMS: 600000, // How long a disconnected session may continue to listen for a heartbeat
  heartbeatMarginMS: 1000, // Grace period added to pings to allow for network inconsistencies
  serverReconnectAttempts: 3, // How many times to try to reconnect after a server failure
  serverReconnectIntervalMS: 10000, // Time between server reconnect attempts
}

const getJuvoConfigWithDefaults = () => {
  return {
    ...juvoConfigDefaults,
    ...getJuvoConfig(),
  }
}

export function useWsConnection({
  guid,
  outMsg,
  inMsg,
}: WsConnectionParams): WebsocketState {
  const lastHeartbeat = useRef<number>(0)
  const [checkInterval, setCheckInterval] = useState<NodeJS.Timer>()
  const [isLive, setIsLive] = useState(true) // isLive is true when the app is actively receiving pings
  const [isTerminated, setIsTerminated] = useState(false) // isTerminated is true when isLive is false and max reconnect attempts are exceeded

  const {
    platformWsUrl,
    heartbeatIntervalMS,
    maxDisconnectTimeMS,
    heartbeatMarginMS,
    serverReconnectAttempts,
    serverReconnectIntervalMS,
  } = getJuvoConfigWithDefaults()
  const socketUrl = `${platformWsUrl}${guid}`
  const shouldConnect = isDefined(guid)

  const token = getJuvoAuthToken()
  const queryParamsOption =
    token === null
      ? {}
      : {
          queryParams: {
            token,
          },
        }

  useEffect(() => {
    logDebug({ boot: { socketUrl: socketUrl } })
  }, [socketUrl])

  const { sendMessage, lastMessage, readyState } = useWebSocket(
    socketUrl,
    {
      ...queryParamsOption,
      onOpen: () => {
        if (heartbeatIntervalMS > 0) startHeartbeat()
        logInfo('WS opened, starting heartbeat')
      },
      onReconnectStop: () => {
        terminate()
      },
      shouldReconnect: () => {
        /**
         *  ShouldReconnect callback is only called when the useWebSocket library actually detects a disconnection.
         *  If the user loses connection (such as when the VPN is disconnected), the library is not aware of it.
         *  However, if the server itself has the issue, the library will detect it and call shouldReconnect() every
         *  x seconds, which is configured by reconnectInterval. Once the maximum number of attempts is reached, configured
         *  by reconnectAttempts, this function is no longer called, and instead onReconnectStop() is called.
         **/

        return !isTerminated
      },
      reconnectInterval: serverReconnectIntervalMS,
      reconnectAttempts: serverReconnectAttempts,
      filter: (message: MessageEvent<any>) => {
        if (isPingMessage(JSON.parse(message.data))) {
          updateHeartbeat(Date.now())
          return false
        } else {
          return true
        }
      },
    },
    shouldConnect,
  )

  useEffect(() => {
    if (lastMessage !== null) {
      if (lastMessage.data) {
        const juvomsg: JuvoMessage = JSON.parse(lastMessage.data)
        if (isJuvoMessage(juvomsg)) {
          logDebug(`msgIn ${juvomsg.app_id}`, { msgIn: juvomsg })
          inMsg({ type: 'msg', payload: juvomsg })
        }
        return
      }
      logWarn('msgIn Err', {
        errMsg: 'Skipping invalid in-msg',
        msgIn: lastMessage,
      })
    }
    // TODO: this is an issue, we must resolve it
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [lastMessage])

  useEffect(() => {
    if (outMsg === null) {
      logDebug('outMsg processing initialized')
    } else {
      logDebug(
        `msgOut ${
          outMsg.type === 'msg' ? outMsg.payload?.app_id : 'new-context'
        }`,
        { msgOut: outMsg.payload },
      )
      sendMessage(JSON.stringify(outMsg.payload))
    }
  }, [outMsg, sendMessage])

  const startHeartbeat = () => {
    lastHeartbeat.current = Date.now()
    if (isUndefined(checkInterval)) {
      const interval = setInterval(checkHeartbeat, heartbeatIntervalMS)
      setCheckInterval(interval)
    }
  }

  const checkHeartbeat = () => {
    if (isTerminated) return
    const timeSinceLastBeat = Date.now() - lastHeartbeat.current
    if (maxDisconnectTimeMS > 0 && timeSinceLastBeat >= maxDisconnectTimeMS) {
      terminate()
    }
    setIsLive(timeSinceLastBeat < heartbeatIntervalMS + heartbeatMarginMS)
  }

  const terminate = () => {
    if (isDefined(checkInterval)) {
      clearInterval(checkInterval)
      setCheckInterval(undefined)
    }
    setIsTerminated(true)
    setIsLive(false)
  }

  const updateHeartbeat = (beatTime: number) => {
    lastHeartbeat.current = beatTime
  }

  if (isTerminated) {
    return WebsocketState.TERMINATED
  } else if ((readyState === 1 && isLive) || !lastHeartbeat.current) {
    return WebsocketState.CONNECTED
  } else {
    return WebsocketState.DISCONNECTED
  }
}
